import { BreadcrumbBar, Breadcrumb as BreadcrumbUSWDS, BreadcrumbLink, Icon } from '@trussworks/react-uswds';
import './breadcrumb.scss';

const formatBreadcrumbText = text => text ? text.replace(/-/g, ' ').split(' ').map(word => word && `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`).join(' ') : '';

const Breadcrumb = ({
  home = true,
  pathname,
  href = '/home',
  hrefText = 'Home'
}) => {
  const paths = pathname.split('/').filter(item => item !== '');
  const pathLength = paths.length;

  return (
    <BreadcrumbBar className='breadcrumb-list'>
      {home && (
        <BreadcrumbUSWDS>
          <BreadcrumbLink href={href}>
            <Icon.Home className='mr-1' color='#0c66bb' size={'16px'} />
            {hrefText}
          </BreadcrumbLink>
        </BreadcrumbUSWDS>
      )}
      <BreadcrumbUSWDS className='breadcrumb-item active' current>
        <span>{formatBreadcrumbText(paths[pathLength - 1])}</span>
      </BreadcrumbUSWDS>
    </BreadcrumbBar>
  );
};

export default Breadcrumb;
