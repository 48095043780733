import React from 'react';
import { states } from '../input-forms/_helper.jsx';

import { PhoneTypes } from '@src/utils/enums';
import { emailRegex, zipCodeRegex, cityRegex, phoneExtRegex, phoneRegex } from '../../../utils/regex.jsx';

// export const validateImpacts = (data) => {
//   let resourcesValid = true;
//   let errorList = [];

//   const formatErrorList = (errors) => (
//     <ol>
//       {errors.map((error, i) => <><li key={i}>{error}</li><br /></>)}
//     </ol>
//   );

//   const isBlank = (val) => !val;

//   const isValidUnit = (val, unit) =>

//     val === 'Fill Volume' && unit === 'Cubic Yards' ||
//     val === 'Removal Volume' && unit === 'Cubic Yards' ||
//     val === 'Fill Area' && (unit === 'Acres' || unit === 'Square Feet') ||
//     val === 'Structure Area' && (unit === 'Acres' || unit === 'Square Feet') ||
//     val === 'Removal Area' && (unit === 'Acres' || unit === 'Square Feet');

//   const isValidAmountType = (activity, amount) =>
//     activity === 'Conversion of waters type (forested wetland to emergent wetland, stream to lake)' && amount === 'Fill Area' ||
//     activity === 'Discharge of dredged material' && amount === 'Fill Area' ||
//     activity === 'Discharge of fill material' && amount === 'Fill Area' ||
//     activity === 'Dredging (Section 10)' && (amount === 'Removal Area' || amount === 'Removal Volume') ||
//     activity === 'Ecological restoration' && amount === 'Fill Area' ||
//     activity === 'Other (Aquaculture, Work, Aerial or Submarine cable crossings)' && (amount === 'Fill Area' || amount === 'Removal Area' || amount === 'Structure Area') ||
//     activity === 'Removal (Sec 10 structures)' && amount === 'Removal Area' ||
//     activity === 'Structure (Sec 10 only)' && amount === 'Structure Area' ||
//     activity === 'Transport of dredged material (Sec 103)' && amount === 'Fill Volume';


//   const requiredFields = [
//     'Waters_Name',
//     'Name',
//     'Activity',
//     'Resource_Type',
//     'Permanent_Loss',
//     'Impact_Duration',
//     'Amount_Type',
//     'Amount_Units',
//     'Proposed_Length',
//     'Proposed_Width',
//     'Proposed_Amount'
//   ];

//   data.forEach((row, i) => {

//     for (const field of requiredFields) {
//       if (isBlank(row[field])) {
//         errorList.push(`Impacts: row ${i + 2} - ${field} is a required field and cannot be blank!`);
//         resourcesValid = false;
//       }
//     }

//     if (!isValidUnit(row.Amount_Type, row.Amount_Units)) {
//       errorList.push(`Impacts: row ${i + 2} - Amount_Units are not valid for Amount_Type!`);
//       resourcesValid = false;
//     }

//     if (!isValidAmountType(row.Activity, row.Amount_Type)) {
//       errorList.push(`Impacts: row ${i + 2} - Amount_Type is not valid for Activity!`);
//       resourcesValid = false;
//     }

//   });

//   const JSXlistToReturn = formatErrorList(errorList);

//   return { valid: resourcesValid, msg: resourcesValid === false ? JSXlistToReturn : null };

// };

export const validatePermitteeRespMitigations = (data) => {
  let resourcesValid = true;
  let errorList = [];

  const formatErrorList = (errors) => (
    <ol>
      {errors.map((error, i) => <><li key={i}>{error}</li><br /></>)}
    </ol>
  );

  const isBlank = (val) => !val;

  const requiredFields = [
    'Waters_Name',
    'Name',
    'Mitigation_Type',
    'Permittee_Responsible_Type',
    'Resource_Type',
    'Proposed_Length',
    'Proposed_Width',
    'Proposed_Amount',
    'Amount_Units',
  ];

  data.forEach((row, i) => {

    for (const field of requiredFields) {
      if (isBlank(row[field])) {
        errorList.push(`Mit-PermitteeResp: row ${i + 2} - ${field} is a required field and cannot be blank!`);
        resourcesValid = false;
      }
    }

  });

  const JSXlistToReturn = formatErrorList(errorList);

  return { valid: resourcesValid, msg: resourcesValid === false ? JSXlistToReturn : null };

};

export const validateBankILFMitigations = (data) => {
  let resourcesValid = true;
  let errorList = [];

  const formatErrorList = (errors) => (
    <ol>
      {errors.map((error, i) => <><li key={i}>{error}</li><br /></>)}
    </ol>
  );

  const isValidUnit = (val, unit) =>
    (val === 'In-Lieu Fee' && (unit === 'Acre Based' || unit === 'Linear Feet Based' || unit === 'Advanced Credit' || unit === 'Assessment Based')) ||
    (val === 'Mitigation Bank' && (unit === 'Acre Based' || unit === 'Linear Feet Based' || unit === 'Advanced Credit'));


  const isBlank = (val) => !val;

  const requiredFields = [
    'Waters_Name',
    'Name',
    'Mitigation_Type',
    'Bank_OR_Program_Name',
    'Credit_Unit',
    'Proposed_Credits',
    'Required_Credits',
  ];

  data.forEach((row, i) => {

    for (const field of requiredFields) {
      if (isBlank(row[field])) {
        errorList.push(`MitBank_ILF: row ${i + 2} - ${field} is a required field and cannot be blank!`);
        resourcesValid = false;
      }
    }

    if (!isValidUnit(row.Mitigation_Type, row.Credit_Unit)) {
      errorList.push(`MitBank_ILF: row ${i + 2} - Credit_Unit is invalid for Mitigation_Type!`);
      resourcesValid = false;
    }

  });

  const JSXlistToReturn = formatErrorList(errorList);

  return { valid: resourcesValid, msg: resourcesValid === false ? JSXlistToReturn : null };

};

// export const validateAquaticResources = (data, regulation) => {
//   let resourcesValid = true;
//   let errorList = [];

//   const formatErrorList = (errors) => (
//     <>
//       <h6>{errors.length} errors found!</h6>
//       <ul>
//         {errors.map((error, i) => <><li key={i}>{error}</li><br key={`br${i}`} /></>)}
//       </ul>
//     </>
//   );

//   const isBlank = (val) => !val;
//   const isUnique = (values, field) => { const uniqueValues = new Set(); for (const obj of values) { uniqueValues.add(obj[field]); }; return uniqueValues.size === values.length; };
//   const isLength = (val, length) => val <= length;
//   const isGTZero = (val) => val > 0;
//   const isASCII = (val) => /^[\x20-\x7E]*$/.test(val);
//   const isLatitude = (val) => isFinite(val) && val <= 90 && val > 0;
//   const isLongitude = (val) => isFinite(val) && val >= -180 && val < 0;
//   const isValidUnit = (val, unit) => val === 'Area' && unit === 'ACRE' || val === 'Area' && unit === 'SQ_FT' || val === 'Linear' && unit === 'FOOT';
//   const isEnumerated = (val, enumlist) => enumlist.some(enumval => enumval.value === val);
//   const isValidARCowardinType = (Water, Cowardin) => (Water === 'UPLAND' && Cowardin !== 'U') ? false : true;

//   const requiredFields = [
//     'Waters_Name',
//     'State',
//     'Cowardin_Code',
//     'Meas_Type',
//     'Amount',
//     'Units',
//     'Waters_Type',
//     'Latitude',
//     'Longitude',
//   ];

//   data.forEach((row, i) => {

//     for (const field of requiredFields) {
//       if (isBlank(row[field])) {
//         errorList.push(`Aquatic Resources: row ${i + 2} - ${field} is a required field and cannot be blank!`);
//         resourcesValid = false;
//       }
//     }

//     if (!isValidUnit(row.Meas_Type, row.Units)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Has a Meas_Type, Units type mismatch! (i.e. "AREA" with "FOOT")`);
//       resourcesValid = false;
//     }

//     if (!isEnumerated(row.Cowardin_Code, cowardincodes)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Contains an invalid Cowardin_Code!`);
//       resourcesValid = false;
//     }

//     if (!isEnumerated(row.Waters_Type, regulation === 'RAPANOS' ? waterstype : watersType2023)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Contains an invalid Waters_Type!`);
//       resourcesValid = false;
//     }

//     if (!isEnumerated(row.State, stateslong)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Contains an invalid State!`);
//       resourcesValid = false;
//     }

//     if (!isEnumerated(row.Meas_Type, [{ value: 'Area' }, { value: 'Linear' }])) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Contains an invalid Meas_Type! (not "Area" or "Linear")`);
//       resourcesValid = false;
//     }

//     if (!isEnumerated(row.HGM_Code, hgmcodes) && !isBlank(row.HGM_Code)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Contains an invalid HGM_Code!`);
//       resourcesValid = false;
//     }

//     if (!isUnique(data, 'Waters_Name')) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Waters_Name field must be unique!`);
//       resourcesValid = false;
//     }

//     if (!isLength(row.Waters_Name.length, 100)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Waters_Name field must be <= 100 characters!`);
//       resourcesValid = false;
//     }

//     if (!isASCII(row.Waters_Name)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Waters_Name field contains invalid characters! (ASCII ONLY!)`);
//       resourcesValid = false;
//     }

//     if (!isLength(row.Local_Waterway.length, 500) && !isBlank(row.Local_Waterway)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Local_Waterway field must be <= 500 characters!`);
//       resourcesValid = false;
//     }

//     if (!isGTZero(row.Amount)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Amount field must be < 0!`);
//       resourcesValid = false;
//     }

//     if (!isLatitude(row.Latitude)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Latitude must be positive to indicate Northern Hemisphere!`);
//       resourcesValid = false;
//     }

//     if (!isLongitude(row.Longitude)) {
//       errorList.push(`Aquatic Resources: row ${i + 2} - Longitude must be negative to indicate Western Hemisphere!`);
//       resourcesValid = false;
//     }
//     if (regulation === 'RAPANOS') {
//       if (!isValidARCowardinType(row.Waters_Type, row.Cowardin_Code)) {
//         errorList.push(`Aquatic Resources: row ${i + 2} - Waters_Type does not correspond with Cowardin_Code! (i.e Waters_Type "UPLAND" must have a Cowardin_Code that beings with "U")`);
//         resourcesValid = false;
//       }
//     }
//   });

//   const JSXlistToReturn = formatErrorList(errorList);

//   return { valid: resourcesValid, msg: resourcesValid === false ? JSXlistToReturn : null };

// };

export const validatePropertyOwners = (data) => {
  let propOwnersValid = true;
  let errorList = [];

  const formatErrorList = (errors) => (
    <>
      <h6>{errors.length} errors found!</h6>
      <ul>
        {errors.map((error, i) => <><li key={i}>{error}</li><br key={`br${i}`} /></>)}
      </ul>
    </>
  );

  const isBlank = (val) => !val;
  const isValidPhoneType = (type) => isBlank(type) || Object.values(PhoneTypes).includes(type);
  const isValidPhoneNumber = (phone) => isBlank(phone) || phoneRegex.test(phone);
  const isValidPhoneNumberExt = (phone) => isBlank(phone) || phoneExtRegex.test(phone);
  const isValidZipcode = (val) => zipCodeRegex.test(val);
  const isValidState = (val) => states.some(state => state.value === val);
  const isValidCity = (val) => cityRegex.test(val);
  const isValidEmail = (val) => emailRegex.test(val);
  const isValidExt = (ext) => (isBlank(ext) || phoneExtRegex.test(ext));



  const requiredFields = [
    'firstName',
    'lastName',
    'address',
    'city',
    'state',
    'zipcode',
    'phoneOneType',
    'phoneOne',
    'emailAddress'
  ];

  data.forEach((row, i) => {

    for (const field of requiredFields) {
      if (isBlank(row[field])) {
        errorList.push(`Property Owner: row ${i + 2} - ${field} is a required field and cannot be blank!`);
        propOwnersValid = false;
      }
    }
    if (!isValidPhoneType(row.phoneOneType)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneOneType is not a valid phone type!`);
      propOwnersValid = false;
    }
    if (!isValidPhoneNumber(row.phoneOne)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneOne is not a valid phone number!`);
      propOwnersValid = false;
    }
    if (!isValidPhoneNumberExt(row.phoneOneExtension)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneOneExtension is not a valid extension!`);
      propOwnersValid = false;
    }

    if (!isValidPhoneNumber(row.phoneTwo)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneTwo is not a valid phone number!`);
      propOwnersValid = false;
    }

    if (!isValidPhoneNumberExt(row.phoneTwoExtension)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneTwoExtension is not a valid extension!`);
      propOwnersValid = false;
    }

    if (!isValidPhoneNumber(row.faxPhone)) {
      errorList.push(`Property Owner: row ${i + 2} - faxPhone is not a valid phone number!`);
      propOwnersValid = false;
    }

    if (!isValidZipcode(row.zipcode)) {
      errorList.push(`Property Owner: row ${i + 2} - zipcode is not valid!`);
      propOwnersValid = false;
    }

    if (!isValidCity(row.city)) {
      errorList.push(`Property Owner: row ${i + 2} - city is not valid!`);
      propOwnersValid = false;
    }

    if (!isValidState(row.state)) {
      errorList.push(`Property Owner: row ${i + 2} - state is not valid!`);
      propOwnersValid = false;
    }

    if (!isValidEmail(row.emailAddress)) {
      errorList.push(`Property Owner: row ${i + 2} - emailAddress is not valid!`);
      propOwnersValid = false;
    }

    if (!isValidExt(row.phoneOneExtension)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneOneExtension is not valid!`);
      propOwnersValid = false;
    }
    if (!isValidExt(row.phoneTwoExtension)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneTwoExtension is not valid!`);
      propOwnersValid = false;
    }

  });

  const JSXlistToReturn = formatErrorList(errorList);

  return { valid: propOwnersValid, msg: propOwnersValid === false ? JSXlistToReturn : null, status: propOwnersValid ? 'Success' : 'VALIDATION FAILED!' };
};
