import { useRef, useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import Icon from '@components/icon/Icon';
import { mdiDownload } from '@mdi/js';

import { FileTypes } from '@src/utils/enums';

import './../dashboard.scss';
import './../../Forms/components/gridCellRenderers/cellRenderers.scss';

const DownloadRenderer = connect(
  'doDownloadFile',
  'doModalOpen',
  ({
    doDownloadFile,
    dashboardData,
    data,
    api,
    column,
    rowIndex,
  }) => {
    const iconRef1 = useRef(null);

    const handleTab = (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
        document.activeElement === iconRef1.current && iconRef1.current.focus();
      }
    };

    useEffect(() => {
      const onCellFocused = (params) => {
        (params.column.colId === column.colId && params.rowIndex === rowIndex) && iconRef1.current.focus();
      };
      api.addEventListener('cellFocused', onCellFocused);
      return () => {
        api.removeEventListener('cellFocused', onCellFocused);
      };
    }, [api, column.colId, rowIndex]);

    const handleKeyUp = (e, iconIndex) => {
      switch (e.key) {
        case 'Enter' && iconIndex === 1:
          handlePDFDownload();
          break;
        case 'ArrowRight' && iconIndex === 1:
          iconRef1.current.focus();
          break;
        default:
          break;
      }
    };

    const handlePDFDownload = (e) => {
      const paramsID = { projectID: dashboardData.projectID, requestID: dashboardData.requestID, version: 0 };
      const fileName = data?.DocumentType ? `Generated/${data?.fileName}` : data?.fileName;
      doDownloadFile(fileName, FileTypes.Request, paramsID);
    };

    return (
      <div className='renderer-div'>
        <div role='button' title='Download File' onClick={handlePDFDownload} onKeyUp={e => handleKeyUp(e, 1)} onKeyDown={handleTab} tabIndex={0} ref={iconRef1} >
          <Icon className='cell-btn-icon info' path={mdiDownload} size={'16px'} />
        </div>
      </div >
    );
  });

export default DownloadRenderer;
