import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from '@trussworks/react-uswds';
import classnames from 'classnames';

/**
 *
 * @param {Array} tabs - List of tabs to display, objects structured as `{ content: <Element>, setButton = logic }`. Default `[]`.
 * @param {number} defaultTab - Default tab index to be set as active. Default `0`.
 * @param {string} contentClassName - Class(es) to be applied to the contents container element `<section />`.
 * @param {Function} onSave - Function triggered after last tab is reached.
 */
const ModalTabContainerNewRequest = connect(
  'doModalClose',
  'selectDistrictResults',
  ({
    contentClassName = '',
    defaultTab = 0,
    disableOnSave,
    districtResults,
    doModalClose,
    hideFooter,
    hideLastBtn,
    onSave = () => {},
    setActiveTab,
    tabs = [],
  }) => {
    const [currentTab, setCurrentTab] = useState(defaultTab);
    const [showPrevButton, setShowPrevButton] = useState(false);
    const [nextButtonText, setNextButtonText] = useState('Next');
    const district = districtResults?.[0];
    const sectionClasses = classnames('modal-body', contentClassName);

    const {
      formState: { isValid },
      trigger,
    } = useFormContext();

    // Display the specified tab of the form
    const showTab = (n) => {
      const tabs = document.getElementsByClassName('tab');

      tabs[n].style.display = 'block';

      setShowPrevButton(n !== 0);
      if (n === tabs.length - 1) {
        setNextButtonText('Continue');
      } else {
        setNextButtonText('Next');
      }
    };

    const nextPrev = (n) => {
      if (isValid && district) {
        const tabs = document.getElementsByClassName('tab');
        const buttons = document.getElementsByClassName('tab-button');

        tabs[currentTab].style.display = 'none';
        buttons[currentTab].style.display = 'none';

        setCurrentTab(currentTab + n);

        // if you have reached the end of the form
        if (currentTab >= tabs.length) return false;
      } else {
        if (isValid && !district) {
          alert(
            'Location is not in a valid USACE district or district has not been determined yet. Please verify the project location and try again.'
          );
        } else {
          trigger();
          toast.error('Please fill out all required fields!');
        }
      }
    };

    const handleCloseModal = () => doModalClose();

    const updateIndicator = (n) => {
      // removes the "active" class of all steps
      const steps = document.getElementsByClassName('step');
      if (steps.length > 0) {
        const stepsArr = Array.from(steps);
        stepsArr.map((step) => (step.className = step.className.replace(' active', '')));
        // adds the "active" class on the current step:
        steps[n].className += ' active';
      }
    };

    // Display the current tab
    useEffect(() => {
      const showButtons = (n) => {
        const buttons = document.getElementsByClassName('tab-button');
        if (buttons.length > 0) {
          buttons[n].style.display = (n === buttons.length - 1 && hideLastBtn) || hideFooter ? 'none' : 'inline';
        }
      };
      showTab(currentTab);
      showButtons(currentTab);
      updateIndicator(currentTab);
    }, [currentTab, hideFooter, hideLastBtn]);

    useEffect(() => {
      setActiveTab(currentTab);
    }, [currentTab, setActiveTab]);

    return (
      <>
        <section className={sectionClasses}>
          {tabs.map((item, index) => (
            <div key={index} className='tab'>
              {item.content}
            </div>
          ))}
        </section>
        {!hideFooter && (
          <div className='modal-footer'>
            <Button style={{ position: 'absolute', left: '15px' }} onClick={handleCloseModal}>
              Cancel
            </Button>
            <div>
              {tabs.map((_, index) => (
                <span key={index} className='step' />
              ))}
            </div>
            {showPrevButton && (
              <Button
                className='prev-button'
                id='prevBtn'
                onClick={() => nextPrev(-1)}
                outline
                style={{ opacity: '0.5' }}
              >
                Previous
              </Button>
            )}
            {tabs.map((item, index) => (
              <Button
                className='tab-button'
                disabled={item.setDisabled || (nextButtonText === 'Continue' && disableOnSave)}
                key={index}
                onClick={nextButtonText === 'Continue' ? onSave : () => nextPrev(1)}
                style={{ display: 'none' }}
                title={nextButtonText}
              >
                {nextButtonText}
              </Button>
            ))}
          </div>
        )}
      </>
    );
  }
);

export default ModalTabContainerNewRequest;
