import Icon from '@components/icon/Icon';
import { mdiEmailOutline } from '@mdi/js';

const PMEmailRender = ({ value, pmEmail, onClickEmail }) => {

  const handleClickEmail = () => {
    onClickEmail(pmEmail);
  };

  return (
    pmEmail === 'N/A' || pmEmail !== 'PM Email is not available.' ? (
      <span className='pm-name-no-email'>{value}</span>
    ) : (
      <div
        className='email-container'
        onClick={handleClickEmail}
      >
        <Icon
          className='email-icon'
          size={'20px'}
          path={mdiEmailOutline}
        />
        <span className='pm-name'>{value}</span>
      </div>
    )
  );
};

export default PMEmailRender;
