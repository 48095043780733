export const SiteSelectionColumns = [
  'Practicability Category',
  'Factor',
  'Alternative 1 Applicant\'s Preferred',
  'Alternative 2',
  'Alternative 3',
  'Alternative 4',
  'Alternative 5',
  'Alternative 6',
];

export const SiteSelectionData = [
  {
    'Practicability Category': 'Available',
    'Factor': 'Available for Acquisition',
    'Alternative 1 Applicant\'s Preferred': 'YES - Applicant owns the parcel',
    'Alternative 2': 'YES - Listed in multi-list',
    'Alternative 3': 'YES - Listed in multi-list',
    'Alternative 4': 'NO - Applicant does not have condemnation authority',
    'Alternative 5': 'YES - Listed in multi-list',
    'Alternative 6': 'YES - Listed in multi-list',
  },
  {
    'Practicability Category': 'Logistics',
    'Factor': 'Sufficient Parcel Size',
    'Alternative 1 Applicant\'s Preferred': 'YES - 800 acres',
    'Alternative 2': 'YES - 870 acres',
    'Alternative 3': 'YES - 770 acres',
    'Alternative 4': 'N/A - Failed availability screen',
    'Alternative 5': 'YES - 900 acres',
    'Alternative 6': 'NO - 600- did not provide adequate space for size range of project',
  },
  {
    'Practicability Category': '',
    'Factor': 'Existing Zoning Appropriate & Potential for Zoning Change',
    'Alternative 1 Applicant\'s Preferred': 'YES - Zoned for this project type',
    'Alternative 2': 'YES - Zoned for this project type',
    'Alternative 3': 'YES - Zoned for agriculture, City has not denied zone change',
    'Alternative 4': 'N/A',
    'Alternative 5': 'YES - Zoned for this project type',
    'Alternative 6': 'N/A - Failed sufficient parcel size screen',
  },
  {
    'Practicability Category': '',
    'Factor': 'Availability of Utilities',
    'Alternative 1 Applicant\'s Preferred': 'YES - Adjacent to site',
    'Alternative 2': 'YES - 0.5 miles to existing water, sewer and power',
    'Alternative 3': 'YES - Adjacent to site',
    'Alternative 4': 'N/A',
    'Alternative 5': 'YES - 6 miles to existing water, sewer and power',
    'Alternative 6': 'N/A',
  },
  {
    'Practicability Category': '',
    'Factor': 'Availability for Access',
    'Alternative 1 Applicant\'s Preferred': 'YES - County ROW on east property boundary',
    'Alternative 2': 'YES - County ROW to northwest property corner',
    'Alternative 3': 'NO - Landlocked by private parcels, request for easement denied, applicant does not have condemnation authority',
    'Alternative 4': 'N/A',
    'Alternative 5': 'YES - County ROW to northwest property corner',
    'Alternative 6': 'N/A',
  },
  {
    'Practicability Category': 'Existing Technology',
    'Factor': 'Topography and other Site Conditions Feasible for Construction of Project',
    'Alternative 1 Applicant\'s Preferred': 'YES -',
    'Alternative 2': 'YES - With use of engineered retaining walls and drainage systems',
    'Alternative 3': 'N/A - Failed access screen',
    'Alternative 4': 'N/A',
    'Alternative 5': 'YES - With use of engineered retaining walls, drainage systems and bridges',
    'Alternative 6': 'N/A',
  },
  {
    'Practicability Category': 'Cost (no cost threshold established)',
    'Factor': 'Reasonable Acquisition Costs (non- exorbitant)',
    'Alternative 1 Applicant\'s Preferred': 'YES - Applicant owns the parcel',
    'Alternative 2': 'YES - Within market normal costs for similar properties',
    'Alternative 3': 'N/A',
    'Alternative 4': 'N/A',
    'Alternative 5': 'NO - Exorbitant - costs are 10X normal costs for similar land',
    'Alternative 6': 'N/A',
  },
];