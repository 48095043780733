import { useState, useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@trussworks/react-uswds';
import ValidationCard from './ValidationCard';
import Spinner from '@components/spinner/spinner';
import Icon from '@components/icon/Icon';
import { mdiHome } from '@mdi/js';
import './AddressValidator.scss';

const AddressValidator = connect(
  'doValidateAddress',
  'selectAddressValidationResults',
  ({ doValidateAddress,
    addressValidationResults,
    address,
    isDisabled,
    source
  }) => {
    const { setValue } = useFormContext();
    const { address1, address2, city, state, zipcode } = address;
    const [suggestionUsed, setSuggestionUsed] = useState(false);
    const [showSuccessCard, setShowSuccessCard] = useState(true);
    const [showNoSuggestionsCard, setShowNoSuggestionsCard] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [isForm, setIsForm] = useState(false);

    const validateAddress = (source) => {
      if (source === 'modal') setIsModal(true);
      if (source === 'form') setIsForm(true);
      setSuggestionUsed(true);
      setShowSuccessCard(false);
      setShowNoSuggestionsCard(false);
      setLoading(true);
      doValidateAddress({
        address: address1,
        addressTwo: address2,
        city: city,
        state: state,
        zipcode: zipcode || '0',
      });
    };

    const toTitleCase = (str) => {
      if (!str) return '';
      return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    const populateFromSuggestion = () => {
      if (addressValidationResults?.validAddress) {
        const suggestedAddress = addressValidationResults.validAddress;
        setValue('address', toTitleCase(suggestedAddress?.Address2), { shouldValidate: true });
        setValue('addressTwo', toTitleCase(suggestedAddress?.Address1), { shouldValidate: true });
        setValue('city', toTitleCase(suggestedAddress?.City), { shouldValidate: true });
        setValue('state', suggestedAddress?.State, { shouldValidate: true });
        setValue('zipcode', `${suggestedAddress?.Zip5}`, { shouldValidate: true });
      }
    };

    useEffect(() => {
      setSuggestionUsed(true);
      setShowSuccessCard(false);
      setShowNoSuggestionsCard(false);
      setIsModal(false);
      setIsForm(false);
    }, [address]);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShowSuccessCard(false);
        setShowNoSuggestionsCard(false);
        isModal && setIsModal(false);
        isForm && setIsForm(false);
      }, 7000);
      return () => clearTimeout(timeoutId);
    }, [isModal, isForm]);

    useEffect(() => {
      setLoading(false);
      if (addressValidationResults.isValid) {
        setShowSuccessCard(true);
      }
      else if (!addressValidationResults.isValid && (!addressValidationResults.validAddress || addressValidationResults.validAddress.length === 0)) {
        setShowNoSuggestionsCard(true);
      }
      else {
        setSuggestionUsed(false);
      }
    }, [addressValidationResults.isValid, addressValidationResults.validAddress]);

    const renderValidationCard = () => (
      <ValidationCard clickSource={source} isValid message='Address Successfully Validated.' />
    );

    const renderNoSuggestionsCard = () => (
      <ValidationCard
        clickSource={source}
        message='No Suggestions Available'
        helperText={'Please ensure the address is entered correctly.'}
      />
    );

    const renderSuggestedAddress = () => (
      <>
        <div>
          <div>
            <strong>Suggested Address:</strong>
          </div>
          <div>{toTitleCase(addressValidationResults?.validAddress?.Address2)}</div>
          <div>{addressValidationResults?.validAddress?.Address1}</div>
          <div>{`${toTitleCase(addressValidationResults?.validAddress?.City)}, ${addressValidationResults?.validAddress?.State} ${addressValidationResults?.validAddress?.Zip5}`}</div>
          <Button className='use-address-button' onClick={populateFromSuggestion}>USE SUGGESTED ADDRESS</Button>
        </div>
      </>
    );

    return (
      <>
        <div className='row'>
          <div className='col-3'>
            <Button
              className='validate-address-button'
              onClick={isDisabled ? validateAddress : () => validateAddress(source)}
              disabled={isDisabled}
            >
              <Icon focusable={false} title='Validate Address Data' path={mdiHome} size={0.85} />
              VALIDATE ADDRESS
            </Button>
          </div>
          <div className='col-6'>
            {loading && <Spinner />}
            {isModal && showSuccessCard && addressValidationResults.isValid === true && renderValidationCard()}
            {isModal && showNoSuggestionsCard && addressValidationResults.isValid === false && (!addressValidationResults.validAddress || addressValidationResults.validAddress.length === 0) && renderNoSuggestionsCard()}
            {isModal && addressValidationResults.isValid === false && addressValidationResults?.validAddress && !suggestionUsed && renderSuggestedAddress()}
            {isForm && showSuccessCard && addressValidationResults.isValid === true && renderValidationCard()}
            {isForm && showNoSuggestionsCard && addressValidationResults.isValid === false && (!addressValidationResults.validAddress || addressValidationResults.validAddress.length === 0) && renderNoSuggestionsCard()}
            {isForm && addressValidationResults.isValid === false && addressValidationResults?.validAddress && !suggestionUsed && renderSuggestedAddress()}
          </div>
        </div>
      </>
    );
  }
);
export default AddressValidator;
