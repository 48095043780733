import { connect } from 'redux-bundler-react';
import Icon from '@components/icon/Icon';
import { mdiTooltipQuestion, mdiPhoneCheck, mdiCommentQuote, mdiAccountQuestion } from '@mdi/js';

import HelpModal from './modals/helpmodal';
import FeedbackModal from './modals/feedbackmodal';
import Breadcrumb from '@components/breadcrumb';
import ExternalLink from '@components/external-link/ExternalLink';
import LinkButton from '@components/link/linkButton';

import { FileTypes, ResourceFiles } from '@src/utils/enums';

import './help.scss';

const Help = connect(
  'doDownloadFile',
  'doModalOpen',
  ({
    doDownloadFile,
    doModalOpen,
  }) => (
    <div className='container'>
      <Breadcrumb pathname='Regulatory Request System Help Center' href='/home' hrefText='Home' />
      <div>
        <h3>How can we help?</h3>
        <hr className='header-border mb-1' />
      </div>
      <div className='row'>
        <div className='col mt-2'>
          <div className='card general-support-card'>
            <div className='card-body'>
              <h4 className='card-title'>
                <Icon focusable={false} className='icon general-support-icon mr-2' path={mdiTooltipQuestion} size={'24px'} />General Support & Inquiries Specific to the Regulatory Request System (RRS)
              </h4>
              <p className='card-text'>Need assistance with navigating the RRS application, encountering technical issues, or require help with account setup?</p>
              <p className='card-text'> Submit your email inquiry by clicking the link below and our support team will help answer your questions and resolve issues.</p>
              <p className='card-text'> If you are unsure of or need additional information regarding the USACE Regulatory Program, please use the "Contact Your Local Regulatory Office" link below.</p>
              <LinkButton className='btn general-support-button' onClick={() => doModalOpen(HelpModal)} title='Submit a Ticket' content='Submit a Ticket' />
              <h7 className='card-subtitle mb-2 ml-2'>Support agents available 9:00AM - 9:00PM EST Monday - Friday</h7>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col mt-4'>
          <div className='card office-support-card'>
            <div className='card-body'>
              <h4 className='card-title'>
                <Icon focusable={false} className='icon office-support-icon mr-2' path={mdiPhoneCheck} size={'24px'} />Contact Your Local Regulatory Office
              </h4>
              <p className='card-text'>Looking for help regarding USACE Regulatory Program guidance, policy, or regulations, or have questions related to the content or status of your request?</p>
              <p className='card-text'>The link below will allow you to contact the USACE district in your area that can provide specialized assistance.</p>
              <ExternalLink className='btn office-support-button' href='https://regulatory.ops.usace.army.mil/offices/' title='Contact Office' content='Contact Office' />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col mt-4'>
          <div className='card feedback-card'>
            <div className='card-body'>
              <h4 className='card-title'>
                <Icon focusable={false} className='icon feedback-icon mr-2' path={mdiCommentQuote} size={'24px'} />Feedback & Suggestions
              </h4>
              <p className='card-text'>Your feedback drives the improvement of the Regulatory Request System. Whether it's a suggestion for additional features, reporting a bug or sharing your overall experience. We're eager to hear from you.</p>
              <p className='card-text'>Submit your feedback by clicking the link below.</p>
              <LinkButton className='btn feedback-button' onClick={() => doModalOpen(FeedbackModal)} title='Submit Feedback' content='Submit Feedback' />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col mt-4'>
          <div className='card logingov-card'>
            <div className='card-body'>
              <h4 className='card-title'>
                <Icon focusable={false} className='icon logingov-icon mr-2' path={mdiAccountQuestion} size={'16px'} />Login.gov Help
              </h4>
              <p className='card-text'>The Regulatory Request System (RRS) uses Login.gov for authentication. Login.gov has its own support center, however here are some helpful hints for Login.gov as it relates to RRS.</p>
              <p className='card-text'>Click the link below to access step-by-step instructions on how to set up your account in Login.gov.</p>
              <LinkButton className='btn logingov-button' onClick={() => doDownloadFile(ResourceFiles.LoginGovHelpGuide, FileTypes.Resource)} title='Login.gov Help Guide [PDF, 1 page]' content='Login.gov Help Guide [PDF, 1 page]' />
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

export default Help;
