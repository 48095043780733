import React, { useEffect, useCallback } from 'react';
import Tooltip from '@components/tooltip/tooltip';
import { useFormContext } from 'react-hook-form';
import { Label, TextInput, Select, Grid, GridContainer } from '@trussworks/react-uswds';
import { formatUSPhoneNumber } from '@src/utils/helpers';
import { phonetypes } from '@src/app-pages/Forms/input-forms/_helper';
import classnames from 'classnames';


import './PhoneInput.scss';

const PhoneInput =
  ({
    className,
    hint,
    label,
    onBlur = () => { },
    onChange = () => { },
    phoneName,
    prefixName,
    phoneTypeName,
    extensionName,
    readOnly,
    required,
    tooltip,
    tooltipClickable,
    isProfile,
  }) => {
    const { register, watch, setValue, getValues, formState: { errors }, trigger } = useFormContext();
    const phoneOptions = phonetypes?.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>));
    const defaultOption = '--- Please select an option ---';
    const prefixError = errors[prefixName];
    const phoneError = errors[phoneName];
    const phoneTypeError = errors[phoneTypeName];
    const extensionError = errors[extensionName];

    const selectCss = classnames('phone-select-container', className, {
      'is-invalid': phoneTypeError ,
    });

    const prefixVal = watch(prefixName);
    const phoneVal = watch(phoneName);
    const phoneTypeVal = watch(phoneTypeName);

    const calculateMaxLength = useCallback(() => {
      const absoluteMax = 15;
      const phoneMax = absoluteMax - prefixVal?.length;
      const inValid = isNaN(phoneMax);
      const returnVal = inValid ? 14 : phoneMax;

      return returnVal;

    }, [prefixVal?.length]);

    useEffect(() => {
      const phoneVal = watch(phoneName);
      if (prefixVal === '1' && phoneVal?.replace(/\D/g, '')?.length > 1) {
        if (phoneVal.replace(/\D/g, '').length <= 10) {
          setValue(phoneName, formatUSPhoneNumber(phoneVal), { shouldValidate: true });
        }
        else {
          setValue(phoneName, formatUSPhoneNumber(phoneVal.substring(0, 10)), { shouldValidate: true });
        }
      }
    }, [prefixVal, watch, setValue, phoneName]);

    useEffect(() => {

      if (phoneVal?.replace(/\D/g, '')?.length > calculateMaxLength()) {
        setValue(phoneName, phoneVal.substring(0, calculateMaxLength(), { shouldValidate: true }));
      }

    }, [phoneName, phoneVal, setValue, calculateMaxLength]);


    const isValidPhoneKey = (key) => /[\d]|Backspace/.test(key);

    const handlePhoneChange = (e) => {
      const key = e?.key;
      const value = e?.target?.value;

      if (!isValidPhoneKey(key)) {
        e?.preventDefault();
      }
      if (prefixVal === '1') {
        const formattedNumber = formatUSPhoneNumber(value);
        setValue(phoneName, formattedNumber, { shouldValidate: true });
        onChange(e);
      }
      else {
        const digitsOnly = value?.replace(/\D/g, '');
        setValue(phoneName, digitsOnly, { shouldValidate: true });
        onChange(e);
      }
    };

    const handlePhoneBlur = (e) => {
      onBlur(e);
      trigger(phoneName);
    };

    const handleCountryCodeBlur = (e) => {
      if (phoneVal?.replace(/\D/g, '')?.length > calculateMaxLength()) {
        setValue(phoneName, phoneVal.substring(0, calculateMaxLength()), { shouldValidate: true });
      }
      if (prefixVal !== '1') {
        setValue(phoneName, phoneVal?.replace(/\D/g, ''), { shouldValidate: true });
      }
      onBlur(e);
      trigger(prefixName);
    };

    const { ref: typeRef, ...typeRest } = register(phoneTypeName, { onChange, onBlur, });
    const { ref: prefixRef, ...prefixRest } = register(prefixName, { onChange, onBlur: handleCountryCodeBlur,  });
    const { ref: phoneRef, ...phoneRest } = register(phoneName, { onChange: handlePhoneChange, onBlur: handlePhoneBlur, });
    const { ref: extensionRef, ...extensionRest } = register(extensionName, { onChange, onBlur,});

    return (
      <>
        <Label
          htmlFor={phoneName}>
          {label}
          {required ? <span className='asterisk-color'>*</span> : <span className='text-italic'> (optional)</span>}
          {tooltip && 
      <Tooltip 
        clickable={tooltipClickable}
        content={tooltip}
        header={label}
        iconStyle={{ marginLeft: '5px' }}
        name={phoneName} 
      /> }
        </Label>
        {hint && <div className='usa-hint' id={`${phoneName}_hint`}>{hint}</div>}
        <GridContainer>
          <Grid row gap='md'>
            {!isProfile && <Grid tablet={{
              col: 3
            }}>
              <div className={selectCss}>
                <Select
                  aria-invalid={phoneTypeError ? 'true' : 'false'}
                  aria-required={required ? 'true' : 'false'}
                  disabled={readOnly}
                  id={phoneTypeName}
                  inputRef={typeRef}
                  name={phoneTypeName}
                  required={required}
                  {...typeRest}
                >
                  <option value='' style={{ display: required ? 'none' : 'inline' }}>{`${defaultOption}`}</option>
                  {phoneOptions}
                </Select>
                <Label htmlFor={phoneTypeName} className='small-label'>
                  Phone Type
                </Label>
              </div>
            </Grid> }
            <Grid  tablet={{
              col: isProfile ? 2 : 3
            }}>
              <div className='input-container'> 
                <TextInput
                  defaultValue={getValues(prefixName)}
                  readOnly={readOnly}
                  id={prefixName}
                  inputRef={prefixRef}
                  name={prefixName}
                  required={required}
                  type='text'
                  validationStatus={prefixError && 'error'}
                  {...prefixRest}
                />
              </div>
              <Label htmlFor={prefixName} className='small-label'>
                    Country Code
              </Label>
            </Grid>
            <Grid tablet={{
              col: isProfile ? 4 : 'fill'
            }}>
              <div className='input-container'>
                <TextInput
                  defaultValue={getValues(phoneName)}
                  readOnly={readOnly}
                  id={phoneName}
                  inputRef={phoneRef}
                  name={phoneName}
                  required={required}
                  type='text'
                  validationStatus={phoneError && 'error'}
                  {...phoneRest}
                />
              </div>
              <Label htmlFor={phoneName} className='small-label'>
                     Area Code and Phone Number
              </Label>
            </Grid>
            {phoneTypeVal === 'Business' && !isProfile && 
            <Grid tablet={{
              col: 2
            }}>
              <div className='input-container'>
                <TextInput
                  defaultValue={getValues(extensionName)}
                  readOnly={readOnly}
                  id={extensionName}
                  inputRef={extensionRef}
                  maxLength='5'
                  name={extensionName}
                  required={required}
                  type='text'
                  validationStatus={extensionError && 'error'}
                  {...extensionRest}
                />
              </div>
              <Label htmlFor={extensionName} className='small-label'>
                Extension
              </Label>
            </Grid>}
          </Grid>
        </GridContainer>
      </>

    );
  };
export default PhoneInput;
