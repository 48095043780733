import React from 'react';
import { Table } from '@trussworks/react-uswds';

const HGMCodeInfoTable = () => (
  <Table compact bordered striped fullWidth>
    <thead>
      <tr>
        <th scope='col'>HGM Code</th>
        <th scope='col'>Name</th>
        <th scope='col'>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope='row'>DEPRESS</th>
        <td>
              Depressional
        </td>
        <td>Depressional is characterized by a water source consisting of return flow from groundwater and interflow with primarily vertical hydrodynamics.</td>
      </tr>
      <tr>
        <th scope='row'>ESTUARINEF</th>
        <td>
              Estuarine Fringed
        </td>
        <td>The water source of the estuarine fringe consists of overbank flow from estuaries, with bidirectional and horizontal hydrodynamics being dominant.</td>
      </tr>
      <tr>
        <th scope='row'>LACUSTRINF</th>
        <td>
              Lacustrine Fringe
        </td>
        <td>A Lacustrine fringe has a dominant water source of lake overbank flow, and the dominant hydrodynamics are bidirectional and horizontal.</td>
      </tr>
      <tr>
        <th scope='row'>MINSOILFLT</th>
        <td>
              Mineral Soil Flats
        </td>
        <td>Mineral soil flats have a water source of precipitation, and vertical hydrodynamics are dominant.</td>
      </tr>
      <tr>
        <th scope='row'>ORGSOILFLT</th>
        <td>
              Organic Soil Flats
        </td>
        <td>Organic soil flats have precipitation as the water source, and its dominant hydrodynamic is vertical.</td>
      </tr>
      <tr>
        <th scope='row'>RIVERINE</th>
        <td>
              Riverine
        </td>
        <td>Riverine is characterized by a water source of overbank flow from a channel, and hydrodynamics which are predominantly unidirectional and horizontal.</td>
      </tr>
      <tr>
        <th scope='row'>SLOPE</th>
        <td>
              Slope
        </td>
        <td>The Slope wetland class is characterized by a water source of return flow from groundwater, with principally unidirectional and horizontal hydrodynamics.</td>
      </tr>
    </tbody>
  </Table>
);

export default HGMCodeInfoTable;
