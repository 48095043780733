import React from 'react';
import { Table } from '@trussworks/react-uswds';

import { SiteSelectionColumns, SiteSelectionData } from './_SiteSelection';

import './SiteSelectionCriteriaTable.scss';

const SiteSelectionCriteriaTable = () => (
  <Table compact bordered striped fullWidth className='table-container'>
    <thead>
      <tr>
        {SiteSelectionColumns.map((column) => (
          <th key={column}>{column}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {SiteSelectionData.map((row, i) => (
        <tr key={i}>
          {SiteSelectionColumns.map((column) => (
            <td key={column}><span className={column === 'Practicability Category' && 'text-bold'}>{row[column]}</span></td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>
);

export default SiteSelectionCriteriaTable;
