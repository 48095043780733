import '../../resources.scss';

// Reusable custom components

export const SectionHeader = ({ children }) => (
  <div className='mt-2'>
    <h5>{children}</h5>
    <hr className='header-border' />
  </div>
);

// Static Content Tooltip Content

export const dredgeTooltipContent = <>The term <i>dredged material</i> means material that is excavated or dredged from waters of the United States.</>;

export const fillTooltipContent = <>The term <i>discharge of fill material</i> means the addition of fill material into waters of the United States. The term generally includes, without limitation, the following activities: Placement of fill that is necessary for the construction of any structure or infrastructure in a water of the United States; the building of any structure, infrastructure, or impoundment requiring rock, sand, dirt, or other material for its construction;
  site-development fills for recreational, industrial, commercial, residential, or other uses; causeways or road fills; dams and dikes; artificial islands; property protection and/or reclamation devices such as riprap, groins,
  seawalls, breakwaters, and revetments; beach nourishment; levees; fill for structures such as sewage treatment facilities, intake and outfall pipes associated with power plants and subaqueous utility lines; placement of fill
  material for construction or maintenance of any liner, berm, or other infrastructure associated with solid waste landfills; placement of overburden, slurry, or tailings or similar mining-related materials; and artificial reefs.
  The term does not include plowing, cultivating, seeding and harvesting for the production of food, fiber, and forest products.</>;

export const WOTUSTooltipContent = <>"Waters of the U.S." (WOTUS) are waters such as oceans, rivers, streams, lakes, ponds, and wetlands subject to federal jurisdiction under Section 404 of the CWA.</>;

export const RGPTooltipContent = <>A regional or programmatic general permit is issued by the division or district engineer that has regulatory jurisdiction over the geographic area in which the general permit will be used.</>;

export const NWPTooltipContent = <>Nationwide permits are general permits issued nationwide to authorize categories of similar activities with minor impacts. Individual Districts have developed regional conditions and can exclude specific NWPs in their geographic region in order to provide additional protection for the aquatic environment. Nationwide permits authorize specific activities in areas under USACE Regulatory jurisdiction. These activities are minor in scope and must result in no more than minimal adverse impacts, both individually and cumulatively. Individuals wishing to perform work under a nationwide permit must ensure their project meets all applicable terms and conditions. Processing time usually takes 30 to 45 days.</>;

export const standardPermitTooltipContent = <>There are two types of IPs- Letters of Permission and Standard Permits (SP).  SPs are the only type that requires a public notice to advertise the proposed work to the regulated public.</>;

export const generalPermitTooltipContent = <>A more streamlined review when the project meets certain criteria – consists of Nationwide Permits, Regional General Permits, and Programmatic General Permits.</>;

export const projectDescriptionTooltipContent = <>The written descriptions and illustrations are an important part of the application. Please describe, in detail, what you wish to do. Describe the permitted activity and its intended use with references to any attached plans or drawings that are considered to be a part of the project description. Include a description of the types and quantities of dredged or fill materials to be discharged in jurisdictional waters.</>;

export const projectPurposeTooltipContent = <>Describe the purpose and need for the proposed project. What will it be used for and why? Also include a brief description of any related activities to be developed as the result of the proposed project. Give the approximate dates you plan to both begin and complete all work.</>;

export const natureOfActivityTooltipContent = <>Describe the overall activity or project. Give appropriate dimensions of structures such as wing walls, dikes (identify the materials to be used in construction, as well as the methods by which the work is to be done), or excavations (length, width, and height). Indicate whether discharge of dredged or fill material is involved. Also, identify any structure to be constructed on a fill, piles, or float-supported platforms.</>;

export const wotusDelineationReportTooltipContent = <>A document or series of documents, stored in the USACE administrative record for the project, that describes the methodology used to conduct the delineation and typically includes<br></br>a collection of one or more maps, datasheets, photographs, and/or figures that depict and support a legally defensible delineation of waters of the United States on a property.</>;

export const aquaticResourceInventoryTooltipContent = <>An accounting of all aquatic resources, including all waters (streams, lakes, ponds, etc.) and wetlands, within the project site. Information provided will include the type, geometry, and measurements of these resources in the project area.</>;

export const jurisdictionalDeterminationTooltipContent = <>A written determination by the USACE identifying which delineated areas are or may be (depending on the type of JD requested) regulated by the USACE under Section 404 of the Clean Water Act and/or Section 10 of the Rivers and Harbors Act.</>;