import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Button } from '@trussworks/react-uswds';
import '@styles/_buttons.scss';

const DeleteButton = connect(
  'doModalClose',
  ({
    doModalClose,
    deleteText,
    handleDelete,
    customClosingLogic,
  }) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const onDelete = (e) => {
      setIsConfirming(false);
      handleDelete(e);
      if (!customClosingLogic) doModalClose();
    };

    return (
      isConfirming ? (
        <div className='btn-group'>
          <Button
            className='danger-button'
            title='Confirm'
            size='small'
            onClick={onDelete}
          >
            Confirm
          </Button> 
          <Button
            className='cancel-button'
            title='Cancel'
            size='small'
            onClick={() => setIsConfirming(false)}
          >
            Cancel
          </Button> 
        </div>
      ) : (
        <Button
          className='danger-button'
          title={{deleteText}}
          size='small'
          onClick={() => setIsConfirming(true)}
        >
          {deleteText}
        </Button> 
      )
    );
  }
);

export default DeleteButton;
