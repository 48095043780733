import { useEffect, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import FieldHeader from '@forms/components/Form/FieldHeader';
import DateRange from '@components/date-range/DateRange';
import useErrorFocus from '@hooks/useErrorFocus';
import TextAreaInput from '@components/textarea/TextArea';
import Checkbox from '@components/checkbox/Checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { dateBeforeB } from '@src/utils/helpers';
import ErrorSummary from '@components/error-summary/ErrorSummary';

export const ViolationsFormGeneralSiteInformationMetadata = {
  sectionName: 'General Site Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const schema = yup.object().shape({
  descriptionOfAllegedViolation: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', val => val.toUpperCase() !== 'DEFAULT_VALUE').required('Field is required'),
  waterBodyType: yup.string().required('Field is required'),
  materialDischarged: yup.string().required('Field is required'),
  equipmentUsed: yup.string().required('Field is required'),
  isWorkOngoing: yup.boolean().required('Check a value'),
});

const ViolationsFormGeneralSiteInformation = connect(
  'doUpdateSectionValidity',
  'doUpdateViolationComplaintRequest',
  'selectViolationComplaintData',
  'selectSteps',
  'selectActiveStep',
  ({
    doUpdateSectionValidity,
    doUpdateViolationComplaintRequest,
    violationComplaintData,
    steps,
    activeStep,
    stepNo,
    isReadOnly,
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    const defaultValues = {
      descriptionOfAllegedViolation: violationComplaintData?.descriptionOfAllegedViolation ?? null,
      waterBodyType: violationComplaintData?.waterBodyType ?? null,
      materialDischarged: violationComplaintData?.materialDischarged ?? null,
      equipmentUsed: violationComplaintData?.equipmentUsed ?? null,
      workBeganDate: violationComplaintData?.workBeganDate ? violationComplaintData.workBeganDate.slice(0, 10) : '' ?? '',
      workEndDate: violationComplaintData?.workEndDate ? violationComplaintData.workEndDate.slice(0, 10) : '' ?? '',
      isWorkOngoing: violationComplaintData?.isWorkOngoing ?? false,
    };

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { errors, isValid }, setValue, watch, getValues, trigger } = methods;

    const isWorkOngoing = watch('isWorkOngoing');

    useEffect(() => {
      doUpdateSectionValidity(ViolationsFormGeneralSiteInformationMetadata.sectionName, isValid ? true : false, stepNo, isReadOnly);
    }, [isValid, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      isWorkOngoing && setValue('workEndDate', null);
    }, [isWorkOngoing, setValue]);

    const handleChange = () => {
      const values = getValues();
      const newValues = {
        ...values,
        descriptionOfAllegedViolation: values?.descriptionOfAllegedViolation
      };
      doUpdateViolationComplaintRequest(newValues);
    };

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }

        <FieldHeader
          text='General Site Information'
          subtext='Provide a detailed description of the alleged violation/activity (type of waterbody affected, estimated amount of impact, type of
              fill material, and type of equipment being used). Include start and end dates of observed activity, if known. Be as specific as possible.
              Provide a general description if any of this information is unknown.'
        />

        <div className='margin-left-1'><TextAreaInput name='descriptionOfAllegedViolation' label='General Description of Alleged Violation (provide as much information as possible, including the purpose of the work and estimated amount of impact, if known)' onChange={handleChange} required readOnly={isReadOnly} /></div>

        <div className='margin-left-1'><TextAreaInput name='waterBodyType' label='Type of Waterbody Affected (e.g., river, stream, lake, pond, wetland, etc.)' onChange={handleChange} required readOnly={isReadOnly} /></div>

        <div className='margin-left-1'><TextAreaInput name='materialDischarged' label='Type of Material Discharged (e.g., Dirt, Gravel, Rock, Construction Debris, Tires, Other, etc.)' onChange={handleChange} required readOnly={isReadOnly} /></div>

        <div className='margin-left-1'><TextAreaInput name='equipmentUsed' label='Equipment Used (e.g., backhoe, bulldozer, dump truck, etc.)' onChange={handleChange} required readOnly={isReadOnly} /></div>

        <div className='row margin-bottom-3'>
          <div className='col-lg-8'>
            <DateRange
              className='col'
              nameBefore='workBeganDate'
              nameAfter='workEndDate'
              labelBefore='Date Work Began'
              labelAfter='Date Work Ended'
              dateBefore={dateBeforeB}
              onChange={handleChange}
              isOnGoing={isWorkOngoing}
              readOnly={isReadOnly}
            />
          </div>
          <div className='col-lg-4 padding-top-3' >
            <fieldset id='isWorkOngoing'>
              <Checkbox id='work-ongoing' name='isWorkOngoing' label='Check box if the work is still ongoing' onChange={handleChange} disabled={isReadOnly} />
            </fieldset>
          </div>
        </div>
      </FormProvider>
    );
  }
);
ViolationsFormGeneralSiteInformation.metadata = ViolationsFormGeneralSiteInformationMetadata;

export default ViolationsFormGeneralSiteInformation;
