import Icon from '@components/icon/Icon';
import { mdiCloseCircle, mdiCheckCircle } from '@mdi/js';

const ValidationCard = ({ message, helperText, isValid, className, id }) => {
  const borderColor = isValid ? 'rgba(0, 128, 0, 0.45)' : 'rgba(255, 0, 0, 0.45)';
  const styles = {
    errorCard: {
      boxSizing: 'border-box',
    },
    messageContainer: {
      backgroundColor: !isValid ? 'rgba(255, 199, 199, 0.75)' : 'rgba(199, 255, 211, 0.75)',
      border: `1px solid ${borderColor}`,
      borderRadius: '5px',
      color: !isValid ? '#4c0000' : 'green',
      borderLeft: `5px solid ${isValid ? 'green' : 'red'}`,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: '5px',
      paddingLeft: '5px',
    },
    errorIcon: {
      fontSize: '20px',
      paddingRight: '5px',
      color: !isValid ? 'red' : 'green'
    },
    helperTextContainer: {
      color: 'black',
      fontStyle: 'italic',
      marginBottom: '2px',
      paddingLeft: '5px',
      textAlign: 'center',
    },
  };
  return (
    <div style={styles.errorCard} className={`mb-2 ${className}`} id={id}>
      <div style={styles.messageContainer}>
        <Icon
          path={`${!isValid ? mdiCloseCircle : mdiCheckCircle}`}
          style={{ ...styles.errorIcon }}
          size={'24px'}
        />
        <div className={`mr-3 ${helperText && 'w-100'}`} >
          {message}
          {!isValid && helperText && <div style={styles.helperTextContainer}>{helperText}</div>}
        </div>
      </div>
    </div >
  );
};
export default ValidationCard;
