import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import IPFormInstructions from '@forms/static-forms/IPForm2aInstructions';
import SupportingInformation from '@forms/input-forms/common/SupportingInformation';
import PermitsFormContacts from '@forms/input-forms/PermitsForm/PermitsFormContacts';
import IPFormGeneralProjectInformation from '@forms/input-forms/PermitsForm/PermitsFormGeneralProjectInformation/IPFormGeneralProjectInformation';
import PermitsFormOtherLawsRegulations from '@forms/input-forms/PermitsForm/PermitsFormOtherLawsRegulations/PermitsFormOtherLawsRegulations';
import PermitsFormAdjoiningPropertyOwners from '@forms/input-forms/PermitsForm/PermitsFormAdjoiningPropertyOwners';
import PermitsFormAquaticResources from '@forms/input-forms/PermitsForm/PermitsFormAquaticResources/PermitsFormAquaticResources';
import PermitsFormImpactsMitigation from '@forms/input-forms/PermitsForm/PermitsFormImpactsMitigation';
import PermitsFormCertifySignSubmit from '@forms/input-forms/PermitsForm/PermitsFormCertifySignSubmit';


//List of steps for vertical stepper, these can also be used for form header. index = step ID 
export const IPForm2aMetadata = [
  IPFormInstructions.metadata,
  PermitsFormContacts.metadata,
  IPFormGeneralProjectInformation.metadata,
  PermitsFormAdjoiningPropertyOwners.metadata,
  PermitsFormOtherLawsRegulations.metadata,
  PermitsFormAquaticResources.metadata,
  PermitsFormImpactsMitigation.metadata,
  SupportingInformation.metadata,
  PermitsFormCertifySignSubmit.metadata,
];

const IPForm2a = connect(
  'doUpdatePermitRequest',
  'selectActiveStep',
  'selectIsReadOnly',
  'selectPermitRequestID',
  'selectPermitData',
  ({
    doUpdatePermitRequest,
    activeStep,
    isReadOnly,
    permitRequestID,
    permitData,
    edit,
  }) => {
    const [showInstructions, setShowInstructions] = useState(false);
    const [showContactInfo, setShowContactInfo] = useState(false);
    const [showGeneralProjectInfo, setShowGeneralProjectInfo] = useState(false);
    const [showAdjoiningPropertyOwners, setShowAdjoiningPropertyOwners] = useState(false);
    const [showOtherLawsAndRegulations, setShowOtherLawsAndRegulations] = useState(false);
    const [showAquaticResources, setShowAquaticResources] = useState(false);
    const [showProjectImpactsAndMitigation, setShowProjectImpactsAndMitigation] = useState(false);
    const [showSupportingInfo, setShowSupportingInfo] = useState(false);
    const [showCertifySignSubmit, setShowCertifySignSubmit] = useState(false);

    //display only active step, hide all other steps
    useEffect(() => {
      if (activeStep) {
        switch (activeStep.id) {
          case 0: {
            setShowInstructions(true);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowAdjoiningPropertyOwners(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 1: {
            setShowInstructions(false);
            setShowContactInfo(true);
            setShowGeneralProjectInfo(false);
            setShowAdjoiningPropertyOwners(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 2: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(true);
            setShowAdjoiningPropertyOwners(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 3: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowAdjoiningPropertyOwners(true);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 4: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowAdjoiningPropertyOwners(false);
            setShowOtherLawsAndRegulations(true);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 5: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowAdjoiningPropertyOwners(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(true);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 6: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowAdjoiningPropertyOwners(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(true);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 7: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowAdjoiningPropertyOwners(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(true);
            setShowCertifySignSubmit(false);
            break;
          }
          case 8: {
            setShowInstructions(false);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowAdjoiningPropertyOwners(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(true);
            break;
          }
          default: {
            setShowInstructions(true);
            setShowContactInfo(false);
            setShowGeneralProjectInfo(false);
            setShowAdjoiningPropertyOwners(false);
            setShowOtherLawsAndRegulations(false);
            setShowAquaticResources(false);
            setShowProjectImpactsAndMitigation(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
          }
        }
      }
    }, [activeStep]);


    const instructionStyles = { display: showInstructions ? 'inline' : 'none' };
    const generalProjInfoStyles = { display: showGeneralProjectInfo ? 'inline' : 'none' };
    const contactInfoStyles = { display: showContactInfo ? 'inline' : 'none' };
    const otherLawsAndRegulationsStyles = { display: showOtherLawsAndRegulations ? 'inline' : 'none' };
    const adjoiningPropertyOwnerStyles = { display: showAdjoiningPropertyOwners ? 'inline' : 'none' };
    const ARStyles = { display: showAquaticResources ? 'inline' : 'none' };
    const projectImpactsAndMitigationStyles = { display: showProjectImpactsAndMitigation ? 'inline' : 'none' };
    const supportingInfoStyles = { display: showSupportingInfo ? 'inline' : 'none' };
    const certifySignSubmitStyles = { display: showCertifySignSubmit ? 'inline' : 'none' };

    return (<>
      <section id='IP2aInstructions' name='Instructions' style={instructionStyles}>
        <IPFormInstructions stepNo={0} />
      </section>
      <section id='IPFormContactInformation' name='Contact Information' style={contactInfoStyles}>
        <PermitsFormContacts componentID={permitRequestID} edit={edit} isReadOnly={isReadOnly} stepNo={1} />
      </section>
      <section id='IPFormGeneralProjectInformation' name='General Project Information' style={generalProjInfoStyles}>
        <IPFormGeneralProjectInformation isReadOnly={isReadOnly} stepNo={2} />
      </section>
      <section id='IPAdjoiningPropertyOwners' name='Other Laws And Regulations' style={adjoiningPropertyOwnerStyles}>
        <PermitsFormAdjoiningPropertyOwners isReadOnly={isReadOnly} stepNo={3} />
      </section>
      <section id='IPOtherLawsAndRegulations' name='Other Laws And Regulations' style={otherLawsAndRegulationsStyles}>
        <PermitsFormOtherLawsRegulations isReadOnly={isReadOnly} stepNo={4} />
      </section>
      <section id='IPFormAquaticResources' name='Aquatic Resources' style={ARStyles}>
        <PermitsFormAquaticResources isReadOnly={isReadOnly} stepNo={5} />
      </section>
      <section id='IPProjectImpactsAndMitigation' name='Project Impact and Mitigation' style={projectImpactsAndMitigationStyles}>
        <PermitsFormImpactsMitigation isReadOnly={isReadOnly} stepNo={6} />
      </section>
      <section id='IP2aSupportingInformation' name='Supporting Information' style={supportingInfoStyles}>
        <SupportingInformation
          componentID={permitRequestID}
          subtext={<><p>Please upload supporting documentation including project plans, state water certifications, etc. Multiple documents of the same file type can be combined and uploaded as one document.</p></>}
          requestData={permitData}
          updateRequestData={doUpdatePermitRequest}
          isReadOnly={isReadOnly}
          stepNo={7}
        />
      </section>
      <section id='IP2aCertifySignSubmit' name='Certify Sign Submit' style={certifySignSubmitStyles}>
        <PermitsFormCertifySignSubmit isReadOnly={isReadOnly} stepNo={8} />
      </section>
    </>);

  });

export default IPForm2a;
