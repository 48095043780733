import { Modal } from '@trussworks/react-uswds';
import { ModalHeader } from '.';
import { connect } from 'redux-bundler-react';
import useListener from '@src/customHooks/useListener';
import classNames from 'classnames';
import '@styles/_modals.scss';

const sizeMap = {
  sm: false,
  md: true,
  lg: true,
};

const ModalContent = connect(
  'doModalClose',
  ({
    children,
    className,
    closeWithEscape,
    doModalClose,
    hasCloseButton = false,
    isLogout = false,
    size = 'lg',
    title,
    ...customProps
  }) => {
    const modalClasses = classNames(className, size === 'md' && 'modal-md');

    useListener('keyup', (e) => {
      if (hasCloseButton && (e.key === 'Esc' || e.key === 'Escape')) {
        doModalClose();
      }
    });

    useListener('click', (e) => {
      const isOverlayClick = e.target.getAttribute('data-testId') === 'modalOverlay';
      const isCloseBtnClick = e.target.getAttribute('data-close-modal') === 'true';
      const isPrimaryModal = !!e.target?.closest('[role=dialog]')?.querySelector('[data-primary-modal]');

      if (isPrimaryModal && hasCloseButton && (isOverlayClick || isCloseBtnClick)) {
        doModalClose();
      }
    });

    return (
      <Modal
        aria-describedby='primary-modal-content'
        aria-labelledby='primary-modal-header'
        className={modalClasses}
        data-primary-modal='true'
        forceAction={!hasCloseButton}
        isInitiallyOpen
        isLarge={sizeMap[size || 'lg']}
        {...customProps}
      >
        <ModalHeader title={title} />
        <div id='primary-modal-content'>{children}</div>
      </Modal>
    );
  }
);

export default ModalContent;
