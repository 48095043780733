import React, { useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import { handleKeyboardAction } from '@src/utils/keyboard';
import { mdiAlertCircle, mdiHelpCircle } from '@mdi/js';
import classnames from 'classnames';

import Icon from '@components/icon/Icon';

import './tooltip.scss';
import tooltipModal from './tooltipModal';

const iconSizes = {
  small: '15px',
  medium: '18px',
  large: '20px',
  'x-large': '28px',
};

const getContentLength = (content) => {
  if (typeof content === 'string') {
    return content.length;
  } else {
    let length = 0;
    React.Children.forEach(content, (child) => {
      if (typeof child === 'string') {
        length += child.length;
      } else if (React.isValidElement(child) && child.props.children) {
        length += getContentLength(child.props.children);
      }
    });
    return length;
  }
};

const DisplayIcon = ({ onClick, onKeyUp, iconSize, isError, title }) => {
  const iconPath = isError ? mdiAlertCircle : mdiHelpCircle;
  const className = classnames('info-tooltip-icon', {
    'info-tooltip-error': isError,
  });

  return (
    <Icon
      aria-label={title}
      className={className}
      onClick={onClick}
      onKeyUp={onKeyUp}
      path={iconPath}
      size={iconSizes[iconSize] || iconSizes.medium}
      tabIndex={0}
      title={title}
    />
  );
};

const Tooltip = connect(
  'doSecondaryModalOpen',
  ({ content, doSecondaryModalOpen, header, iconSize = 'medium', isError, title = 'view more information' }) => {
    const handleTooltipOpen = useCallback(
      (e) => {
        const contentLength = getContentLength(content);
        if (!contentLength) return;

        doSecondaryModalOpen(tooltipModal, {
          isError: isError,
          msg: content,
          size: contentLength < 256 ? 'sm' : 'md',
          title: header,
        });
      },
      [content, doSecondaryModalOpen, header, isError]
    );
    const handleKeyUp = (e) => handleKeyboardAction(e, handleTooltipOpen);

    return (
      <DisplayIcon
        iconSize={iconSize}
        isError={isError}
        onClick={handleTooltipOpen}
        onKeyUp={handleKeyUp}
        title={title}
      />
    );
  }
);

export default Tooltip;
