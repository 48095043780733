import React from 'react';
import { connect } from 'redux-bundler-react';

import Hero from '../../app-components/hero';
import ModuleButton from './components/_shared/ModuleButton';
import isAnonymousModal from './components/modals/isAnonymous';
import NewApplicationModal from '../Home/components/modals/NewApplication';

// import gettingStartedIcon from '../../styles/images/help-tool-round.png';
import jurisdictionIcon from '../../styles/images/Jurisdiction.png';
import mitigationIcon from '../../styles/images/Mitigation.png';
import permittingIcon from '../../styles/images/Permitting.png';
import programOverviewIcon from '../../styles/images/Regulatory-101.png';
import publicNoticesIcon from '../../styles/images/Public-Notices.png';
import violationIcon from '../../styles/images/Report-Violations.png';
// import announcementsIcon from '../../styles/images/regulatory-announcements.png';
import applyPermitIcon from '../../styles/images/Apply-for-Permit.png';
import rrsIcon from '../../styles/images/RRS.png';

import './resources.scss';
import { ProfileRoles } from '../../utils/enums';

const Resources = connect(
  'doModalOpen',
  'doUpdateRelativeUrl',
  'doResetReduxFormData',
  'doAuthLogin',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  ({
    doModalOpen,
    doUpdateRelativeUrl,
    doResetReduxFormData,
    doAuthLogin,
    authIsLoggedIn,
    userProfileData,
  }) => {

    const handleViolationComplaint = () => {
      doResetReduxFormData();
      doModalOpen(isAnonymousModal);
    };

    const handlePermits = () => {
      sessionStorage.setItem('PermitButtonSession', true);
      if (authIsLoggedIn) {
        const PermitButtonSession = sessionStorage.getItem('PermitButtonSession');
        doUpdateRelativeUrl('/home');
        doResetReduxFormData();
        PermitButtonSession && doModalOpen(NewApplicationModal);
        sessionStorage.removeItem('PermitButtonSession');
      }
      else {
        doAuthLogin();
      }
    };

    return (
      <>
        <Hero />
        <div className='container homepage-info-container'>
          <div className='p-2'>
            <div className='row'>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1'>
                <ModuleButton
                  title='Regulatory Program Information'
                  imageAlt='Regulatory Program logo'
                  text='Learn the basics about the Regulatory Program, including recent announcements.'
                  image={programOverviewIcon}
                  onClick={() => doUpdateRelativeUrl('/home/regulatory-program')}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1'>
                <ModuleButton
                  title='Jurisdiction'
                  imageAlt='Navigate to Jurisdiction'
                  text='Does the property in question contain wetlands/waters? If so, does the USACE have jurisdiction?'
                  image={jurisdictionIcon}
                  onClick={() => doUpdateRelativeUrl('/home/jurisdiction')}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1'>
                <ModuleButton
                  title='Permitting'
                  imageAlt='Navigate to Permitting'
                  text='Do you need a permit? Need to schedule a pre-application meeting?'
                  image={permittingIcon}
                  onClick={() => doUpdateRelativeUrl('/home/permitting')}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1'>
                <ModuleButton
                  title='Mitigation'
                  imageAlt='Navigate to Mitigation'
                  text='Discover how we help avoid and minimize impacts to aquatic resources.'
                  image={mitigationIcon}
                  onClick={() => doUpdateRelativeUrl('/home/mitigation')}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1'>
                <ModuleButton
                  title='Report Violations'
                  imageAlt='Open modal to report a potential violation'
                  text='Submit a report of unauthorized activity or permit non-compliance.'
                  image={violationIcon}
                  onClick={() => handleViolationComplaint()}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1'>
                <ModuleButton
                  title='RRS Support'
                  imageAlt='RRS Help Icon'
                  text='Get general system support, district contact information, submit feedback, and login.gov help.'
                  image={rrsIcon}
                  onClick={() => doUpdateRelativeUrl('/help')}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1'>
                <ModuleButton
                  title='Public Notices'
                  imageAlt='Public Notices Sign'
                  text='Visit the HQ Regulatory website to find your district and get local public notices.'
                  isDisabled
                  popupText='UNDER CONSTRUCTION'
                  image={publicNoticesIcon}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1'>
                <ModuleButton
                  title='Apply for a Permit'
                  imageAlt='Start Apply Begin Permit Image'
                  text="If you're ready to apply for a permit, click here to login or start a new request"
                  isDisabled={userProfileData?.role === ProfileRoles.USACERegulator || userProfileData?.role === ProfileRoles.ExternalAgency}
                  image={applyPermitIcon}
                  onClick={() => handlePermits()}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  });

export default Resources;
