import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import { useFormContext } from 'react-hook-form';
import Icon from '@components/icon/Icon';
import { mdiMagnify, mdiClose } from '@mdi/js';
import classNames from 'classnames';
import Tooltip from '@components/tooltip/tooltip';
import { debounce, formatCoordFlt, formatCoordStr, mapCountryAlpha3toAlpha2 } from '@src/utils/helpers';
import { isValidASCII, sanitizeASCII } from '@src/utils/helpers';
import genericSecondaryModal from '@forms/components/modals/genericSecondaryModal';
import { ErrorMessages } from '@src/utils/enums';
import { TextInput, Label } from '@trussworks/react-uswds';
import './AddressSuggestInput.scss';

const AddressSuggestInput = connect(
  'doGetAddressSuggestions',
  'doResetAddressSuggestionResults',
  'doFindAddressCandidates',
  'doSecondaryModalOpen',
  'doResetAddressCandidatesResults',
  'doUpdateRequestLocation',
  'selectAddressSuggestionResults',
  ({
    className = 'width-full',
    doGetAddressSuggestions,
    doResetAddressSuggestionResults,
    doFindAddressCandidates,
    doSecondaryModalOpen,
    doResetAddressCandidatesResults,
    doUpdateRequestLocation,
    addressSuggestionResults,
    isProjectAddress = false,
    name,
    label,
    onChange = () => { },
    onBlur = () => { },
    required,
    tooltip,
    tooltipClickable,
    readOnly,
    usOnly = true,
    uppercase,
    customProps,
  }) => {
    const classes = classNames(className, 'address-search-input', { 'text-uppercase': uppercase });
    const resultsRef = useRef();
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [showAddressSuggest, setShowAddressSuggest] = useState(false);
    //convert to local storage
    const [userLocation, setUserLocation] = useState();
    const { register, watch, setValue, getValues, formState: { errors } } = useFormContext();

    const inputError = errors[name];
    const input = watch(name);

    useEffect(() => {
      if (input && typeof input === 'string') {
        if (isValidASCII(input)) {
          setValue(name, input);
        } else {
          // Optionally handle invalid input here, such as warning the user
          const msg = ErrorMessages.InvalidCharacters;
          doSecondaryModalOpen(genericSecondaryModal, { title: 'Invalid Characters', msg: msg });
          setValue(name, sanitizeASCII(input));
        }
      };
    }, [input, name, setValue, doSecondaryModalOpen]);

    const debouncedGetAddressSuggestions = useMemo(() => debounce((searchText, userLocation) => {
      doGetAddressSuggestions(searchText, userLocation, usOnly);
    }, 300), [doGetAddressSuggestions, usOnly]);

    const getAddressSuggestion = (e) => {
      if (navigator?.geolocation && !userLocation) {
        navigator.geolocation.getCurrentPosition((location) => setUserLocation(`${location?.coords?.longitude},${location?.coords?.latitude}`));
      }

      const searchText = e?.target?.value;

      if (!searchText) {
        doResetAddressSuggestionResults();
        setShowAddressSuggest(false);
      }
      else if (searchText?.length > 1) {
        debouncedGetAddressSuggestions(searchText, userLocation ?? '-113,44');
        !showAddressSuggest && addressSuggestionResults && setShowAddressSuggest(true);
      }
      else if (searchText?.length < 1) {
        setShowAddressSuggest(false);
      }
    };

    const populateAddressFields = (address, isProjectAddress) => {
      const fields = address?.[0]?.candidates?.[0]?.attributes;
      const addressField = document.getElementById('address');
      const addressFieldFocused = document.activeElement === addressField;

      if (fields) {

        if (addressFieldFocused) {
          addressField.blur();
        }

        setValue('address', fields.StAddr, { shouldValidate: true });
        setValue('addressTwo', fields.SubAddr, { shouldValidate: true });
        setValue('city', fields.City, { shouldValidate: true });
        setValue('state', fields.RegionAbbr, { shouldValidate: true });
        setValue('zipcode', fields.Postal, { shouldValidate: true });
        setValue('county', fields.Subregion, { shouldValidate: true });
        setValue('country', mapCountryAlpha3toAlpha2(fields.Country), { shouldValidate: true });
        isProjectAddress && setValue('latitude', formatCoordStr(fields.Y), { shouldValidate: true });
        isProjectAddress && setValue('longitude', formatCoordStr(fields.X), { shouldValidate: true });
        isProjectAddress && doUpdateRequestLocation({ address: fields?.StAddr, addressTwo: fields?.SubAddr, city: fields?.City, state: fields?.RegionAbbr, zipcode: fields?.Postal, county: fields?.Subregion, country: fields?.Country !== 'USA' ? fields?.Country : 'US', latitude: formatCoordFlt(fields.Y), longitude: formatCoordFlt(fields.X) });
        !isProjectAddress && doResetAddressCandidatesResults();

        if (addressFieldFocused) {
          addressField.focus();
        }
      }
    };

    const selectAddress = (e) => {
      if (typeof (e) == 'undefined') {
        doResetAddressCandidatesResults();
        doResetAddressSuggestionResults();
        setShowAddressSuggest(false);
      }
      else {
        const address = e?.[1];
        const magicKey = e?.[0];
        address && Promise.all([doFindAddressCandidates(address, magicKey)]).then(data => populateAddressFields(data, isProjectAddress));
        doResetAddressSuggestionResults();
        setShowAddressSuggest(false);
      }
    };

    const handleOutsideClick = useCallback((e) => {
      if (resultsRef && resultsRef.current && !resultsRef.current.contains(e.target)) {
        setShowAddressSuggest(false);
      }
    }, []);

    const handleKeyPress = useCallback((e) => {
      if (!showAddressSuggest) return;

      const items = resultsRef?.current?.querySelectorAll('.dropdown-item');
      switch (e?.key) {
        case 'ArrowDown':
          e?.preventDefault();
          setCurrentIndex((prevIndex) => (prevIndex + 1) % items?.length);
          break;
        case 'ArrowUp':
          e?.preventDefault();
          setCurrentIndex((prevIndex) => (prevIndex - 1 + items?.length) % items?.length);
          break;
        case 'Enter':
          e?.preventDefault();
          if (currentIndex >= 0 && currentIndex < items?.length) {
            items?.[currentIndex]?.click();
            setCurrentIndex(-1);
          }
          break;
        case 'Escape':
          e?.preventDefault();
          setCurrentIndex(-1);
          break;
        default:
          break;
      }
    }, [currentIndex, showAddressSuggest]);

    useEffect(() => {

      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keydown', handleKeyPress);

      return () => { document.removeEventListener('click', handleOutsideClick); document.removeEventListener('keydown', handleKeyPress); };


    }, [handleKeyPress, handleOutsideClick]);

    useEffect(() => {
      if (currentIndex !== -1 && showAddressSuggest) {
        const items = resultsRef.current.querySelectorAll('.dropdown-item');
        if (items[currentIndex]) {
          items[currentIndex].focus();
        }
      }
    }, [currentIndex, showAddressSuggest]);


    const handleCloseSuggestions = () => {
      doResetAddressSuggestionResults();
      setShowAddressSuggest(false);
    };

    const handleBlur = (e) => {
      onBlur(e);
    };

    const handleChange = (e) => {
      getAddressSuggestion(e);
      onChange(e);
      setValue(e?.target?.name, e?.target?.value);
    };

    const {ref: addressSuggestionRef , ...rest } = register(name, { onBlur:handleBlur, onChange:handleChange });

    return (
      <>
        <div className='width-full'>
          <Label
            htmlFor={name}>
            {label}
            {required ? <span className='asterisk-color'>*</span> : <span className='text-italic'> (optional)</span>}
            {tooltip &&
              <Tooltip
                clickable={tooltipClickable}
                content={tooltip}
                header={label}
                iconStyle={{ marginLeft: '5px' }}
                name={name}
              />
            }
          </Label>
          <TextInput
            className={classes}
            type='text'
            defaultValue={getValues(name)}
            readOnly={readOnly}
            id={name}
            inputRef={addressSuggestionRef}
            name={name}
            required={required}
            validationStatus={inputError && 'error'}
            aria-label='Search Icon with result cancel'
            aria-required={required ? 'true' : 'false'}
            aria-invalid={inputError ? 'true' : 'false'}
            aria-describedby='inputGroup-sizing-sm'
            {...rest}
            {...customProps}
          />
          <span className={`icon-search ${inputError ? 'is-invalid' : ''}`}><Icon focusable={false} path={mdiMagnify} size={1} /></span>
        </div>
        {showAddressSuggest && (
          <div className='address-suggestions'>
            <div className='dropdown-content' ref={resultsRef}>
              <div className='suggestion-title'>
                Suggestions
                <Icon
                  path={mdiClose}
                  className='close-btn'
                  onClick={handleCloseSuggestions}
                  size={'16px'}
                />
              </div>
              {addressSuggestionResults?.suggestions?.map(res =>
                <div className='dropdown-item' key={res.magicKey} onClick={() => selectAddress([res.magicKey, res.text])} tabIndex={0}>{res.text}</div>)}
            </div>
          </div>)}
      </>
    );
  });

export default AddressSuggestInput;
