import React, { useEffect, useState, useCallback, useMemo } from 'react';
import useErrorFocus from '@src/customHooks/useErrorFocus';
import { connect } from 'redux-bundler-react';
import { AgGridReact } from 'ag-grid-react';

import FieldHeader from '@forms/components/Form/FieldHeader';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';
import Card from '@components/card/card';
import { Button } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import AddOtherPersonsModal from '@forms/components/modals/AddOtherPersonsModal';
import AddAllegedViolatorModal from '@forms/components/modals/AddAllegedViolatorModal';
import SelectInput from '@components/select/Select';
import EditCellRenderer from '@forms/components/gridCellRenderers/editCellRender';
import { isObjectNotDirty, formatUSPhoneNumber } from '@src/utils/helpers';
import { ContactTypes } from '@src/utils/enums';
import { addressRegex, addressTwoRegex, emailRegex, phoneRegex, countryCodeRegex, usPhoneRegex, phoneExtRegex, cityRegex, zipCodeRegex } from '@src/utils/regex';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import ErrorSummary from '@components/error-summary/ErrorSummary';

export const ViolationsFormContactsMetadata = {
  sectionName: 'Contact Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const ViolationsFormContacts = connect(
  'doModalOpen',
  'doUpdateSectionValidity',
  'doUpdateViolationComplaintRequestContacts',
  'doUpdateIsAllegedViolatorKnown',
  'selectUserProfileData',
  'selectIsAnonymous',
  'selectRequestAPIData',
  'selectIsNewRequest',
  'selectSteps',
  'selectActiveStep',
  ({
    doModalOpen,
    doUpdateSectionValidity,
    doUpdateViolationComplaintRequestContacts,
    doUpdateIsAllegedViolatorKnown,
    userProfileData,
    isAnonymous,
    requestAPIData,
    steps,
    activeStep,
    stepNo,
    isReadOnly,
  }) => {
    const [otherPersonsRowData, setOtherPersonsRowData] = useState([]);
    const [allegedViolatorRowData, setAllegeViolatorRowData] = useState([]);
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    const allegedViolatorTableLength = allegedViolatorRowData?.length;

    const schema = (allegedViolatorTableLength) =>
      yup.object().shape(!isAnonymous ? {
        firstName: yup.string().required('Field is required'),
        lastName: yup.string().required('Field is required'),
        address: yup.string().required('Field is required').matches(addressRegex, 'Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed'),
        addressTwo: yup.string().nullable().matches(addressTwoRegex, 'Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed'),
        city: yup.string().required('Field is required').matches(cityRegex, 'Field is invalid'),
        state: yup.string().when('country', { is: 'US', then: () => yup.string().required('Please select an option'), otherwise: () => yup.string().required('Please select an option') }),
        zipcode: yup.string().when('country', { is: 'US', then: () => yup.string().required('Field is required').matches(zipCodeRegex, 'Field is invalid'), otherwise: () => yup.string().nullable() }),
        country: yup.string().required('Please select an option'),
        phoneOneType: yup.string().required('Please select an option'),
        phoneOneCountryCode: yup.string().required('Field is required').matches(countryCodeRegex, 'Field is invalid'),
        phoneOne: yup.string().when('phoneOneCountryCode', {
          is: '1', then: () => yup.string().required('Field is required').matches(usPhoneRegex, 'Field is invalid'),
          otherwise: () => yup.string().when('phoneOneCountryCode', { is: val => val !== '1', then: () => yup.string().required('Field is required').matches(phoneRegex, 'Field is invalid') })
        }).required('Field is required'),
        phoneOneExtension: yup.string().nullable().when('phoneOneType', { is: 'Business', then: () => yup.string().matches(phoneExtRegex, 'Field is invalid') }),
        showPhoneTwo: yup.boolean().nullable(),
        phoneTwoType: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Please select an option') }),
        phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Field is required').matches(countryCodeRegex, 'Field is invalid') }),
        phoneTwo: yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
          is: (countryCode, showPhoneTwo) => countryCode === '1' && showPhoneTwo === true, then: () => yup.string().required('Field is required').matches(usPhoneRegex, 'Field is invalid'),
          otherwise: () => yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], { is: (countryCode, showPhoneTwo) => countryCode !== '1' && showPhoneTwo === true, then: () => yup.string().required('Field is required').matches(phoneRegex, 'Field is invalid') })
        }),
        phoneTwoExtension: yup.string().nullable().when(['showPhoneTwo', 'phoneTwoType'], { is: (show, type) => show && type === 'Business', then: () => yup.string().nullable().matches(phoneExtRegex, { message: 'Field is invalid', excludeEmptyString: true }) }),
        faxPhone: yup.string().matches(phoneRegex, { message: 'Field is invalid', excludeEmptyString: true }).nullable(),
        emailAddress: yup.string().required('Field is required').matches(emailRegex, 'Field is invalid'),
        isAllegedViolatorKnown: yup.string().required('Please select an option'),
        allegedViolatorTable: yup.boolean().when('isAllegedViolatorKnown', { is: (val) => val === 'Yes' && allegedViolatorTableLength < 1, then: () => yup.boolean().required('Alleged Violator: At least one alleged violator is required') }),
      } : {
        isAllegedViolatorKnown: yup.string().required('Is Alleged Violator Known?'),
        allegedViolatorTable: yup.boolean().when('isAllegedViolatorKnown', { is: (val) => val === 'Yes' && allegedViolatorTableLength < 1, then: () => yup.boolean().required('Alleged Violator: At least one alleged violator is required') }),
      });

    const reporterContact = requestAPIData?.request?.contacts?.length > 0 && requestAPIData?.request?.contacts?.find((contact) => contact.contactType === ContactTypes.Reporter);
    const violationComplaint = requestAPIData?.request?.violationComplaints?.length > 0 && requestAPIData?.request?.violationComplaints?.[0];

    const defaultValues = {
      salutation: reporterContact?.salutation ?? null,
      firstName: reporterContact?.firstName ?? null,
      middleName: reporterContact?.middleName ?? null,
      lastName: reporterContact?.lastName ?? null,
      address: reporterContact?.address ?? null,
      addressTwo: reporterContact?.addressTwo ?? null,
      city: reporterContact?.city ?? null,
      state: reporterContact?.state ?? null,
      zipcode: reporterContact?.zipcode ?? null,
      country: reporterContact?.country ?? 'US',
      phoneOneType: reporterContact?.phoneOneType ?? null,
      phoneOneCountryCode: reporterContact?.phoneOneCountryCode ?? '1',
      phoneOne: reporterContact?.phoneOne ? (reporterContact?.phoneOneCountryCode === '1' ? formatUSPhoneNumber(reporterContact.phoneOne) : reporterContact?.phoneOne) : '',
      phoneOneExtension: reporterContact?.phoneOneExtension ?? null,
      phoneTwoType: reporterContact?.phoneTwoType ?? null,
      phoneTwoCountryCode: reporterContact?.phoneTwoCountryCode ?? null,
      phoneTwo: reporterContact?.phoneTwo ? (reporterContact?.phoneTwoCountryCode === '1' ? formatUSPhoneNumber(reporterContact.phoneTwo) : reporterContact?.phoneTwo) : '',
      phoneTwoExtension: reporterContact?.phoneTwoExtension ?? null,
      faxPhone: reporterContact?.faxPhone ?? null,
      emailAddress: reporterContact?.emailAddress ?? null,
      company: reporterContact?.company ?? null,
      isAllegedViolatorKnown: violationComplaint?.isAllegedViolatorKnown === true ? 'Yes' : violationComplaint?.isAllegedViolatorKnown === false ? 'No' : null,
    };

    const methods = useForm({ resolver: yupResolver(schema(allegedViolatorTableLength)), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { isValid, errors }, watch, setError, clearErrors, trigger, getValues, reset } = methods;
    const isAllegedViolatorKnown = watch('isAllegedViolatorKnown');

    const formatContactType = (type) => {
      const baseType = type.slice(10);
      return baseType === 'PropertyOwner' ? 'Property Owner' : baseType;
    };

    const OtherPersonsColumnDefs = [
      { headerName: 'Actions', cellRenderer: 'editRowRenderer', cellRendererParams: { doModalOpen: doModalOpen, modalComponent: AddOtherPersonsModal, setRowData: setOtherPersonsRowData, userProfileKey: { firstName: userProfileData.firstName, lastName: userProfileData.lastName, email: userProfileData.emailAddress }, rowData: otherPersonsRowData, isReadOnly: isReadOnly }, width: 65 },
      { field: 'firstName', headerName: 'First Name', flex: 1, resizable: true },
      { field: 'middleName', headerName: 'Middle Name', flex: 1, resizable: true },
      { field: 'lastName', headerName: 'Last Name', flex: 1, resizable: true },
      { field: 'phoneOneType', headerName: 'Phone One Type', flex: 1, resizable: true },
      { field: 'phoneOne', headerName: 'Phone Number One', flex: 1, resizable: true },
      { field: 'state', flex: 1, maxWidth: 70 },
      { field: 'country', flex: 1, maxWidth: 70 },
    ];

    const AllegedViolatorColumnDefs = [
      { headerName: 'Actions', cellRenderer: 'editRowRenderer', cellRendererParams: { doModalOpen: doModalOpen, modalComponent: AddAllegedViolatorModal, setRowData: setAllegeViolatorRowData, userProfileKey: { firstName: userProfileData.firstName, lastName: userProfileData.lastName, email: userProfileData.emailAddress }, rowData: allegedViolatorRowData, isReadOnly: isReadOnly }, width: 65 },
      { field: 'contactType', headerName: 'Contact Type', flex: 1, maxWidth: 150, valueFormatter: params => formatContactType(params.value), resizable: true },
      { field: 'firstName', headerName: 'First Name', flex: 1, resizable: true },
      { field: 'middleName', headerName: 'Middle Name', flex: 1, resizable: true },
      { field: 'lastName', headerName: 'Last Name', flex: 1, resizable: true },
      { field: 'phoneOneType', headerName: 'Phone One Type', flex: 1, resizable: true },
      { field: 'phoneOne', headerName: 'Phone Number One', flex: 1, resizable: true },
      { field: 'state', flex: 1, maxWidth: 70 },
      { field: 'country', flex: 1, maxWidth: 70 },
    ];

    const addRowData = (data, fnc) => fnc((rowData) => [...rowData, data]);

    const clearRowData = (fnc) => fnc([]);

    useEffect(() => {
      if (isAllegedViolatorKnown === 'Yes' && allegedViolatorRowData?.length < 1) {
        setError('allegedViolatorTable', { type: 'custom', message: 'Alleged Violator: At least one alleged violator is required' });
      }
      else {
        clearErrors('allegedViolatorTable');
      }

    }, [isAllegedViolatorKnown, allegedViolatorRowData?.length, setError, clearErrors]);

    useEffect(() => {
      doUpdateSectionValidity(ViolationsFormContactsMetadata.sectionName, isValid, stepNo, isReadOnly);
    }, [isValid, isAllegedViolatorKnown, allegedViolatorRowData, doUpdateSectionValidity, stepNo, isReadOnly]);

    //Load tables from database
    useEffect(() => {
      const otherContacts = requestAPIData?.request?.violationComplaints?.[0]?.contacts?.filter((contact) => contact.contactType === ContactTypes.Witness);
      const suspects = requestAPIData?.request?.violationComplaints?.[0]?.contacts?.filter((contact) => contact.contactType !== ContactTypes.Witness && contact.contactType !== ContactTypes.Reporter);

      otherContacts && setOtherPersonsRowData(otherContacts);
      suspects && setAllegeViolatorRowData(suspects);
    }, [requestAPIData]);


    useEffect(() => {
      //copies of original requestAPIData creation
      const reporterContact = requestAPIData?.request?.contacts?.length > 0 && requestAPIData?.request?.contacts?.find((contact) => contact.contactType === ContactTypes.Reporter);
      const violationComplaint = requestAPIData?.request?.violationComplaints?.length > 0 && requestAPIData?.request?.violationComplaints?.[0];


      // remap of default values
      const defaultValues = {
        salutation: reporterContact?.salutation ?? null,
        firstName: reporterContact?.firstName ?? null,
        middleName: reporterContact?.middleName ?? null,
        lastName: reporterContact?.lastName ?? null,
        address: reporterContact?.address ?? null,
        addressTwo: reporterContact?.addressTwo ?? null,
        city: reporterContact?.city ?? null,
        state: reporterContact?.state ?? null,
        zipcode: reporterContact?.zipcode ?? null,
        country: reporterContact?.country ?? null,
        phoneOneType: reporterContact?.phoneOneType ?? null,
        phoneOneCountryCode: reporterContact?.phoneOneCountryCode ?? '1',
        phoneOne: reporterContact?.phoneOne ?
          (reporterContact.phoneOneCountryCode === '1' ? formatUSPhoneNumber(reporterContact?.phoneOne) : reporterContact?.phoneOne)
          : null,
        phoneOneExtension: reporterContact?.phoneOneExtension ?? null,
        phoneTwoType: reporterContact?.phoneTwoType ?? null,
        phoneTwoCountryCode: reporterContact?.phoneTwoCountryCode ?? null,
        phoneTwo: reporterContact?.phoneTwo ?
          (reporterContact.phoneTwoCountryCode === '1' ? formatUSPhoneNumber(reporterContact?.phoneTwo) : reporterContact?.phoneTwo)
          : null,
        phoneTwoExtension: reporterContact?.phoneTwoExtension ?? null,
        faxPhone: reporterContact?.faxPhone ?? null,
        emailAddress: reporterContact?.emailAddress ?? null,
        company: reporterContact?.company ?? null,
        isAllegedViolatorKnown: violationComplaint?.isAllegedViolatorKnown === true ? 'Yes' : violationComplaint?.isAllegedViolatorKnown === false ? 'No' : null,
      };
      reset(defaultValues);
      //

    }, [requestAPIData, reset]);


    useEffect(() => {
      // Format contacts array
      const latestvalues = getValues();
      const { isAllegedViolatorKnown, ...reporterFields } = latestvalues;
      const reporterFieldsWithIDs = {
        ...reporterFields,
        contactID: reporterContact?.contactID ?? undefined,
        requestID: reporterContact?.requestID ?? undefined,
        version: reporterContact?.version ?? undefined,
        contactType: ContactTypes.Reporter,
        createdBy: reporterContact?.createdBy ?? undefined,
        phoneOne: reporterFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
        phoneTwo: reporterFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
      };
      const reporterObj = isAnonymous ? [] : [reporterFieldsWithIDs];
      const contactArr = [...reporterObj, ...otherPersonsRowData, ...allegedViolatorRowData];
      // Update Contacts array state value
      isObjectNotDirty(reporterFields) && doUpdateViolationComplaintRequestContacts(contactArr);
    }, [otherPersonsRowData, allegedViolatorRowData, getValues, doUpdateViolationComplaintRequestContacts, isAnonymous, reporterContact]);

    const handleFieldChange = useCallback((e) => {
      // form field on change handler
      const latestvalues = getValues();
      const { isAllegedViolatorKnown, ...reporterFields } = latestvalues;
      const reporterFieldsWithIDs = {
        ...reporterFields,
        contactID: reporterContact?.contactID ?? undefined,
        requestID: reporterContact?.requestID ?? undefined,
        version: reporterContact?.version ?? undefined,
        contactType: ContactTypes.Reporter,
        createdBy: reporterContact?.createdBy ?? undefined,
        phoneOne: reporterFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
        phoneTwo: reporterFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
      };
      const reporterObj = isAnonymous ? [] : [reporterFieldsWithIDs];
      const contactArr = [...reporterObj, ...otherPersonsRowData, ...allegedViolatorRowData];
      doUpdateViolationComplaintRequestContacts(contactArr);
    }, [allegedViolatorRowData, doUpdateViolationComplaintRequestContacts, getValues, isAnonymous, otherPersonsRowData, reporterContact.contactID, reporterContact.createdBy, reporterContact.requestID, reporterContact.version]);

    useEffect(() => {
      // Update IsAllegedViolatorKnown state value
      doUpdateIsAllegedViolatorKnown(Boolean(isAllegedViolatorKnown === 'Yes'));
    }, [isAllegedViolatorKnown, doUpdateIsAllegedViolatorKnown]);

    useEffect(() => {
      if (allegedViolatorRowData?.length > 0) {
        (isAllegedViolatorKnown === 'No') && clearRowData(setAllegeViolatorRowData);
      }
    }, [allegedViolatorRowData, isAllegedViolatorKnown]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        {<FieldHeader text='Contact Information' />}
        <section>
          {!isAnonymous && (<>
            <ContactFields label='Reporter' showButton isReadOnly={isReadOnly} handleFieldChange={handleFieldChange} /></>)}
          <FieldHeader text='Alleged Violator' />
          <div className='row mt-3 mb-3' id='allegedViolatorTable'>
            <div className='col-sm'>
              <SelectInput name='isAllegedViolatorKnown' label='Is Alleged Violator known?' required readOnly={isReadOnly}>
                <option key='2' value='Yes'>Yes</option>
                <option key='3' value='No'>No</option>
              </SelectInput>

            </div>
          </div>
          {isAllegedViolatorKnown === 'Yes' && (<>

            <div className='row mt-3 mb-3'>
              <div className='col-sm'>
                <Card>
                  <div className='row d-flex w-100 mt-3 pb-3 justify-content-center'>
                    <div className='d-flex justify-content-center col-4'>
                      <Button
                        className='add-contact-button'
                        title='Add a Contact'
                        size='small'
                        onClick={() => doModalOpen(AddAllegedViolatorModal, { addRowData: addRowData, setRowData: setAllegeViolatorRowData, isReadOnly: isReadOnly })}
                        disabled={isReadOnly}
                      >
                        <Icon focusable={false} className='mr-1' path={mdiAccountPlus} size={'16px'} />
                        Add a Contact
                      </Button>
                    </div>
                    <div className='d-flex justify-content-center col-4'>
                      <Button
                        className='clear-table-button'
                        title='Clear Contacts'
                        size='small'
                        onClick={() => clearRowData(setAllegeViolatorRowData)}
                        disabled={isReadOnly || allegedViolatorRowData?.length === 0}
                      >
                        <Icon focusable={false} path={mdiCloseOctagon} size={'16px'} />
                        Clear Contacts
                      </Button>
                    </div>
                  </div>
                  <div className='ag-theme-balham' style={{ height: 400 }}>
                    <AgGridReact
                      rowData={allegedViolatorRowData}
                      columnDefs={AllegedViolatorColumnDefs}
                      rowHeight={35}
                      suppressClickEdit
                      components={{
                        'editRowRenderer': EditCellRenderer,
                      }}
                    />
                  </div>
                </Card>
              </div>
            </div></>)}
          <FieldHeader text='Additional Contacts or Witnesses' />
          <div className='row'>
            <div className='col-sm'>
              <p className='mt-3'>Other persons that may have information regarding the alleged violation (provide name and contact information if available):</p>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-sm'>
              <Card id='otherPersonsTable'>
                <div className='row d-flex w-100 mt-3 pb-3 justify-content-center'>
                  <div className='d-flex justify-content-center col-4'>
                    <Button
                      className='add-contact-button'
                      title='Add a Contact'
                      size='small'
                      onClick={() => doModalOpen(AddOtherPersonsModal, { addRowData: addRowData, setRowData: setOtherPersonsRowData, isReadOnly: isReadOnly })}
                      disabled={isReadOnly}
                    >
                      <Icon focusable={false} className='mr-1' path={mdiAccountPlus} size={'16px'} />
                        Add a Contact
                    </Button>
                  </div>
                  <div className='d-flex justify-content-center col-4'>
                    <Button
                      className='clear-table-button'
                      title='Clear Contacts'
                      size='small'
                      onClick={() => clearRowData(setOtherPersonsRowData)}
                      disabled={isReadOnly || otherPersonsRowData?.length === 0}
                    >
                      <Icon focusable={false} path={mdiCloseOctagon} size={'16px'} />
                        Clear Contacts
                    </Button>
                  </div>
                </div>
                <div className='ag-theme-balham' style={{ height: 400 }}>
                  <AgGridReact
                    rowData={otherPersonsRowData}
                    columnDefs={OtherPersonsColumnDefs}
                    pagination={true}
                    paginationAutoPageSize={true}
                    rowHeight={35}
                    suppressClickEdit
                    components={{
                      'editRowRenderer': EditCellRenderer,
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
        </section>

        <section>

        </section>
      </FormProvider>
    );
  }
);
ViolationsFormContacts.metadata = ViolationsFormContactsMetadata;

export default ViolationsFormContacts;
