import ExternalLink from '@components/external-link/ExternalLink';
import InternalLink from '@components/internal-link/InternalLink';
import { ResourceFiles } from '@src/utils/enums';

import '@styles/_accordion.scss';

export const examplePlansFileList = [
  { label: 'Sample Drawing Bank Stabilization - Bioengineered [PDF, 2 pages]', filename: ResourceFiles.SampleDrawingBankStabilizationBioEng },
  { label: 'Sample Drawing Bank Stabilization – Riprap [PDF, 2 pages]', filename: ResourceFiles.SampleDrawingBankStabilizationRipRap },
  { label: 'Sample Drawing Culvert [PDF, 3 pages]', filename: ResourceFiles.SampleDrawingCulvert },
  { label: 'Sample Drawing Dock [PDF, 2 pages]', filename: ResourceFiles.SampleDrawingDock },
  { label: 'Sample Drawing Stream Crossing [PDF, 2 pages]', filename: ResourceFiles.SampleDrawingStream },
  { label: 'Sample Drawing Wetland Fill for House [PDF, 1 page]', filename: ResourceFiles.SampleDrawingWetlandHouse },
  { label: 'Sample Drawing Wetland Fill for Road [PDF, 2 pages]', filename: ResourceFiles.SampleDrawingWetlandRoad },
];

export const permitAccordionList = [
  {
    title: <>Expand to Learn More About <span className='text-bold'>Section 10 of the Rivers and Harbors Act</span></>,
    content: <p>Under Section 10 of the Rivers and Harbors Act, a USACE permit is required for <span className='text-bold'>work or structures in, over, or under</span> navigable waters of the United States.  Navigable waters of the United States typically include larger rivers, larger freshwater lakes, tidal waters, and the ocean.</p>,
    id: '1',
    headingLevel: 'h3'
  },{
    title: <>Expand to Learn More About <span className='text-bold'>Section 404 of the Clean Water Act</span></>,
    content: <p>Under Section 404 of the Clean Water Act, a USACE permit is required for the <span className='text-bold'>discharge of dredged or fill material</span> into waters of the United States. Waters of the United States including the larger navigable waters of the United States and also include smaller streams and adjacent wetlands.</p>,
    id: '2',
    headingLevel: 'h3'
  },{
    title: <>Expand to Learn More About <span className='text-bold'>Section 103 of the Marine Protection, Research, and Sanctuaries Act</span></>,
    content: <p>Under Section 103 of the Marine Protection, Research, and Sanctuaries Act, USACE regulates the <span className='text-bold'>transport and disposal of dredged material</span> at designated ocean sites.</p>,
    id: '3',
    headingLevel: 'h3'
  },
];

export const permitsAvailableAccordionList = [
  {
    title: <>Expand to Learn More About <span className='text-bold'>General Permits</span></>,
    content:  <>
      <p>There are three types of general permits: Nationwide Permits (NWPs), Regional General Permits (RGPs), and Programmatic General Permits (PGPs). General permits are typically not tailored for individual applicants/projects but instead authorize activities that the USACE has determined to be substantially similar in nature (such as utility lines, residential developments, bank stabilization, etc.) and which cause
    minimal individual and cumulative environmental impacts. General permits can authorize activities within a limited geographic area (such as a county or state), a specific region (such as a group of contiguous states), or the entire nation. In some cases, activities that qualify for general permit authorization can proceed without written confirmation from the USACE, if they adhere to the terms and conditions
    outlined in the general permit. However, for many general permits, the USACE must review the proposed work and provide written verification of compliance with the general permit before construction on the project can commence. <span className='text-bold'>Written notification to the USACE is almost always required when the project has the potential to impact federally listed threatened or endangered species or properties that are included in,
      or eligible for inclusion in, the National Register of Historic Places.</span></p>
      <p className='text-bold'>Nationwide Permits</p>
      <p>Nationwide permits are general permits issued nationwide to authorize categories of similar activities with minor impacts. Regional conditions may be added by individual Districts to provide additional protection for aquatic environments. Nationwide permits authorize specific activities within <InternalLink href='/home/jurisdiction' title='waters of the United States' content='waters of the United States' />. These activities are normally minor
                in scope and result in no more than minimal adverse impacts, both individually and cumulatively to waters of the United States. Individuals seeking to undertake work under a nationwide permit must ensure that their project adheres to all relevant terms and conditions. Typically, the processing time for nationwide permits ranges from 30 to 45 days.</p>
      <p><span className='text-underline'>Links:</span></p>
      <ul>
        <li><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Nationwide-Permits/' title='2021 Nationwide Permits Information' content='2021 Nationwide Permits Information' /></li>
        <li><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Nationwide-Permits/#twentyseventeenOriginal' title='Expired Nationwide Permits' content='Expired Nationwide Permits' /></li>
      </ul>
      <p className='text-bold'>Regional General Permits</p>
      <p>A regional general permit is issued by the USACE District that has regulatory jurisdiction over the geographic area in which the general permit will be used. Regional general permits authorize specific activities within <InternalLink href='/home/jurisdiction' title='waters of the United States' content='waters of the United States' /> that are typically common in that geographic area. These activities are normally minor in scope and result in no
                more than minimal adverse impacts, both individually and cumulatively to waters of the United States.  The processing times for regional general permit are dependent on the terms and conditions of the permit.</p>
      <p><span className='text-underline'>Links:</span></p>
      <ul><li><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Obtain-a-Permit/#RegProgPermits' title='Current Regional General Permits' content='Current Regional General Permits' /></li></ul>
      <p className='text-bold'>Programmatic General Permits</p>
      <p>Programmatic general permits are developed using established state, local, or federal programs to prevent redundant processes. State Programmatic General Permits are permits issued by the USACE to minimize duplication between USACE districts and state regulatory programs that offer comparable protection for aquatic resources. In certain states, the SPGP replaces some or all of the USACE nationwide permits, aiming to enhance
                overall efficiency in the permitting process.</p>
    </>,
    id: '4',
    headingLevel: 'h3'
  },{
    title: <>Expand to Learn More About <span className='text-bold'>Individual Permits</span></>,
    content: <>
      <p>Individual permits (IPs) are authorized for individual applicants/projects and typically authorize activities that may cause more than minimal individual and cumulative environmental impacts. Activities that require an individual permit authorization cannot proceed without written authorization from the USACE. There are two types of individual permits: Standard Permits (SPs) and Letters of Permissions (LOPs).</p>
      <p className='text-bold'>Standard Permits</p>
      <p>A Standard Permit is an authorization granted by the Department of the Army following a comprehensive review of the public interest. This process involves a public notice period of 15 to 30 days, during which the permit drawings and detailed project description are provided to interested parties, neighboring property owners, and State and Federal agencies for review and feedback. The typical processing time for such permits
                ranges from 60 to 120 days after receiving a complete application for non-controversial projects. However, controversial or complex projects, including those requiring a public hearing or an Environmental Impact Statement (EIS), may take longer to process.</p>
      <p><span className='text-underline'>Links:</span></p>
      <ul>
        <li><ExternalLink href='https://permits.ops.usace.army.mil/orm-public#' title='View the complete list of Final Individual Permits' content='View the complete list of Final Individual Permits' /></li>
        <span className='text-italic'>Please select district from "Filter By District" option to narrow the results.</span>
      </ul>

      <p className='text-bold'>Letters of Permission</p>
      <p>The USACE District has the option to issue Letters of Permission (LOP), as specified in 33 CFR Part 325, particularly 33 CFR 325.2(e)(1). This expedited process for individual permits typically results in a decision within 60 days. An LOP can be utilized when the USACE District determines that the proposed work is minor, would not have significant individual or cumulative impacts on the environment, and is unlikely to face
                substantial opposition.</p>
      <p>All applications qualifying for LOP are categorically excluded under the USACE implementing regulations for the National Environmental Policy Act, outlined in 33 CFR Part 325 Appendix B. Therefore, letters of permission do not require an environmental assessment or environmental impact statement as a legal requirement. However, it's important to note that the USACE must still comply with other laws when issuing an LOP. In these cases, the proposal is coordinated with Federal and state fish and wildlife agencies, as required by the Fish and Wildlife Coordination Act, and includes a public interest evaluation, but without the publishing of an individual public notice.</p>
      <p>Letters of permission are issued in the form of a letter, providing details such as the permittee's information, project location, authorized work, time limits, and any specific limitations or conditions applicable to the project. Additionally, relevant general conditions are attached to and incorporated into the letter of permission.</p>
    </>,
    id: '5',
    headingLevel: 'h3'
  },
];