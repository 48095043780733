import { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { ToastContainer } from 'react-toastify';
import { GovBanner } from '@trussworks/react-uswds';

import Modal from '@components/modal';
import SecondaryModal from '@components/secondary-modal';
import NavBar from '@components/navigation/navBar';
import PageContent from '@components/page-content/pageContent';
import LandingModal from '@pages/common/modals/Landing';
import LoadingModal from '@pages/common/modals/Loading';
import RRSFooter from '@components/footer/Footer';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/css/bootstrap/css/bootstrap.water.min.css';
import './styles/bootstrap_overrides.css';
import './styles/index.scss';

export default connect(
  'doModalOpen',
  'doFetchBannerInfo',
  'doFetchUserProfile',
  'doSetLoadingState',
  'doSetLoadingMessage',
  'selectRoute',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  'selectLoadingState',
  'selectLoadingMessage',
  'selectBannerData',
  ({
    doModalOpen,
    doFetchBannerInfo,
    doFetchUserProfile,
    doSetLoadingState,
    doSetLoadingMessage,
    route: Route,
    authIsLoggedIn,
    userProfileData,
    loadingState,
    loadingMessage,
    bannerData,
  }) => {
    useEffect(() => {
      const fetchBanner = async () => await doFetchBannerInfo();

      fetchBanner();

      const intervalId = setInterval(() => {
        fetchBanner();
      }, 600000); // 10 minutes

      return () => clearInterval(intervalId);
    }, [doFetchBannerInfo]);

    useEffect(() => {
      if (!authIsLoggedIn) {
        const landingModalSeen = sessionStorage.getItem('landingModalSeen');
        if (!landingModalSeen || landingModalSeen === 'false') {
          doModalOpen(LandingModal);
        }
      } else {
        if (userProfileData.length === 0) {
          doFetchUserProfile();
        }
      }
    }, [authIsLoggedIn, userProfileData, doModalOpen, doFetchUserProfile]);

    useEffect(() => {
      window.addEventListener('pageshow', (event) => {
        // Check if page was restored from the bfcache
        if (event.persisted) {
          // Clear loading overlay
          doSetLoadingState(false);
          doSetLoadingMessage('Loading...');
        }
      });
    }, [doSetLoadingMessage, doSetLoadingState]);

    return (
      <>
        {loadingState && <LoadingModal text={loadingMessage} />}
        <ToastContainer autoClose={3500} hideProgressBar={false} />
        <div className={'gov-banner-container'}>
          <GovBanner language='english' tld='.mil' aria-label='Official government website' />
        </div>
        <NavBar />
        <PageContent>
          <Route />
        </PageContent>
        <Modal closeWithEscape />
        <SecondaryModal closeWithEscape />
        <RRSFooter version={bannerData?.version} />
      </>
    );
  }
);
