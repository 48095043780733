import { ModalContent, ModalFooter } from '@components/modal';

import { connect } from 'redux-bundler-react';
import { RequestTypeStrings } from '@src/utils/enums';
import { Alert } from '@trussworks/react-uswds';

const submissionModal = connect('selectSelectedRequest', 'selectRequestFormData', 'selectDistrictResults', ({
  selectedRequest,
  requestFormData,
  districtResults,
  title = 'Request Submitted',
  msg,
  status,
  onSave,
  saveText = 'OK',
  cancelText
}) => {
  const todayPlusTen = new Date();
  todayPlusTen.setDate(todayPlusTen.getDate() + 10);

  const district = requestFormData?.location?.district ?? '';

  //@TODO Query for district info

  return (
    <ModalContent title={title}>
      <section className='modal-body'>
        <div className='container-fluid'>
          {status === 'Success With Warning' && msg && (
            <Alert slim type='warning'>{msg}</Alert>
          )}
          <span>
            Your request for a <span className='text-bold'>{RequestTypeStrings[selectedRequest]}</span> has been submitted to the <span className='text-bold'>{district}</span>.
          </span>
          <span style={{ display: 'block', marginTop: '8px' }}>
            Please contact your local Regulatory office at <span className='text-bold'>{districtResults?.district_properties?.DISTRICT_N}</span> with any questions/concerns.
          </span>
        </div>
      </section>
      <ModalFooter
        saveText={saveText}
        showCancelButton={cancelText}
        onSave={onSave}
        cancelText={cancelText}
      />
    </ModalContent>);
});

export default submissionModal;
