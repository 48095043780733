import Card from '@components/card';
import Icon from '@components/icon/Icon';
import classnames from 'classnames';

import '../../resources.scss';

const ModuleButton = ({
  hideDetails,
  icon,
  image,
  imageAlt = '',
  isDisabled,
  isError,
  isModalButton,
  onClick = () => {},
  popupText,
  selected = false,
  subtext,
  text,
  title,
}) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  const contentCss = classnames({
    'd-none': hideDetails,
  });

  return (
    <Card
      className={`spacer w-100 ${isDisabled && 'homepage-disabled'} ${isModalButton && subtext ? 'modal-card-container' : 'card-container'} ${selected && 'module-selected'} ${hideDetails && 'module-icon-only'} ${isError && 'modal-card-invalid'}`}
      onClick={onClick}
      onKeyUp={handleKeyPress}
      tabIndex={0}
    >
      <Card.Body>
        <div className='center'>
          <div>
            {image && <img src={image} alt={imageAlt} className='img-icon' />}
            {icon && <Icon focusable={false} title={text} className='img-icon' path={icon} size={3} />}
            <div className={contentCss}>
              {title && <div className='button-header'>{title}</div>}
              {popupText && <div className='button-text popup'>{popupText}</div>}
              <div>
                {text && <div className='button-text'>{text}</div>}
                {subtext && <div className='button-subtext'>{subtext}</div>}
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ModuleButton;
