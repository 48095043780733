const districtAPIBundle = {
  name: 'districtapi',

  getReducer: () => {
    const initialData = {
      districts: null,
    };

    return (state = initialData, { type, payload }) => {

      switch (type) {
        case 'CALCULATING_DISTRICT': {
          return {
            ...state,
            districts: payload?.length > 0
              ? payload.map(district => `${district} District`)
              : null
          };
        }
        case 'RESET_DISTRICT_API_RESULTS': {
          return initialData;
        }
        default:
          return state;
      };

    };
  },
  doGetDistrictUsingBackupEndpoint:
    (geometry) =>
      ({ dispatch }) => {

        let formattedGeometry;
        let geometryType;
        switch (geometry?.type) {
          case 'Point': {
            geometryType = 'esriGeometryPoint';
            formattedGeometry = geometry?.coordinates;
            break;
          }
          case 'LineString': {
            geometryType = 'esriGeometryPolyline';
            formattedGeometry = JSON.stringify({ 'paths': [geometry?.coordinates], 'spatialReference': { 'wkid': 4326 } });
            break;
          }
          case 'MultiLineString': {
            geometryType = 'esriGeometryPolyline';
            formattedGeometry = JSON.stringify({ 'paths': geometry?.coordinates, 'spatialReference': { 'wkid': 4326 } });
            break;
          }
          case 'Polygon': {
            geometryType = 'esriGeometryPolygon';
            formattedGeometry = JSON.stringify({ 'rings': geometry?.coordinates, 'spatialReference': { 'wkid': 4326 } });
            break;
          }
          default: {
            geometryType = 'esriGeometryPoint';
            formattedGeometry = geometry?.coordinates;
          }
        }

        const URL = 'https://services7.arcgis.com/n1YM8pTrFmm7L4hs/ArcGIS/rest/services/usace_regulatory_boundary/FeatureServer/0/query';
        const params = new URLSearchParams({
          where: '1=1',
          f: 'json',
          geometry: formattedGeometry,
          geometryType: geometryType,
          spatialRel: 'esriSpatialRelIntersects',
          outFields: 'DISTRICTNAME',
          returnGeometry: false,
          returnDistinctValues: true,
          inSR: 4326,
        });

        fetch(URL,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: params
          }
        ).then(data => data.json()).then((json) => {
          const districts = json?.features?.map(feature => feature.attributes.DISTRICTNAME) ?? [];
          if (districts.length > 0) {
            dispatch({
              type: 'CALCULATING_DISTRICT',
              payload: districts,
            });
          }
          else {
            alert('A USACE regulatory district could not be determined for this location. Please select a new location and try again.');
            dispatch({
              type: 'CALCULATING_DISTRICT',
              payload: [],
            });
          }

        })
          .catch((err) => {
            console.error(err); alert('A USACE regulatory district could not be determined for this location. Please select a new location and try again.');
          });
      },
  doGetDistrictFromGeometry:
    (geometry) =>
      ({ dispatch, store }) => {
        let formattedGeometry;
        let geometryType;
        switch (geometry?.type) {
          case 'Point': {
            geometryType = 'esriGeometryPoint';
            formattedGeometry = geometry?.coordinates;
            break;
          }
          case 'LineString': {
            geometryType = 'esriGeometryPolyline';
            formattedGeometry = JSON.stringify({ 'paths': [geometry?.coordinates], 'spatialReference': { 'wkid': 4326 } });
            break;
          }
          case 'MultiLineString': {
            geometryType = 'esriGeometryPolyline';
            formattedGeometry = JSON.stringify({ 'paths': geometry?.coordinates, 'spatialReference': { 'wkid': 4326 } });
            break;
          }
          case 'Polygon': {
            geometryType = 'esriGeometryPolygon';
            formattedGeometry = JSON.stringify({ 'rings': geometry?.coordinates, 'spatialReference': { 'wkid': 4326 } });
            break;
          }
          case 'MultiPolygon': {
            geometryType = 'esriGeometryPolygon';
            const rings = geometry?.coordinates?.flat(1);
            formattedGeometry = JSON.stringify({ 'rings': rings, 'spatialReference': { 'wkid': 4326 } });
            break;
          }
          default: {
            geometryType = 'esriGeometryPoint';
            formattedGeometry = geometry?.coordinates;
          }
        }

        const URL = 'https://services9.arcgis.com/TwG74uxbzhyKmMpS/ArcGIS/rest/services/USACE_Regulatory_Areas_of_Responsibility_Admin/FeatureServer/1/query';
        const params = new URLSearchParams({
          where: '1=1',
          f: 'json',
          geometry: formattedGeometry,
          geometryType: geometryType,
          spatialRel: 'esriSpatialRelIntersects',
          outFields: 'DISTRICTNAME',
          returnGeometry: false,
          returnDistinctValues: true,
          inSR: 4326,
        });

        fetch(URL,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: params,
          }
        ).then(data => data.json()).then((json) => {
          const districts = json?.features?.map(feature => feature.attributes.DISTRICTNAME) ?? [];
          if (districts.length < 1) {
            store.doGetDistrictUsingBackupEndpoint(geometry);
          }
          else {
            dispatch({
              type: 'CALCULATING_DISTRICT',
              payload: districts,
            });
          }
        })
          .catch((err) => console.error(err));
      },
  doResetDistrictAPIResults: () =>
    ({ dispatch }) => {
      dispatch({
        type: 'RESET_DISTRICT_API_RESULTS'
      });
    },
  selectDistrictResults: (state) => state.districtapi.districts,
};

export default districtAPIBundle;
