import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'redux-bundler-react';

import AddressValidator from '../AddressValidator.jsx';
import TextInput from '@components/text-input/TextInput';
import SelectInput from '@components/select/Select';
import CreatableSelectInput from '@components/new-inputs/creatableSelectInput.jsx';
import { Button, Grid } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import { mdiAccount } from '@mdi/js';

import { useFormContext } from 'react-hook-form';
import { salutations, states, countries } from '@forms/input-forms/_helper.jsx';
import { formatUSPhoneNumber } from '@src/utils/helpers.jsx';
import PhoneInput from '@components/phone-input/PhoneInput';
import countryRegionData from 'country-region-data/dist/data-umd';

import './ContactFields.scss';
import AddressSuggestInput from '@components/address-suggestion/AddressSuggestInput.jsx';
import FieldHeader from '../FieldHeader.jsx';
import { ContactTypes } from '@src/utils/enums.ts';

const ContactFields = connect(
  'selectUserProfileData',
  ({
    userProfileData,
    type, // lowerCamelCase format
    label,
    requiredFields,
    handleFieldChange,
    isReadOnly,
    showButton,
    showContactInfoSection = true,
    showSalutation = true,
    requireEmail = true,
  }) => {
    const { watch, setValue, register } = useFormContext();
    const [showPhoneTwo, setShowPhoneTwo] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);

    register('showPhoneTwo');

    const phoneOneType = watch('phoneOneType');
    const phoneTwoType = watch('phoneTwoType');
    const phoneTwo = watch('phoneTwo');
    const address1 = watch('address');
    const address2 = watch('addressTwo');
    const city = watch('city');
    const state = watch('state');
    const zipcode = watch('zipcode');
    const country = watch('country');

    useEffect(() => {
      if ((phoneTwoType || phoneTwo) && !showPhoneTwo) {
        setShowPhoneTwo(() => true);
        setValue('showPhoneTwo', true);
      }
    }, [phoneTwoType, phoneTwo, showPhoneTwo, setValue]);


    const togglePhone = () => {
      if (!isReadOnly) {
        if (showPhoneTwo) {
          setShowPhoneTwo(() => false);
          setValue('showPhoneTwo', false);
          setValue('phoneTwo', '', { shouldValidate: true });
          setValue('phoneTwoCountryCode', '', { shouldValidate: true });
          setValue('phoneTwoType', '', { shouldValidate: true });
          setValue('phoneTwoExtension', '', { shouldValidate: true });
        } else {
          setShowPhoneTwo(() => true);
          setValue('showPhoneTwo', true);
          if (!requiredFields?.includes('Phone Number Two'))
            setValue('phoneTwoCountryCode', '');
          else
            setValue('phoneTwoCountryCode', '1');
        }
      }
    };

    const populateFromProfile = (e) => {
      e.preventDefault();
      setValue('firstName', userProfileData?.firstName ??  null, { shouldValidate: true });
      setValue('middleName', userProfileData?.middleName ??  null, { shouldValidate: true });
      setValue('lastName', userProfileData?.lastName ??  null, { shouldValidate: true });
      setValue('address', userProfileData?.address ??  null, { shouldValidate: true });
      setValue('addressTwo', userProfileData?.addressTwo ??  null, { shouldValidate: true });
      setValue('city', userProfileData?.city ??  null, { shouldValidate: true });
      setValue('country', userProfileData?.country ??  null, { shouldValidate: true });
      setValue('state', userProfileData?.state ??  null, { shouldValidate: true });
      setValue('zipcode', userProfileData?.zipcode ??  null, { shouldValidate: true });
      setValue('company', userProfileData?.companyName ??  null, { shouldValidate: true });
      setValue('phoneOneType', 'Primary', { shouldValidate: true });
      setValue('phoneOneCountryCode', userProfileData?.phoneCountryCode ??  null, { shouldValidate: true });
      setValue('phoneOne', userProfileData?.phone ? (userProfileData?.phoneCountryCode === '1' ? formatUSPhoneNumber(userProfileData?.phone) : userProfileData?.phone) : null, { shouldValidate: true });
      setValue('salutation', userProfileData?.salutation ??  null, { shouldValidate: true });
      setValue('emailAddress', userProfileData?.emailAddress ??  null, { shouldValidate: true });
      handleFieldChange && handleFieldChange();
    };

    useEffect(() => {
      if (((country === null || country === '') && !requiredFields)
        || ((country === null || country === '') && requiredFields?.includes('Country'))) {
        setValue('country', 'US');
      }
    }, [setValue, requiredFields, country]);

    const addressValues = useMemo(() => ({
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zipcode: zipcode,
      country: country,
    }), [address1, address2, city, state, zipcode, country]);

    const handleBlur = (e) => {
      setValue(e?.target?.name, e?.target?.value?.trim());
    };

    const regionHelper = useCallback((country) => {
      const selectedCountryData = countryRegionData?.find(regionCountry => regionCountry.countryShortCode === country);

      let regions = [];
      if (selectedCountryData) {
        regions = selectedCountryData.regions.map(region => ({
          value: region.shortCode ?? region.name, // Some countries do not have provided shortcodes
          text: region.name
        }));
      }

      regions.push({ value: 'Other', text: 'Other' });
      regions.push({ value: 'None', text: 'None' });

      return regions;
    }, []);

    const handleCountryChange = useCallback((selectedCountry) => {
      const regions = regionHelper(selectedCountry);
      setStateOptions(regions);

      if (selectedCountry !== country) {
        setValue('city', '',{shouldValidate: true});
        setValue('state', '',{shouldValidate: true});
        setValue('zipcode', '', {shouldValidate: true});
      }
    }, [country, regionHelper, setStateOptions, setValue]);

    useEffect(() => {
      const regions = regionHelper(country);
      setStateOptions(regions);
    }, [country, regionHelper, setStateOptions]);

    useEffect(() => {
      const matchingOption = stateOptions?.find(option => option?.value === state);
      if (matchingOption) {
        setValue('state', matchingOption?.value, { shouldValidate: true });
      }
    }, [stateOptions, setValue, state]);

    return (
      <div className='container-fluid'>
        {type === ContactTypes.PropertyOwner && (
          <>
            <h6 className='border-bottom width-full padding-bottom-1 margin-top-4'>{label} Parcel Number(s)</h6>
            <Grid row gap='md'>
              <Grid col={8} tablet={{col: 8}}>
                <CreatableSelectInput name={'parcels'} label='Parcel Number(s)' placeholder='Enter any applicable parcel numbers' multi required={requiredFields ? requiredFields.includes('Parcel Number(s)') : false} disabled={isReadOnly} onChange={handleFieldChange} />
              </Grid>
            </Grid>
          </>)}
        <FieldHeader text={label} />
        <h6 className='border-bottom width-full padding-bottom-1 margin-top-4'>{label} Name</h6>
        <Grid row gap='md'>
          <Grid tablet={{col: 'auto'}}>
            <div align='left'>
              {showButton && 
                <Button className='use-profile-button' onClick={(e) => populateFromProfile(e)} tabIndex={0} disabled={isReadOnly}> <Icon focusable={false} title='Use Profile Data' path={mdiAccount} size={0.85} />USE PROFILE DATA </Button>}
            </div>
            {showSalutation === true && <SelectInput name='salutation' label='Salutation' readOnly={isReadOnly} onChange={handleFieldChange}>
              {salutations.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
            </SelectInput>}
          </Grid>
        </Grid>
        <Grid row gap='md'>
          <Grid tablet={{col: 4}}>
            <TextInput label='First Name' name='firstName' required={requiredFields ? requiredFields.includes('First Name') : true} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </Grid>
          <Grid tablet={{col: 4}}>
            <TextInput label='Middle Name' name='middleName' readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </Grid>
          <Grid tablet={{col: 4}}>
            <TextInput label='Last Name' name='lastName' required={requiredFields ? requiredFields.includes('Last Name') : true} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </Grid>
        </Grid>

        <h6 className='border-bottom width-full padding-bottom-1 margin-top-4'>{label} Address</h6>
        <Grid row gap='md'>
          <Grid tablet={{col: 8}}>
            <AddressSuggestInput id='address' name='address' label='Address' required={requiredFields ? requiredFields.includes('Address One') : true} usOnly={false} onBlur={handleBlur} onChange={handleFieldChange} readOnly={isReadOnly} />
          </Grid>
        </Grid>
        <Grid row gap='md'>
          <Grid tablet={{col: 8}}>
            <TextInput label='Address Line 2' name='addressTwo' readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </Grid>
        </Grid>
        <Grid row gap='md'>
          <Grid tablet={{col: 'auto'}}>
            <SelectInput name='country' label='Country' required={requiredFields ? requiredFields.includes('Country') : true} readOnly={isReadOnly} onChange={(e) => handleCountryChange(e.target.value)}>
              {countries?.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
            </SelectInput>
          </Grid>
        </Grid>
        <Grid row gap='md'>
          <Grid tablet={{col: 4}}>
            <TextInput label='City' name='city' required={requiredFields ? requiredFields.includes('City') : true} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </Grid>
          {country === 'US' ? (
            <Grid tablet={{col: 4}}>
              <SelectInput name='state' label='State' required={requiredFields ? requiredFields.includes('State') : true} readOnly={isReadOnly} onChange={handleFieldChange}>
                {states?.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
              </SelectInput>
            </Grid>
          ) : (
            <Grid tablet={{col: 4}}>
              <SelectInput name='state' label='Region' required={requiredFields ? requiredFields.includes('State') : true} readOnly={isReadOnly}>
                {stateOptions?.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
              </SelectInput>
            </Grid>
          )}
          <Grid tablet={{col: 4}}>
            <TextInput type='text' label={country === 'US' ? 'Zip Code' : 'Postal code'} required={country === 'US' ? (requiredFields ? requiredFields.includes('Zip Code') : true) : false} name='zipcode' maxLength={country === 'US' ? 5 : 10} readOnly={isReadOnly} onChange={handleFieldChange} />
          </Grid>
        </Grid>
        <Grid row className='margin-top-2' gap='md'>
          <Grid tablet={{col:12}}>
            <AddressValidator source='form' isDisabled={isReadOnly} address={addressValues} />
          </Grid>
        </Grid>

        {showContactInfoSection && (
          <>
            <h6 className='border-bottom width-full padding-bottom-1 margin-top-4'>{label} Contact Information</h6>
            <Grid row gap='md'>
              <Grid tablet={{col: phoneOneType === 'Business' ? 8 : 7}} >
                <PhoneInput phoneName='phoneOne' prefixName='phoneOneCountryCode' phoneTypeName='phoneOneType' extensionName='phoneOneExtension' label='Phone Number' required={requiredFields ? requiredFields.includes('Phone Number One') : true} readOnly={isReadOnly} onChange={handleFieldChange} onBlur={handleBlur} />
              </Grid>
              <Grid tablet={{col: 'auto'}}>
                {!showPhoneTwo && <div className='phone-button-margin'> <Button className='button-small' disabled={isReadOnly} size='small' title='Add Phone Number' id='phone-toggle-1' name='phoneToggleButton1' onClick={togglePhone} onKeyUp={(e) => { e.key === 'Enter' && togglePhone(); }}>Add</Button></div>}
              </Grid>
            </Grid>
            {showPhoneTwo && (
              <Grid row gap='md' >
                <Grid tablet={{col: phoneTwoType === 'Business' ? 8 : 7}}>
                  <PhoneInput phoneName='phoneTwo' prefixName='phoneTwoCountryCode' phoneTypeName='phoneTwoType' extensionName='phoneTwoExtension' label='Phone Number' required={requiredFields ? requiredFields.includes('Phone Number Two') : true} readOnly={isReadOnly} onChange={handleFieldChange} onBlur={handleBlur} />
                </Grid>
                <Grid tablet={{col: 'auto'}}>
                  <div className='phone-button-margin'>
                    <Button className='button-small' disabled={isReadOnly} title='Remove Phone Number' id='phone-toggle-2' name='phoneToggleButton2' onClick={togglePhone} secondary onKeyUp={(e) => { e.key === 'Enter' && togglePhone(); }} >Remove</Button>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid row gap='md'>
              <Grid col>
                <TextInput type='tel' name='faxPhone' label='Fax Number' maxLength={10} readOnly={isReadOnly} onChange={handleFieldChange} />
              </Grid>
            </Grid>
            <Grid row gap='md'>
              <Grid col>
                <TextInput name='emailAddress' label='Email Address' required={requireEmail && (requiredFields ? requiredFields.includes('Email Address') : true)} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
              </Grid>
            </Grid>
            <Grid row gap='md'>
              <Grid col>
                <TextInput name='company' label='Company' readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
              </Grid>
            </Grid>
          </>)}
      </div >
    );
  }
);

export default ContactFields;
