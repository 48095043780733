import { connect } from 'redux-bundler-react';
import { TableWrapper } from './FormAndAppendices';
import LinkButton from '@components/link/linkButton';
import { FileTypes, ResourceFiles } from '@src/utils/enums';

const FormsAndAppendicesDelineationRequest = connect(
  'doDownloadFile',
  ({ doDownloadFile }) => (
    <>
      <p>
        This path is used by property owners or their agents to request that USACE conduct a Jurisdictional
        Determination to determine if aquatic resources are or may be subject to jurisdiction under Section 404 of the
        Clean Water Act or Section 10 of the Rivers and Harbors Act.{' '}
        <span className='text-bold'>
          Note – if a delineation report is also being provided, refer to the Delineation Report and Aquatic Resource
          (AR) Inventory section below.
        </span>
      </p>
      <TableWrapper>
        <tr>
          <td>
            <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6247, FileTypes.Resource)}>
              ENG 6247 Request for a Jurisdictional Determination [PDF, 2 pages]
            </LinkButton>
          </td>
          <td>Provides information entered throughout the request screens. Generates the JD request form.</td>
        </tr>
        <tr>
          <td>
            <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6295, FileTypes.Resource)}>
              ENG 6295 Agent Authorization Form [PDF, 1 page]
            </LinkButton>
          </td>
          <td>
            This form indicates that the property owner has given permission to an agent/consultant to act on his or her
            behalf in all matters relating to obtaining a jurisdictional determination (JD).{' '}
            <span className='text-bold'>
              This form is not generated but needs to be uploaded to the RRS by the requestor.
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6294, FileTypes.Resource)}>
              ENG 6294 Right of Entry Form [PDF, 1 page]
            </LinkButton>
          </td>
          <td>
            This form provides permission to the USACE Project Manager to enter the property for which the JD is being
            requested.{' '}
            <span className='text-bold'>
              This form is not generated but needs to be uploaded to the RRS by the requestor.
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixH, FileTypes.Resource)}>
              Supporting information [PDF, 1 page]
            </LinkButton>
          </td>
          <td>
            Information that is uploaded by the requestor to support and/or clarify the request that is being made. This
            information can include, but is not limited to, photos, maps, drawings, other authorizations, mitigation
            plans, etc.
          </td>
        </tr>
      </TableWrapper>
    </>
  )
);

export default FormsAndAppendicesDelineationRequest;
