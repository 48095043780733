import { toast } from 'react-toastify';
import { tSuccess, tError } from '../utils/toast-helpers';
import { createSelector } from 'redux-bundler';
import { ContactsFormNames } from '@src/utils/enums';
import { filterNullEmptyObjects } from '../utils/helpers.jsx';

const processResponse = (response, type) => (
  new Promise((resolve, reject) => {
    const func = response.status < 400 ? resolve : reject;
    // @TODO: handle different response types
    type === 'json' ?
      response.json()
        .then(json => func({
          'status': response.status,
          'json': json,
        }))
        .catch(e => console.error(e)) :
      response.blob()
        .then(blob => func({
          'status': response.status,
          'blob': blob,
        }))
        .catch(e => console.error(e));
  })
);

const fileStorageBundle = {
  name: 'fileStorage',

  getReducer: () => {
    const initialData = {
      fileUploaded: [],
      requestUploadedFiles: [],
      selectedFiles: [],
      componentFiles: [],
      // Size/count config
      totalFileSize: 0, // 0B
      maxTotalFileSize: 525000000, // 500MB + buffer
      maxIndFileSize: 106000000, // 100MB + buffer
      maxFileCount: 50
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'UPDATE_UPLOAD_FILE':
          return {
            ...state,
            fileUploaded: payload,
          };
        case 'UPDATE_REQUEST_UPLOADED_FILES':
          return {
            ...state,
            requestUploadedFiles: payload,
          };
        case 'UPDATE_SELECTED_FILES':
          return {
            ...state,
            selectedFiles: [...state.selectedFiles, ...payload],
          };
        case 'UPDATE_COMPONENT_FILES':
          return {
            ...state,
            componentFiles: payload,
          };
        case 'UPDATE_TOTAL_FILE_SIZE':
          return {
            ...state,
            totalFileSize: payload,
          };
        case 'RESET_FILE_SECTION':
          return {
            ...state,
            selectedFiles: payload,
          };
        case 'RESET_FILE_DATA':
          return initialData;
        default:
          return state;
      }
    };
  },

  selectFileStorage: state => state.fileStorage,
  selectRequestUploadedFiles: state => state.fileStorage.requestUploadedFiles,
  selectSelectedFiles: state => state.fileStorage.selectedFiles,
  selectComponentFiles: state => state.fileStorage.componentFiles,
  selectTotalFileSize: state => state.fileStorage.totalFileSize,
  selectMaxTotalFileSize: state => state.fileStorage.maxTotalFileSize,
  selectMaxIndFileSize: state => state.fileStorage.maxIndFileSize,
  selectMaxFileCount: state => state.fileStorage.maxFileCount,
  selectCombinedComponentFiles: createSelector('selectComponentFiles', 'selectSelectedFiles', (componentFiles, selectedFiles) => [...componentFiles, ...selectedFiles]),
  selectCombinedRequestFiles: createSelector('selectRequestUploadedFiles', 'selectSelectedFiles', (requestUploadedFiles, selectedFiles) => [...requestUploadedFiles, ...selectedFiles]),
  selectSupportingDocFiles: createSelector('selectCombinedComponentFiles', (files) => files.filter(file => file.section === 'Supporting Documents')),
  selectAgentAuthorizationFile: createSelector('selectCombinedComponentFiles', (files) => files.filter(file => file.section === 'Agent Authorization')),
  selectRightOfEntryFile: createSelector('selectCombinedComponentFiles', (files) => files.filter(file => file.section === 'Right of Entry')),
  selectGeneratedFiles: createSelector('selectRequestUploadedFiles', (files) => {
    const generatedFilesArr = files.filter(file => file.DocumentType === 'Generated');
    var sortedArr = [];
    var existingArr = [];
    // Separate files that do/do not have the SortOrder S3 Tag
    generatedFilesArr.forEach(item => item?.SortOrder ? sortedArr.push(item) : existingArr.push(item));
    // Sorted files then old files
    return  [...sortedArr.sort((a, b) => Number(a.SortOrder) - Number(b.SortOrder)), ...existingArr];
  }),

  doFetchUserFilesDetails: (componentID, getAllVersions = false, paramsID) => ({ dispatch, apiFetch, store }) => {
    const projectID = paramsID ? paramsID.projectID : store.selectProjectID();
    const requestID = paramsID ? paramsID.requestID : store.selectRequestID();
    const version = paramsID ? paramsID.version : store.selectVersion();

    const uri = '/api/FileStorage/getUserFilesDetails?' + new URLSearchParams({
      projectID,
      requestID,
      version,
      getAllVersions
    });

    return apiFetch(uri, { method: 'GET' })
      .then(response => processResponse(response, 'json'))
      .then(response => {
        // Format file payload
        var formatPayload = [];
        response.json.data.map(file => {
          const fileObj = {
            fileName: file.fileName,
            key: file.key,
            fileSize: file.fileSize,
            lastModified: file.lastModified
          };
          var tagObj = {};
          file.tags.map(tag => {
            const formatTag = { [tag.key]: tag.value };
            tagObj = { ...tagObj, ...formatTag };
            return true;
          });
          formatPayload.push({ ...fileObj, ...tagObj });
          return true;
        });
        dispatch({ type: 'UPDATE_REQUEST_UPLOADED_FILES', payload: formatPayload });
        store.doUpdateComponentFiles(componentID);
        return Promise.resolve(formatPayload);
      })
      .catch(e => {
        dispatch({ type: 'FILES_FETCH_ERROR', payload: e });
        console.error(`Request returned: ${e}`);
        return Promise.resolve(false);
      });
  },
  doUploadFiles: (formData) => ({ dispatch, apiFetch }) => {
    const uri = '/api/FileStorage/uploadFiles';

    return apiFetch(uri, { method: 'POST', body: formData })
      .then(response => processResponse(response, 'json'))
      .then(response => {
        if (response?.json?.status === 'Success') {
          dispatch({ type: 'UPDATE_UPLOAD_FILES' });
        } else {
          dispatch({ type: 'UPLOAD_FILES_ERROR', payload: response?.json?.msg });
        }
        return Promise.resolve({
          status: response?.json?.status,
          msg: response?.json?.msg
        });
      })
      .catch(e => {
        dispatch({ type: 'UPLOAD_FILE_ERROR' });
        console.error(`Request returned: ${e}`);
      });
  },
  doUploadFilesAnonymous: (formData) => ({ dispatch, apiFetch }) => {
    const uri = '/api/FileStorage/uploadFilesAnonymous';
    const skipAuth = true;

    return apiFetch(uri, { method: 'POST', body: formData }, skipAuth)
      .then(response => processResponse(response, 'json'))
      .then(response => {
        if (response?.json?.status === 'Success') {
          dispatch({ type: 'UPDATE_UPLOAD_FILES' });
        } else {
          dispatch({ type: 'UPLOAD_FILES_ERROR', payload: response?.json?.msg });
        }
        return Promise.resolve({
          status: response?.json?.status,
          msg: response?.json?.msg
        });
      })
      .catch(e => {
        dispatch({ type: 'UPLOAD_FILE_ERROR' });
        console.error(`Request returned: ${e}`);
      });
  },
  doUploadFilesFeedback: (formData) => ({ dispatch, apiFetch }) => {
    const uri = '/api/FileStorage/uploadAttachments';

    return apiFetch(uri, { method: 'POST', body: formData })
      .then(response => processResponse(response, 'json'))
      .then(response => {
        if (response?.json?.status === 'Success') {
          dispatch({ type: 'UPDATE_UPLOAD_FILES' });
        } else {
          dispatch({ type: 'UPLOAD_FILES_ERROR', payload: response?.json?.msg });
        }
        return Promise.resolve({
          status: response?.json?.status,
          msg: response?.json?.msg
        });
      })
      .catch(e => {
        dispatch({ type: 'UPLOAD_FILE_ERROR' });
        console.error(`Request returned: ${e}`);
      });
  },
  doDownloadFile: (fileName, fileType, paramsID) => ({ dispatch, apiFetch, store }) => {
    /** fileType: Template, Resource, or Request */
    const toastId = toast.loading('Preparing file for download...');
    const projectID = paramsID?.projectID ?? store.selectProjectID();
    const requestID = paramsID?.requestID ?? store.selectRequestID();
    const version = paramsID?.version ?? store.selectVersion();

    const skipAuth = (fileType === 'Resource' && store.selectAuthIsLoggedIn() === false) ? true : false;

    const uriRequest = '/api/FileStorage/downloadFile?' + new URLSearchParams({
      fileName,
      projectID,
      requestID,
      version,
    });

    const uriTemplates = '/api/FileStorage/downloadTemplate?' + new URLSearchParams({
      fileName
    });

    const uriResource = '/api/FileStorage/downloadResourceFile?' + new URLSearchParams({
      fileName
    });

    let uri;
    switch (fileType) {
      case 'Template':
        uri = uriTemplates;
        break;
      case 'Resource':
        uri = uriResource;
        break;
      case 'Request':
        uri = uriRequest;
        break;
      default:
        break;
    }

    apiFetch(uri, {}, skipAuth)
      .then(response => processResponse(response, 'blob'))
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
        tSuccess(toastId, 'File downloaded!');
      })
      .catch(e => {
        tError(toastId, 'Failed to download file. Please try again later.');
        dispatch({ type: 'DOWNLOADING_FILE_ERROR' });
        console.error(`Request returned a ${e.status}`);
      });
  },
  doDownloadPopulatedFile: (type, fileName) => ({ dispatch, apiPostPDF, store }) => {
    const toastId = toast.loading('Preparing file for download...');

    const uri = `/api/FileStorage/${type === ContactsFormNames.RightOfEntry ? 'generateRightOfEntry' : 'generateAgentAuth'}`;

    apiPostPDF(uri, store.selectRequestFormData(), (err, body) => {
      if (!err) {
        const url = window.URL.createObjectURL(new Blob([body], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
        tSuccess(toastId, 'File downloaded!');
      } else {
        tError(toastId, 'Failed to download file. Please try again later.');
        dispatch({ type: 'DOWNLOADING_FILE_ERROR' });
        console.error('Request returned a an error');
      }
    });
  },
  doDownloadZip: (fileNames, paramsID) => ({ dispatch, apiFetch, store }) => {
    const toastId = paramsID?.toastID ?? toast.loading('Preparing file for download...');
    const projectID = paramsID?.projectID ?? store.selectProjectID();
    const requestID = paramsID?.requestID ?? store.selectRequestID();
    const version = paramsID?.version ?? store.selectVersion();

    const uri = '/api/FileStorage/downloadZippedFiles';

    const data = {
      FileNames: fileNames, // array of strings
      ProjectID: projectID,
      RequestID: requestID,
      Version: version,
    };

    apiFetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).then(response => processResponse(response, 'blob'))
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${store.selectUserProfileParams()?.firstName}_${store.selectUserProfileParams()?.lastName}_Project${projectID}_Request${requestID}_Version0_${new Date().toISOString()}.zip`;
        link.click();
        window.URL.revokeObjectURL(url);
        tSuccess(toastId, 'File downloaded!');
      })
      .catch(e => {
        tError(toastId, 'Failed to download file. Please try again later.');
        dispatch({ type: 'DOWNLOADING_FILE_ERROR' });
        console.error(`Request returned a ${e.status}`);
      });
  },
  doDeleteFile: (fileName, componentID, showToast = true) => ({ dispatch, apiDelete, store }) => {
    const toastId = showToast === true ? toast.loading('Deleting file...') : null;
    const projectID = store.selectProjectID();
    const requestID = store.selectRequestID();
    const version = store.selectVersion();

    const uri = '/api/FileStorage/deleteDraftFile?' + new URLSearchParams({
      fileName,
      projectID,
      requestID,
      version,
    });

    apiDelete(uri, (err, body) => {
      if (!err && body.status === 'Success') {
        dispatch({ type: 'DELETE_FILE_SUCCESSFUL', payload: fileName });
        showToast === true && tSuccess(toastId, 'File successfully deleted!');
        store.doFetchUserFilesDetails(componentID);
      } else {
        showToast === true && tError(toastId, `File failed to delete! ${body.msg}`);
        dispatch({ type: 'DELETE_FILE_ERROR', payload: err });
      }
    });
  },
  doUpdateSelectedFiles: (data) => ({ dispatch, store }) => {
    const tagList = [];
    Object.keys(data).map(key => {
      if (key !== 'file' && key !== 'fileName') {
        tagList.push({ [key]: data[key] });
      }
      return true;
    });
    const fileData = {
      file: data?.file,
      tags: tagList,
    };
    dispatch({ type: 'UPDATE_SELECTED_FILES', payload: [{ ...fileData, ...data }] });
    dispatch({ type: 'UPDATE_TOTAL_FILE_SIZE', payload: Number(isNaN(store.selectTotalFileSize()) ? 0 : store.selectTotalFileSize()) + Number(fileData?.file?.size) });
  },
  doResetFileSection: (section, deleteUploaded = false) => ({ dispatch, store }) => {
    const filteredFiles = store.selectSelectedFiles().filter(el => el.section !== section);
    const selectedFileSizeArr = store.selectSelectedFiles().filter(el => el.section === section).map(item => item.file.size);
    const filteredUploadedFiles = store.selectRequestUploadedFiles().filter(file => file.section === section);
    const sum = selectedFileSizeArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    dispatch({ type: 'RESET_FILE_SECTION', payload: filteredFiles });
    dispatch({ type: 'UPDATE_TOTAL_FILE_SIZE', payload: Number(isNaN(store.selectTotalFileSize()) ? 0 : store.selectTotalFileSize()) - Number(sum) });
    // @TODO: Move bulk delete file logic to backend
    if (deleteUploaded) {
      filteredUploadedFiles?.length > 0 && filteredUploadedFiles.forEach(item => {
        store.doDeleteFile(item.fileName, item.componentID, false);
      });
    }
  },
  doResetUsersFileData: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_FILE_DATA' });
  },
  doUpdateComponentFiles: (componentID) => ({ dispatch, store }) => {
    const filteredArr = store.selectRequestUploadedFiles().filter(item => item.componentID === String(componentID));
    dispatch({ type: 'UPDATE_COMPONENT_FILES', payload: filteredArr });
  },
  doGenerateDraftDocuments: () => ({ dispatch, apiPostPDF, store }) => {
    const toastId = toast.loading('Downloading Draft Documents...');
    const filteredData = filterNullEmptyObjects(store.selectRequestFormData());

    const uri = '/api/FileStorage/generateDraftDocuments';

    apiPostPDF(uri, filteredData, (err, body) => {
      if (!err) {
        const url = window.URL.createObjectURL(new Blob([body], { type: 'application/zip' }));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${filteredData?.projectID ? filteredData.projectID + '_' : '0'}${filteredData?.requestID ? filteredData.requestID + '_' : '0'}${filteredData?.version ? filteredData.version : '0'}_Draft_Files`;
        link.click();
        window.URL.revokeObjectURL(url);
        tSuccess(toastId, 'File downloaded!');
      } else {
        tError(toastId, 'Failed to download file. Please try again later.');
        dispatch({ type: 'DOWNLOADING_FILE_ERROR' });
        console.error('Request returned a an error');
      }
    });
  },
};

export default fileStorageBundle;
