import { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import { ModalContent, ModalFooter } from '@components/modal';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';
import SelectInput from '@components/select/Select';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ContactTypes } from '@src/utils/enums';
import { emailRegex, phoneRegex, usPhoneRegex, phoneExtRegex, countryCodeRegex, cityRegex, zipCodeRegex } from '@src/utils/regex';
import { isNullUndefinedOrEmpty } from '@src/utils/helpers';
import ErrorSummary from '@components/error-summary/ErrorSummary';

const schema = yup.object().shape({
  contactType: yup.string().required('Please select an option'),
  parcels: yup.array().nullable(),
  firstName: yup.string().required('Field is required'),
  lastName: yup.string().required('Field is required'),
  city: yup.string().matches(cityRegex, { message: 'Field is invalid', excludeEmptyString: true }).nullable(),
  zipcode: yup.string().nullable().when('country', { is: 'US', then: () => yup.string().matches(zipCodeRegex, 'Field is invalid') }),
  country: yup.string().nullable(),
  phoneOneType: yup.string().nullable().test('phone-one-type-optional', 'Field is invalid', function (value) {
    const { phoneOne, phoneOneCountryCode } = this.parent;
    return isNullUndefinedOrEmpty(phoneOne) && isNullUndefinedOrEmpty(phoneOneCountryCode) && isNullUndefinedOrEmpty(value) ? true : !isNullUndefinedOrEmpty(value);
  }),
  phoneOneCountryCode: yup.string().nullable().test('phone-one-country-code-optional', 'Field is invalid', function (value) {
    const { phoneOne, phoneOneType } = this.parent;
    return isNullUndefinedOrEmpty(phoneOne) && isNullUndefinedOrEmpty(phoneOneType) && isNullUndefinedOrEmpty(value) ? true : countryCodeRegex.test(value);
  }),
  phoneOne: yup.string().nullable().test('phone-one-optional', 'Field is invalid', function (value) {
    const { phoneOneCountryCode, phoneOneType } = this.parent;
    if (isNullUndefinedOrEmpty(phoneOneCountryCode) && isNullUndefinedOrEmpty(phoneOneType) && isNullUndefinedOrEmpty(value))
      return true;
    else
      return phoneOneCountryCode === '1' ? usPhoneRegex.test(value) : phoneRegex.test(value);
  }),
  phoneOneExtension: yup.string().nullable().when('phoneOneType', { is: 'Business', then: () => yup.string().matches(phoneExtRegex, 'Field is invalid') }),
  showPhoneTwo: yup.boolean().nullable(),
  phoneTwoType: yup.string().nullable().when('showPhoneTwo', {
    is: true, then: () =>
      yup.string().test('phone-two-type-optional', 'Field is invalid', function (value) {
        const { phoneTwo, phoneTwoCountryCode } = this.parent;
        return isNullUndefinedOrEmpty(phoneTwo) && isNullUndefinedOrEmpty(phoneTwoCountryCode) && isNullUndefinedOrEmpty(value) ? true : !isNullUndefinedOrEmpty(value);
      })
  }),
  phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', {
    is: true, then: () =>
      yup.string().test('phone-two-country-code-optional', 'Field is invalid', function (value) {
        const { phoneTwo, phoneTwoType } = this.parent;
        return isNullUndefinedOrEmpty(phoneTwo) && isNullUndefinedOrEmpty(phoneTwoType) && isNullUndefinedOrEmpty(value) ? true : countryCodeRegex.test(value);
      })
  }),
  phoneTwo: yup.string().nullable().when('showPhoneTwo', {
    is: true, then: () =>
      yup.string().test('phone-two-optional', 'Field is invalid', function (value) {
        const { phoneTwoCountryCode, phoneTwoType } = this.parent;
        if (isNullUndefinedOrEmpty(phoneTwoCountryCode) && isNullUndefinedOrEmpty(phoneTwoType) && isNullUndefinedOrEmpty(value))
          return true;
        else
          return phoneTwoCountryCode === '1' ? usPhoneRegex.test(value) : phoneRegex.test(value);
      })
  }),
  phoneTwoExtension: yup.string().nullable().when(['showPhoneTwo', 'phoneTwoType'], { is: (show, type) => show && type === 'Business', then: () => yup.string().nullable().matches(phoneExtRegex, { message: 'Field is invalid', excludeEmptyString: true }) }),
  faxPhone: yup.string().matches(phoneRegex, { message: 'Field is invalid', excludeEmptyString: true }).nullable(),
  emailAddress: yup.string().nullable().when('emailAddress', { is: (value) => value?.length, then: () => yup.string().matches(emailRegex, 'Field is invalid') }),
}, [
  ['zipcode', 'zipcode'],
  ['emailAddress', 'emailAddress'],
]);

const requiredFields = [
  'First Name',
  'Last Name'
];

const AddAllegedViolatorModal = connect('doModalClose', ({ doModalClose, addRowData, edit, data, setRowData, rowData, id, isReadOnly }) => {
  const formatParcelNumbersOutput = data => data?.map(obj => ({ label: obj.parcelNumber, value: obj.parcelNumber }));

  const defaultValues = edit ? {
    contactType: data?.contactType ?? null,
    address: data?.address ?? null,
    addressTwo: data?.addressTwo ?? null,
    city: data?.city ?? null,
    company: data?.company ?? null,
    emailAddress: data?.emailAddress ?? null,
    faxPhone: data?.faxPhone ?? null,
    firstName: data?.firstName ?? null,
    lastName: data?.lastName ?? null,
    middleName: data?.middleName ?? null,
    phoneOne: data?.phoneOne ?? null,
    phoneOneType: data?.phoneOneType ?? null,
    phoneOneCountryCode: data?.phoneOneCountryCode ?? null,
    phoneOneExtension: data?.phoneOneExtension ?? null,
    phoneTwo: data?.phoneTwo ?? null,
    phoneTwoType: data?.phoneTwoType ?? null,
    phoneTwoCountryCode: data?.phoneTwoCountryCode ?? null,
    phoneTwoExtension: data?.phoneTwoExtension ?? null,
    salutation: data?.salutation ?? null,
    state: data?.state ?? null,
    zipcode: data?.zipcode ?? null,
    country: data?.country ?? null,
    parcels: formatParcelNumbersOutput(data.parcels) ? formatParcelNumbersOutput(data.parcels) : [],
  } : { country: null };

  const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues, });
  const { formState: { errors, isValid }, watch, setFocus, trigger, getValues } = methods;

  const watchContactType = watch('contactType');
  const watchParcels = watch('parcels');

  const formatContactType = (type) => type.slice(10);

  const onSave = () => {
    if (isValid) {
      const contactFields = getValues();
      const contactFieldsWithIDs = {
        ...contactFields,
        contactID: data?.contactID ?? undefined,
        requestID: data?.requestID ?? undefined,
        version: data?.version ?? undefined,
        contactType: watchContactType,
        createdBy: data?.createdBy ?? undefined,
        phoneOne: contactFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
        phoneTwo: contactFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
      };
      if (edit) {
        // if contact data obj already exists in database, identify contactID, otherwise use row index
        let dataArr = [...rowData];
        const index = data.contactID ? dataArr.findIndex(el => el.contactID === id) : id;
        contactFieldsWithIDs.parcels = formatParcelNumbers();
        dataArr[index] = contactFieldsWithIDs;
        setRowData(dataArr);
      } else {
        contactFieldsWithIDs.parcels = formatParcelNumbers();
        addRowData(contactFieldsWithIDs, setRowData);
      }
      doModalClose();
    }
    else {
      trigger();
      toast.error('Please fill out all required fields!');
    }
  };

  const formatParcelNumbers = () => (watchParcels ?? []).map(obj => ({ parcelNumber: obj.value }));

  useEffect(() => {
    if (errors?.[Object.keys(errors)[0]]?.['ref']?.focus) {
      errors?.[Object.keys(errors)[0]]?.['ref']?.focus();
    }

    setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
  }, [errors, setFocus]);

  return (
    <ModalContent hasCloseButton={isReadOnly} title='Add Contact - Alleged Violator'>
      <FormProvider {...methods}>
        {errors && <ErrorSummary errors={errors} modalID='addAllegedViolatorModal' type='modal' />}
        <section className='modal-body' id='addAllegedViolatorModal'>
          <div className='container-fluid'>
            <SelectInput name='contactType' label='Contact Type' readOnly={isReadOnly} required >
              <option key='2' value={ContactTypes.SuspectPropertyOwner}>Property Owner</option>
              <option key='3' value={ContactTypes.SuspectContractor}>Contractor</option>
              <option key='4' value={ContactTypes.SuspectOther}>Other</option>
            </SelectInput>
            {watchContactType && <ContactFields label={formatContactType(watchContactType)} type={formatContactType(watchContactType)} requiredFields={requiredFields} isReadOnly={isReadOnly} />}
          </div>
        </section>
      </FormProvider>
      <ModalFooter
        showCancelButton={!isReadOnly}
        showSaveButton={!isReadOnly}
        saveText={edit ? 'Apply Changes' : 'Add'}
        onSave={onSave}
        customClosingLogic
      />
    </ModalContent>
  );
}
);

export default AddAllegedViolatorModal;
