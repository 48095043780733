import { connect } from 'redux-bundler-react';
import { SideNav } from '@trussworks/react-uswds';
import InternalLink from '@components/internal-link/InternalLink';

import './SideNavigation.scss';

const SideNavigation = connect('selectRelativePathname', ({items, relativePathname}) => {

  const linkItems = items?.map(item => <InternalLink className={`sidenav_link ${relativePathname === item.href && 'usa-current'}`} key={item.content} title={item?.content} content={item?.content} href={item?.href} /> );

  return (
    <div className='sidebar'>
      <div className='margin-top-105'>
        <h6>Jump To</h6>
        <hr />
      </div>
      <SideNav items={linkItems} />
    </div>
  );

}
);

export default SideNavigation;
