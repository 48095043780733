import Tooltip from '@components/tooltip/tooltip';
import { useFormContext } from 'react-hook-form';
import { Label, Select } from '@trussworks/react-uswds';
import classnames from 'classnames';

import './select.scss';

const SelectInput = ({
  children,
  className = 'width-full',
  defaultOption = '--- Please select an option ---',
  group,
  label,
  name,
  onBlur,
  onChange,
  options = [],
  readOnly,
  required,
  tooltip,
  tooltipClickable,
  validations,
  ...customProps
}) => {
  const {
    register,
    formState: { errors },
    trigger,
  } = useFormContext();

  const inputError = errors[name];
  const renderedOptions = options.map((option, i) => (
    <option key={option.value} value={option.value ? option.value : option}>
      {option.label ? option.label : option}
    </option>
  ));

  const containerCss = classnames('select-container', className, {
    'is-invalid': inputError,
  });

  const { ref: selectRef, ...rest } = register(name, { onBlur,
    onChange: async (e) => {
      await trigger(name);
      onChange?.(e);
    },
    ...validations
  });

  return (
    <>
      <Label htmlFor={name}>
        {label}
        {required ? <span className='asterisk-color'>*</span> : <span className='optional-text'> (optional)</span>}
        {tooltip && <Tooltip clickable={tooltipClickable} content={tooltip} header={label} name={name} />}
      </Label>
      <div className={containerCss}>
        <Select
          aria-invalid={inputError ? 'true' : 'false'}
          aria-required={required ? 'true' : 'false'}
          disabled={readOnly}
          id={name}
          inputRef={selectRef}
          name={name}
          required={required}
          {...rest}
          {...customProps}
        >
          <option value='' style={{ display: required ? 'none' : 'inline' }}>{`${defaultOption}`}</option>
          {!group && renderedOptions}
          {group && <optgroup label={group}>{renderedOptions}</optgroup>}
          {children}
        </Select>
      </div>
    </>
  );
};

export default SelectInput;
