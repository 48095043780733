export const enum ContactTypes {
  PropertyOwner = 'Property Owner',
  Agent = 'Agent',
  Applicant = 'Applicant',
  SuspectPropertyOwner = 'Suspect - Property Owner',
  SuspectContractor = 'Suspect - Contractor',
  SuspectOther = 'Suspect - Other',
  Witness = 'Witness',
  Reporter = 'Reporter',
  AdjoiningPropertyOwner = 'Adjoining Property Owner',
}

export const enum PreAppRequestTypes {
  StandardPermit = 'Standard permit',
  MitigationBankOrFee = 'Mitigation bank or in-lieu fee',
  ScopingResponse = 'Scoping response for environmental compliance (NEPA, state/local environmental scoping requirements, etc.)',
  NeedAdvice = 'I need advice on what type of permit is applicable',
}

export const enum FileTypes {
  Request = 'Request',
  Template = 'Template',
  Resource = 'Resource',
}

export const enum TemplateFiles {
  AgentAuthorization = 'ENG6295_Agent_Authorization.pdf',
  Amended2023RuleGDB = 'Amended_2023Rule.gdb.zip',
  BulkUploadGuide = 'Aquatic_Resource_Bulk_Upload_Guide.pdf',
  PostSackettGDB = 'Pre2015_Post_Sackett.gdb.zip',
  ProjectLocationGDBTemplate = 'ProjectArea_BLANK.gdb.zip',
  PropertyOwnerBulk = 'Property_Owner_Template_v1.xlsx',
  RightOfEntry = 'ENG6294_Right_Of_Entry.pdf'
}

export const enum ResourceFiles {
  AdditionalInformation = 'JD_Additional_info.pdf',
  AppendixA1 = 'Appx_A1_Adjoining_Property_Owners.pdf',
  AppendixB = 'Appx_B_Aquatic_Resources.pdf',
  AppendixC = 'Appx_C_Impacts.pdf',
  AppendixD1 = 'Appx_D1_MB_ILF.pdf',
  AppendixD2 = 'Appx_D2_PRM.pdf',
  AppendixE = 'Appx_E_Dredging.pdf',
  AppendixF = 'Appx_F_Shoreline_Stabilization.pdf',
  AppendixG = 'Appx_G_Pile_Driving.pdf',
  AppendixH = 'Appx_H_Supporting_Files.pdf',
  ARBulkUploadCSV = 'Aquatic_Resource_Bulk_Upload_Template.csv',
  ARBulkUploadGDBTemplate = 'Aquatic_Resource_Bulk_Upload.gdb.zip',
  ARBulkUploadGuide = 'Aquatic_Resource_Bulk_Upload_Guide.pdf',
  DistrictList = 'JPA_Districts_14MAY2024.pdf',
  DistrictSpecificInfo = 'District_Specific_Information_14MAY2024.pdf',
  Eng4345 = 'Eng_Form_4345_2024Jun.pdf',
  Eng6082 = 'Eng_Form_6082-2024Jul-v2.pdf',
  Eng6247 = 'Eng_Form_6247-Jurisdictional_Determination.2022Jul.pdf',
  Eng6284 = 'Eng_Form_6284_VC.pdf',
  Eng6294 = 'Eng_Form_6294_Right_Of_Entry.2024Jan.pdf',
  Eng6295 = 'Eng_Form_6295-Agent_Authorization.2024Jan.pdf',
  LoginGovHelpGuide = 'Login_gov_guide.pdf',
  Reg101FreqUsedTerms = '20240618_RRS Regulatory Terms_Final.pdf',
  SampleDrawingBankStabilizationBioEng = 'Sample Drawing_Bank Stabilization (Bioengineered) 20240515.pdf',
  SampleDrawingBankStabilizationRipRap = 'Sample Drawing_Bank Stabilization (Riprap)_20240515.pdf',
  SampleDrawingCulvert = 'Sample Drawing_Culvert_20240318.pdf',
  SampleDrawingDock = 'Sample Drawing_Dock_20240226.pdf',
  SampleDrawingStream = 'Sample Drawing_Stream Crossing_20240304.pdf',
  SampleDrawingWetlandHouse = 'Sample Drawing_Wetland Fill for House_20240514.pdf',
  SampleDrawingWetlandRoad = 'Sample Drawing_Wetland Fill for Road_20240426.pdf',
}

export const enum PhoneTypes {
  Residence = 'Residence',
  Business = 'Business',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Mobile = 'Mobile',
}

export const enum ApplicableStatutoryAuthorities {
  Section10 = 'Section 10 of the Rivers and Harbors Act of 1899 (33 U.S.C. 403)',
  Section103 = 'Section 103 of the Marine Protection, Research, and Sanctuaries Act of 1972, as amended (33 U.S.C. 1413)',
  Section404 = 'Section 404 of the Clean Water Act (33 U.S.C. 1344)',
}

export const RequestTypeStrings = {
  1: 'Submit a WOTUS Delineation Report and Aquatic Resource Inventory',
  2: 'Request a Jurisdictional Determination',
  3: 'Individual Permit (ENG 4345)',
  4: 'Nationwide Permit (ENG 6082)',
  5: 'Pre-application/Project Scoping',
  6: 'Violation Complaint',
};

export const enum ProfileRoles {
  Applicant = 'Applicant',
  Agent = 'Agent',
  ExternalAgency = 'External Agency',
  USACERegulator = 'USACE Regulator',
  Anonymous = 'Anonymous',
}

export const enum ContactsFormNames {
  AgentAuthorization = 'Agent Authorization',
  RightOfEntry = 'Right of Entry',
}

export const enum ImpactsActivity {
  ConversionWatersType = 'Conversion of waters type (forested wetland to emergent wetland, stream to lake)',
  DischargeDredgedMaterial = 'Discharge of dredged material',
  DischargeFillMaterial = 'Discharge of fill material',
  DredgingSection = 'Dredging (Section 10)',
  EcologicalRestoration = 'Ecological restoration',
  Other = 'Other (Aquaculture, Work, Aerial or Submarine cable crossings)',
  Removal = 'Removal (Sec 10 structures)',
  Structure = 'Structure (Sec 10 only)',
  TransportDredgedMaterial = 'Transport of dredged material (Sec 103)',
}

export const enum ErrorMessages {
  InvalidCharacters = 'Invalid character(s) have been removed. Currently, we cannot accept any accent characters i.e (è, ã, ñ)',
}
