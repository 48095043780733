import { connect } from 'redux-bundler-react';
import { Button } from '@trussworks/react-uswds';
import DeleteButton from './deleteButton';
import '@styles/_buttons.scss';

const ModalFooter = connect(
  'doModalClose',
  ({
    cancelText = 'Cancel',
    customClosingLogic = false,
    deleteText = 'Delete',
    doModalClose,
    onCancel = null,
    onDelete = null,
    onSave = null,
    saveIsDisabled = false,
    saveText = 'Save',
    showCancelButton = false,
    showSaveButton = true,
  }) => {
    const saveButtonProps = {
      className: 'save-button',
      text: saveText,
      disabled: saveIsDisabled,
      onClick: (e) => {
        if (onSave) onSave(e);
        if (!customClosingLogic) doModalClose();
      },
    };

    return (
      <footer className='modal-footer'>
        <div>
          {(showCancelButton || onCancel) && (
            <Button
              text={cancelText}
              className={'cancel-button'}
              onClick={(e) => {
                if (onCancel) onCancel(e);
                doModalClose();
              }}
            >
              {cancelText}
            </Button>
          )}
        </div>
        {onDelete && (
          <DeleteButton deleteText={deleteText} handleDelete={onDelete} customClosingLogic={customClosingLogic} />
        )}
        {showSaveButton && (
          <Button className={'save-button'} {...saveButtonProps}> {saveButtonProps.text} </Button>
        )}
      </footer>
    );
  }
);

export default ModalFooter;
