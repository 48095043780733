import { ModalContent } from '@components/secondary-modal';

import './tooltip.scss';

const tooltipModal = ({ msg, title, isError, size = 'lg' }) => (
  <ModalContent hasCloseButton={true} hasIcon isError={isError} size={size} title={title}>
    <div className='tooltip-modal-contents'>{msg}</div>
  </ModalContent>
);
export default tooltipModal;
