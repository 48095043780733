import { Button, Checkbox, Table } from '@trussworks/react-uswds';
import { connect } from 'redux-bundler-react';
import Icon from '@components/icon/Icon';
import { mdiLoading } from '@mdi/js';
import ExternalLink from '@src/app-components/external-link/ExternalLink';

const IPACSpeciesTable = connect(
  'selectIsLoading',
  'selectIsError',
  'selectIsReadOnly',
  ({
    isLoading,
    isError,
    isReadOnly,
    fetchSpecies = () => {},
    handleSpeciesCheck = () => {},
    endangeredSpeciesList,
    selectedSpecies
  }) => (
    <Table compact bordered fullWidth>
      <thead>
        <tr>
          <th scope='col'>Select</th>
          <th scope='col'>Common Name</th>
          <th scope='col'>Scientific Name</th>
          <th scope='col'>Listing Status</th>
          <th scope='col'>Species Profile URL</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan='5'><div className='display-flex'><div className='ml-2 spinner'>
              <Icon focusable={false} path={mdiLoading} spin={1} />
            </div><div className='mt-2'>Loading...</div></div></td>
          </tr>
        ) : (
          <>
            {isError ? (
              <tr>
                <td colSpan='5'><div className='mt-2 mb-2'>USFWS IPAC service is unavailable at this time. <Button type='button' className='ml-2' onClick={() => fetchSpecies()}>Retry</Button></div></td>
              </tr>
            ) : (
              <>
                {endangeredSpeciesList?.length === 0 ? (
                  <tr>
                    <td colSpan='5'>No USFWS IPAC species found in project location.</td>
                  </tr>
                ) : (
                  endangeredSpeciesList?.map((species, index) => (
                    <tr key={index}>
                      <td style={{ paddingBottom: '35px', paddingLeft: '25px' }}>
                        <Checkbox
                          id={species.optionalCommonName}
                          name={species.optionalCommonName}
                          onChange={handleSpeciesCheck}
                          checked={selectedSpecies.includes(species.optionalCommonName)}
                          disabled={isReadOnly}
                        />
                      </td>
                      <td>{species.optionalCommonName}</td>
                      <td>{species.optionalScientificName}</td>
                      <td>{species.listingStatusName.toUpperCase()}</td>
                      <td>
                        <ExternalLink href={species.speciesProfileUrl} title={species.speciesCommonName} content={species.speciesProfileUrl} />
                      </td>
                    </tr>
                  ))
                )}
              </>
            )}
          </>
        )}
      </tbody>
    </Table>
  ));

export default IPACSpeciesTable;
