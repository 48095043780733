import { ModalContent, ModalFooter, } from '@components/secondary-modal';

const statusModal = ({
  msg,
  status,
  onSave,
  saveText = 'OK',
  cancelText,
  hasCloseButton = true
}) => (

  <ModalContent hasCloseButton={hasCloseButton} title={status === 'Success' ? 'SUCCESS!' : status === 'Failed' ? 'ERROR!' : status}>
    <section className='modal-body' style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <div className='container-fluid'>
        {msg}
      </div>
    </section>
    <ModalFooter
      saveText={saveText}
      showCancelButton={cancelText}
      onSave={onSave}
      cancelText={cancelText}
    />
  </ModalContent>);
export default statusModal;
