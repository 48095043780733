import { connect } from 'redux-bundler-react';

import { ModalContent } from '@components/modal';
import LinkButton from '@components/link/linkButton';
/* eslint-disable */

const FeedbackModal = connect(
  'doModalClose',
  ({
    doModalClose,
  }) => {

    return (
      <ModalContent hasCloseButton size='sm' title='Submit Feedback'>
        <div className='mt-2 mb-2 text-center'>
          <p className='text-center'>To submit feedback or suggestions, please either:</p>
          <div className='text-center mb-2'>
            <LinkButton onClick={(e) => { window.location.href = 'mailto:rrs@usace.army.mil?subject=RRS%20Feedback'; e?.preventDefault(); }} title='Click This Link' content='Click This Link' />
          </div>
          <p><b> OR </b></p>
          <p className='text-center'>Send an email directly to:</p>
          <p><b>rrs@usace.army.mil</b></p>
        </div>
      </ModalContent >
    );
  });

export default FeedbackModal;
