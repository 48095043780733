import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import FieldHeader from '@forms/components/Form/FieldHeader';
import { Button } from '@trussworks/react-uswds';
import DatePicker from '@components/date-picker/DatePicker';
import TextInput from '@components/text-input/TextInput';
import { Alert } from '@trussworks/react-uswds';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { filterNullEmptyObjects } from '@src/utils/helpers';
import ErrorSummary from '@components/error-summary/ErrorSummary';

export const JDFormCertifySignSubmitMetadata = {
  sectionName: 'Certify, Sign, and Submit',
  isSection: true,
  lastSection: true,
  firstSection: false,
};

const JDFormCertifySignSubmit = connect(
  'doUpdateSectionValidity',
  'doUpdateRequestObject',
  'doUploadAndUpdateJD',
  'selectRequestAPIData',
  'selectRequestFormData',
  'selectFormValidation',
  'selectUserProfileData',
  'selectSelectedRequest',
  'selectJDErrors',
  ({
    doUpdateSectionValidity,
    doUpdateRequestObject,
    doUploadAndUpdateJD,
    requestAPIData,
    requestFormData,
    formValidation,
    userProfileData,
    selectedRequest,
    jDErrors,
    stepNo,
    isReadOnly,
  }) => {
    // Signature always validated against user profile name
    const getName = useCallback(() => userProfileData.middleName ? `${userProfileData.firstName} ${userProfileData.middleName} ${userProfileData.lastName}` : `${userProfileData?.firstName} ${userProfileData.lastName}`, [userProfileData?.firstName, userProfileData?.middleName, userProfileData?.lastName]);

    const defaultValues = {
      signature: requestAPIData?.request?.signature ?? null,
      signatureDate: requestAPIData?.request?.signatureDate !== null ? requestAPIData?.request?.signatureDate?.split('T')[0] : '' ?? '',
    };

    const [isSigned, setIsSigned] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const schema = yup.object().shape({
      signature: yup.string().test('isSigned', `Signature must be ${getName()}`, val => val.trim() === getName()),
      signatureDate: yup.string().required(),
    });

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors }, setValue, watch, handleSubmit, trigger } = methods;
    const errorCount = Object.values(errors)?.length;
    const signature = watch('signature');
    const signatureDate = watch('signatureDate');
    const dateNow = new Date().toISOString().slice(0, 10);

    const handleBlur = (e) => {
      setValue('signature', e.target.value.trim());
    };

    useEffect(() => {
      if (signature?.trim() === getName()) {
        !signatureDate && setValue('signatureDate', new Date().toISOString().slice(0, 10));
        setIsSigned(true);
        trigger();
      } else {
        setIsSigned(false);
      }
    }, [signature, setValue, signatureDate, trigger, getName]);

    const submitForm = (e) => {
      const requestFormDataSubmit = { ...requestFormData, request: { ...requestFormData.request, isSubmit: true } };
      const filteredRequest = filterNullEmptyObjects(requestFormDataSubmit);
      doUploadAndUpdateJD(filteredRequest, 'Submit');
    };

    useEffect(() => {
      doUpdateSectionValidity(JDFormCertifySignSubmitMetadata.sectionName, (isFormValid && isSigned), stepNo, isReadOnly);
      isFormValid && isSigned && signature && signatureDate && doUpdateRequestObject({ signature: signature, signatureDate: signatureDate });
    }, [isFormValid, isSigned, signature, signatureDate, doUpdateRequestObject, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      setIsFormValid(Object.values(formValidation).find(section => section.validity === false && section.section !== 'Certify, Sign, and Submit') ? false : true);
    }, [formValidation]);

    return (
      <FormProvider {...methods}>
        {/* Error Summary list for section errors */}
        {(!isFormValid || errorCount > 0) && !isReadOnly && <ErrorSummary errors={errors} type='form' />}
        {/* Error Summary list for validation errors */}
        {jDErrors && (Object.keys(jDErrors)?.length > 0) && <ErrorSummary errors={jDErrors} type='summary' />}
        <FieldHeader text='Certify, Sign, and Submit'>
          <span className='text-bold'>This request must be signed by the person who desires to undertake the proposed activity (applicant) or it may be signed by a duly authorized agent if a completed agent authorization is provided.</span>
        </FieldHeader>
        <div className='text-bold'>
          <div className='row ml-2'>
            <p className='h6 w-100 pb-2 b'>BY SUBMITTING THIS REQUEST, I AM AGREEING TO ALL OF THE FOLLOWING:</p>
          </div>
          <ul>
            <li>I consent to conduct business electronically and understand this consent can be withdrawn at any time in writing to the local USACE Regulatory office.</li>
            <li>By signing this request, I certify that the information contained in this request and any attached documents are true and correct to the best of my knowledge.</li>
            {selectedRequest === '1' && <li>I understand that, as an agent, I am providing this information on behalf of my client on a voluntary basis to help facilitate a more efficient review of the project.</li>}
            <li>I understand that, whoever, in any matter within the jurisdiction of any department of agency of the United States, knowingly and willfully (1) falsifies, conceals, or covers up by any trick, scheme, or device a material fact; (2) makes any materially false, fictitious, or fraudulent statement or representation; or (3) makes or uses any false writing or document knowing the same to contain any materially false, fictitious, or fraudulent statement or entry; shall be fined not more than $10,000 or imprisoned not more than 5 years or both. (18 USC Section 1001)</li>
            <li>I understand that by typing my name in the text field below that I am e-signing this application and I understand that this e-signature has the same legal status as handwritten signatures.</li>
          </ul>
        </div>
        {isFormValid || isReadOnly ?
          <>
            <p className='h6 border-bottom w-100 pb-2 b'>Signature</p>
            <div className='row'>
              <div className='col-6'>
                <TextInput name='signature' label='Signature' signature={getName()} required readOnly={isReadOnly} onBlur={handleBlur} />
              </div>
              <div className='col-6'>
                <DatePicker min={null} max={dateNow} name='signatureDate' label='Signature Date' readOnly={isReadOnly} />
              </div>
            </div>
            <p className='h6 border-bottom w-100 pb-2 b'></p>
            <div className='row'>
              <div className='col-4 mb-3 pb-2'>
                <Button className='submit-request-button' title='Submit Request' disabled={!isSigned || isReadOnly} onClick={handleSubmit(submitForm)}> Submit Request </Button>
              </div>
            </div></> : <Alert slim className='mb-3' type='error' >In order to submit this form, please revisit the section(s) marked with errors, and correct any missing or invalid fields.</Alert>}
      </FormProvider>
    );
  }
);

JDFormCertifySignSubmit.metadata = JDFormCertifySignSubmitMetadata;

export default JDFormCertifySignSubmit;
