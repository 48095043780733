import React, { useState, useEffect, useCallback, useRef } from 'react';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const MeasurementUnitTableCell =
  ({
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const initialOptions = columnMeta?.options[0];
    const [value, setValue] = useState(initialValue);
    const [measurementOptions, setMeasurementOptions] = useState(initialOptions);
    const measurementType = row.getValue('measurementType');
    const debouncedUpdateRef = useRef();

    useEffect(() => {
      debouncedUpdateRef.current = debounce((newValue) => {
        const processedValue = columnMeta?.type === 'number' ? Number(newValue) : newValue ?? newValue;
        if (tableMeta?.updateData) {
          tableMeta.updateData(row.index, column.id, processedValue);
        }
      }, 500);
    }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

    const updateValue = useCallback((newValue) => {
      debouncedUpdateRef.current(newValue);
    }, []);

    useEffect(() => {
      if (measurementType === 'Linear' && value !== 'FOOT') {
        setValue('FOOT');
        tableMeta?.updateData(row.index, column.id, 'FOOT');
      }
      else if (measurementType === 'Area' && measurementOptions !== initialOptions) {
        setValue(null);
        tableMeta?.updateData(row.index, column.id, null);
      }
    }, [measurementOptions, measurementType, column.id, row.index, tableMeta, initialOptions, value]);

    useEffect(() => {
      const options = measurementType === 'Area' ? columnMeta?.options[0] : columnMeta?.options[1];
      setMeasurementOptions(options);
    }, [measurementType, columnMeta?.options]);

    const handleBlur = (e) => {
      // tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(e.target.value) : e.target.value ?? e.target.value);
    };

    const handleChange = (e) => {
      setValue(e.target.value);
      updateValue(e.target.value);
    };

    return (
      <select
        id={cell.id}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value ?? ''}
        required={columnMeta?.required}
        disabled={columnMeta?.readOnly}
        style={{ width: '100%' }}>
        <option key={0} value='' className='none' disabled style={{ display: 'none' }}>-- Select a value --</option>
        {measurementOptions.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    );
  };

export default MeasurementUnitTableCell;