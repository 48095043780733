import { toast } from 'react-toastify';
import statusModal from '@pages/Forms/components/modals/statusModal';
import submissionModal from '@pages/Forms/components/modals/submission';

const violationsFormDataBundle = {
  name: 'violationsFormData',

  getReducer: () => {
    const initialState = {
      violationComplaints: [],
      errors: {}
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case 'RESET_VIOLATION_COMPLAINT':
          return initialState;
        case 'UPDATE_VIOLATION_COMPLAINT':
          return {
            ...state,
            violationComplaints: [{ ...state.violationComplaints[0], ...payload }]
          };
        case 'UPDATE_VIOLATION_COMPLAINT_CONTACTS':
          return {
            ...state,
            violationComplaints: [{
              ...state.violationComplaints[0],
              contacts: payload,
            }]
          };
        case 'UPDATE_VIOLATION_COMPLAINT_IS_ALLEGED_VIOLATOR':
          return {
            ...state,
            violationComplaints: [{
              ...state.violationComplaints[0],
              isAllegedViolatorKnown: payload,
            }]
          };
        case 'UPDATE_IS_ANONYMOUS':
          return {
            ...state,
            violationComplaints: [{
              ...state.violationComplaints[0],
              isAnonymous: payload
            }]
          };
        case 'UPDATE_VIOLATION_COMPLAINT_ERRORS':
          return { ...state, errors: payload };
        default:
          return state;
      };
    };
  },

  selectIsAnonymous: (state) => state.violationsFormData.violationComplaints.length > 0 && state.violationsFormData.violationComplaints[0].isAnonymous,
  selectViolationComplaintData: (state) => state.violationsFormData.violationComplaints[0],
  selectViolationComplaintID: (state) => state.violationsFormData.violationComplaints[0].violationComplaintID,
  selectViolationComplaintErrors: (state) => state.violationsFormData.errors,

  doFetchViolationComplaint: (projectID, requestID, requestStatus, isGenerate = false) => ({ apiGet, dispatch, store }) => {
    !isGenerate && store.doSetLoadingState(true);
    !isGenerate && store.doSetLoadingMessage('Retrieving Violation Complaint Information...');

    store.doResetViolationComplaintRequest();
    store.doResetRequestData();

    const uri = '/api/ViolationComplaint/getViolationComplaint?' + new URLSearchParams({
      projectID,
      requestID,
    });

    return apiGet(uri, (err, body) => {
      !isGenerate && store.doSetLoadingMessage('Loading...');
      if (!err && body.status === 'Success') {
        dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: body.data });
        dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: body.data });
        dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: requestStatus } });
        dispatch({ type: 'UPDATE_VIOLATION_COMPLAINT', payload: body.data.request.violationComplaints[0] });
        if (isGenerate) {
          store.doGenerateDraftDocuments();
        }
        else {
          store.doUpdateSelectedRequest('6');
          store.doUpdateRelativeUrl(`/forms/${requestID}`);
          store.doFetchUserFilesDetails(body.data.request.violationComplaints[0].violationComplaintID)
            .finally(() => {
              dispatch({ type: 'SET_LOADING_STATE', payload: false });
            });
        }
      } else {
        dispatch({ type: 'VIOLATION_COMPLAINT_UPDATE_ERROR', payload: err });
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while retrieving the request, please try again', status: body?.status ?? err?.status ?? 'Request Retrieval Error' });
        dispatch({ type: 'SET_LOADING_STATE', payload: false });
      }
    });
  },
  doAddViolationComplaint: () => ({ apiPost, dispatch, store }) => {
    store.doSetLoadingState(true);
    store.doSetLoadingMessage('Creating Violation Complaint...');

    const uri = '/api/ViolationComplaint/addViolationComplaint';

    return apiPost(uri, null, (err, body) => {
      store.doSetLoadingState(false);
      store.doSetLoadingMessage('Loading...');
      if (!err && body.status === 'Success') {
        dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: { projectID: body.data.projectID, requestID: body.data.requestID } });
        dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: { projectID: body.data.projectID, requestID: body.data.requestID } });
        dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: 'Draft' } });
        dispatch({ type: 'UPDATE_VIOLATION_COMPLAINT', payload: { violationComplaintID: body.data.violationComplaintID } });
        store.doUpdateViolationComplaint(store.selectRequestFormData(), true);
        store.doUpdateRelativeUrl('/forms');
        store.doModalClose();
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while creating the request, please try again', status: body?.status ?? err?.status ?? 'Request Creation Error' });
      }
    });
  },
  doAddAnonymousViolationComplaint: () => ({ apiPost, dispatch, store }) => {
    store.doSetLoadingState(true);
    store.doSetLoadingMessage('Creating Anonymous Violation Complaint...');

    const uri = '/api/ViolationComplaint/addAnonymousViolationComplaint';
    const skipAuth = true;

    return apiPost(uri, null, (err, body) => {
      store.doSetLoadingState(false);
      store.doSetLoadingMessage('Loading...');
      if (!err && body.status === 'Success') {
        dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: { projectID: body.data.projectID, requestID: body.data.requestID } });
        dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: { projectID: body.data.projectID, requestID: body.data.requestID } });
        dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: 'Draft' } });
        dispatch({ type: 'UPDATE_VIOLATION_COMPLAINT', payload: { violationComplaintID: body.data.violationComplaintID } });
        store.doUpdateRelativeUrl('/forms');
        store.doModalClose();
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while creating the request, please try again', status: body?.status ?? err?.status ?? 'Request Creation Error' });
      }
    }, skipAuth);
  },
  doUploadAndUpdateViolationComplaint: (data, status) => ({ store }) => {
    store.doSetLoadingState(true);
    store.doSetLoadingMessage('Uploading Violation Complaint Files...');

    if (store.selectSelectedFiles()?.length > 0) {
      // Format to FormData
      const formdata = new FormData();
      formdata.append('overwrite', false);
      formdata.append('isAnonymous', false);
      store.selectSelectedFiles().forEach(item => {
        const fileMetaData = {
          ProjectID: store.selectProjectID(),
          RequestID: store.selectRequestID(),
          Version: 0,
          Type: status,
          Tags: item?.tags
        };
        formdata.append('files', item?.file);
        formdata.append('fileMetaData', JSON.stringify(fileMetaData));
      });

      // Upload Files and Save Request
      Promise.all([store.doUploadFiles(formdata)])
        .then(results => {
          if (results?.[0]?.status === 'Success') {
            store.doSetLoadingMessage('Saving Violation Complaint...');
            store.doUpdateViolationComplaint(data);
            store.doResetUsersFileData();
            store.doFetchUserFilesDetails(store.selectViolationComplaintID());
          } else {
            // if any of the files failed to upload
            store.doSetLoadingState(false);
            store.doSetLoadingMessage('Loading...');
            store.doResetUsersFileData();
            store.doFetchUserFilesDetails(store.selectViolationComplaintID());
            store.doSecondaryModalOpen(statusModal, {
              status: 'ERROR! Failed Uploads',
              msg: (
                <>
                  <p>{results?.[0]?.msg}</p>
                  <p>Retry saving/submitting your Violation Complaint in a few minutes.</p>
                </>),
              saveText: 'Return to request',
            });
          }
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      // If no files were selected, just update VC
      store.doUpdateViolationComplaint(data);
    }
  },
  doUpdateViolationComplaint: (data, addVC) => ({ apiPut, dispatch, store }) => {
    const uri = '/api/ViolationComplaint/updateViolationComplaint';

    return apiPut(uri, data, (err, body) => {
      const signed = Boolean(data.request.isSubmit);
      store.doSetLoadingState(false);
      store.doSetLoadingMessage('Loading...');
      if (!err && ['Success', 'Success With Warning'].includes(body?.status)) {
        signed && toast.success('Violation Complaint has been successfully submitted!');
        signed && store.doModalOpen(submissionModal, {
          msg: body?.msg,
          status: body?.status ?? 'Success'
        });
        signed && store.doUpdateRelativeUrl('/dashboard');
        !signed && !addVC && toast.success('Violation Complaint has been successfully saved!');
        !signed && dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: body.data });
        !signed && dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: 'Draft' } });
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while saving or submitting the request, please try again', status: body?.status ?? err?.status ?? 'Request Save/Submission Error' });
        dispatch({ type: 'UPDATE_VIOLATION_COMPLAINT_ERRORS', payload: body?.data });
      }
    });
  },
  doSubmitAnonymous: (data) => ({ store }) => {
    store.doSetLoadingState(true);
    store.doSetLoadingMessage('Uploading Anonymous Violation Complaint Files...');

    if (store.selectSelectedFiles()?.length > 0) {
      // Format to FormData
      const formdata = new FormData();
      formdata.append('overwrite', false);
      formdata.append('isAnonymous', true);
      store.selectSelectedFiles().forEach(item => {
        const fileMetaData = {
          ProjectID: store.selectProjectID(),
          RequestID: store.selectRequestID(),
          Version: 0,
          Type: 'Submitted',
          Tags: item?.tags
        };
        formdata.append('files', item?.file);
        formdata.append('fileMetaData', JSON.stringify(fileMetaData));
      });

      Promise.all([store.doUploadFilesAnonymous(formdata)])
        .then(results => {
          // if all files upload successfully
          if (results?.[0]?.status === 'Success') {
            store.doSetLoadingMessage('Saving Violation Complaint...');
            store.doUpdateAnonymousViolationComplaint(data);
            store.doResetUsersFileData();
          } else {
            // if any of the files failed to upload
            store.doSetLoadingState(false);
            store.doSetLoadingMessage('Loading...');
            store.doResetUsersFileData();
            store.doSecondaryModalOpen(statusModal, {
              status: 'ERROR! Failed Uploads',
              msg: (
                <>
                  <p>{results?.[0]?.msg}</p>
                  <p>Retry saving/submitting your Violation Complaint in a few minutes.</p>
                </>),
              saveText: 'Return to request',
            });
          }
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      // If no files were selected, just update VC
      store.doUpdateAnonymousViolationComplaint(data);
    }
  },
  doUpdateAnonymousViolationComplaint: (data) => ({ apiPut, store, dispatch }) => {
    const uri = '/api/ViolationComplaint/updateAnonymousViolationComplaint';
    const skipAuth = true;

    return apiPut(uri, data, (err, body) => {
      const signed = Boolean(data.request.isSubmit);
      store.doSetLoadingState(false);
      store.doSetLoadingMessage('Loading...');
      if (!err && ['Success', 'Success With Warning'].includes(body?.status)) {
        signed && toast.success('Anonymous Violation Complaint has been successfully submitted!');
        signed && store.doUpdateRelativeUrl('/');
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while saving or submitting the request, please try again', status: body?.status ?? err?.status ?? 'Request Save/Submission Error' });
        dispatch({ type: 'UPDATE_VIOLATION_COMPLAINT_ERRORS', payload: body?.data });
      }
    }, skipAuth);
  },
  doUpdateViolationComplaintRequest: (data) => ({ dispatch, store }) => {
    dispatch({ type: 'UPDATE_VIOLATION_COMPLAINT', payload: data });
    dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { violationComplaints: [store.selectViolationComplaintData()] } });
  },
  doUpdateViolationComplaintRequestContacts: (data) => ({ dispatch, store }) => {
    dispatch({ type: 'UPDATE_VIOLATION_COMPLAINT_CONTACTS', payload: data });
    dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { violationComplaints: [store.selectViolationComplaintData()] } });
  },
  doUpdateIsAnonymous: (data) => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_IS_ANONYMOUS', payload: data });
  },
  doResetViolationComplaintRequest: () => ({ dispatch, store }) => {
    dispatch({ type: 'RESET_VIOLATION_COMPLAINT' });
    store.doResetUsersFileData();
  },
  doUpdateIsAllegedViolatorKnown: (data) => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_VIOLATION_COMPLAINT_IS_ALLEGED_VIOLATOR', payload: data });
  },
};

export default violationsFormDataBundle;