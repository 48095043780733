import { ModalContent } from '@components/modal';
import LinkButton from '@components/link/linkButton';

const HelpModal = () => (
  <ModalContent hasCloseButton size='sm' title='Submit a Help Ticket'>
    <div className='mt-2 mb-2 text-center'>
      <p className='text-center'>To submit a ticket, please either:</p>
      <div className='text-center mb-2'>
        <LinkButton onClick={(e) => { window.location.href = 'mailto:rrs@usace.army.mil?subject=RRS%20Support%20Ticket'; e?.preventDefault(); }} title='Click This Link' content='Click This Link' />
      </div>
      <p><b> OR </b></p>
      <p className='text-center'>Send an email directly to:</p>
      <p><b>rrs@usace.army.mil</b></p>
    </div>
  </ModalContent >
);

export default HelpModal;
