import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { decimalNumberRegex } from '@src/utils/regex';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const ProposedCreditAmountTableCell = ({
  getValue,
  row,
  column,
  table,
  cell,
}) => {
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const [mitigationType, setMitigationType] = useState();
  const rowMitigationType = useMemo(() => row.getValue('mitigationType'), [row]);
  const debouncedUpdateRef = useRef();

  useEffect(() => {
    debouncedUpdateRef.current = debounce((newValue) => {
      if (tableMeta?.updateData) {
        tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(newValue) : newValue ?? newValue);
      }
    }, 500);
  }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

  const updateValue = useCallback((newValue) => {
    debouncedUpdateRef.current(newValue);
  }, []);

  useEffect(() => {
    rowMitigationType && setMitigationType(rowMitigationType);
  }, [rowMitigationType]);

  const handleBlur = (e) => {
    // Clear field if value is 0 or is a negative number
    if (String(e?.target?.value) === '0' || String(e?.target?.value)[0] === '-') {
      setValue(null);
      updateValue(null);
    }
  };

  const handleChange = (e) => {
    const inputValue = e?.target?.value ?? '';
    if (decimalNumberRegex.test(inputValue) || inputValue === '') {
      setValue(inputValue === '' ? null : inputValue);
      updateValue(inputValue === '' ? null : inputValue);
    }
  };

  return columnMeta?.type === 'select' ? (
    <select onChange={handleChange} onBlur={handleBlur} value={value ?? ''} required={columnMeta?.required || mitigationType === 'Mitigation Bank' || mitigationType === 'In-lieu Fee'} disabled={(columnMeta?.readOnly || (mitigationType !== 'Mitigation Bank' && mitigationType !== 'In-lieu Fee'))} style={{ width: '100%' }}>
      <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
      {columnMeta?.options?.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  ) : (
    <input
      id={cell.id}
      style={{ width: '100%', cursor: (columnMeta?.readOnly || (mitigationType !== 'Mitigation Bank' && mitigationType !== 'In-lieu Fee')) ? 'not-allowed' : 'auto' }}
      value={value ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      type={columnMeta?.type || 'text'}
      required={columnMeta?.required}
      readOnly={columnMeta?.readOnly}
      disabled={(columnMeta?.readOnly || (mitigationType !== 'Mitigation Bank' && mitigationType !== 'In-lieu Fee'))}
      placeholder={columnMeta?.readOnly || (mitigationType !== 'Mitigation Bank' && mitigationType !== 'In-lieu Fee') ? 'N/A' : ''}
    />
  );
};