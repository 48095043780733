import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import JDFormInstructions from '../static-forms/JDFormInstructions';
import JDFormGeneralProjectInformation from '../input-forms/JDForm/JDFormGeneralProjectInformation';
import JDFormContacts from '../input-forms/JDForm/JDFormContacts';
import SupportingInformation from '../input-forms/common/SupportingInformation';
import JDFormCertifySignSubmit from '../input-forms/JDForm/JDFormCertifySignSubmit';
import JDFormPropertyOwners from '../input-forms/JDForm/JDFormPropertyOwners';
import JDFormAquaticResources from '../input-forms/JDForm/JDFormAquaticResources';

//List of steps for vertical stepper, these can also be used for form header. index = step ID 
export const JDForm1aMetadata = [
  JDFormInstructions.metadata,
  JDFormGeneralProjectInformation.metadata,
  JDFormContacts.metadata,
  JDFormPropertyOwners.metadata,
  JDFormAquaticResources.metadata,
  SupportingInformation.metadata,
  JDFormCertifySignSubmit.metadata,
];

const JDForm1a = connect(
  'doUpdateJDRequest',
  'selectActiveStep',
  'selectIsReadOnly',
  'selectJDRequestID',
  'selectJDData',
  ({
    doUpdateJDRequest,
    activeStep,
    isReadOnly,
    jDRequestID,
    jDData,
    edit,
  }) => {
    const [showInstructions, setShowInstructions] = useState(false);
    const [showGeneralProjectInfo, setShowGeneralProjectInfo] = useState(false);
    const [showContactInfo, setShowContactInfo] = useState(false);
    const [showPropertyOwners, setShowPropertyOwners] = useState(false);
    const [showAquaticResources, setShowAquaticResources] = useState(false);
    const [showSupportingInfo, setShowSupportingInfo] = useState(false);
    const [showCertifySignSubmit, setShowCertifySignSubmit] = useState(false);

    //display only active step, hide all other steps
    useEffect(() => {
      if (activeStep) {
        switch (activeStep.id) {
          case 0: {
            setShowInstructions(true);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowAquaticResources(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 1: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(true);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowAquaticResources(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 2: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(true);
            setShowPropertyOwners(false);
            setShowAquaticResources(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 3: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(true);
            setShowAquaticResources(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 4: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowAquaticResources(true);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 5: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowAquaticResources(false);
            setShowSupportingInfo(true);
            setShowCertifySignSubmit(false);
            break;
          }
          case 6: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowAquaticResources(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(true);
            break;
          }
          default: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowAquaticResources(true);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
          }
        }
      }
    }, [activeStep]);


    const instructionStyles = { display: showInstructions ? 'inline' : 'none' };
    const generalProjInfoStyles = { display: showGeneralProjectInfo ? 'inline' : 'none' };
    const contactInfoStyles = { display: showContactInfo ? 'inline' : 'none' };
    const propertyOwnersStyles = { display: showPropertyOwners ? 'inline' : 'none' };
    const ARStyles = { display: showAquaticResources ? 'inline' : 'none' };
    const supportingInfoStyles = { display: showSupportingInfo ? 'inline' : 'none' };
    const certifySignSubmitStyles = { display: showCertifySignSubmit ? 'inline' : 'none' };

    return (<>
      <section id='JD1aInstructions' name='Instructions' style={instructionStyles}>
        <JDFormInstructions stepNo={0} />
      </section>
      <section id='JD1aGeneralProjectInformation' name='General Project Information' style={generalProjInfoStyles}>
        <JDFormGeneralProjectInformation isReadOnly={isReadOnly} stepNo={1} />
      </section>
      <section id='JD1aContactInformation' name='Contact Information' style={contactInfoStyles}>
        <JDFormContacts componentID={jDRequestID} edit={edit} isReadOnly={isReadOnly} stepNo={2} />
      </section>
      <section id='JD1aPropertyOwners' name='Property Owners' style={propertyOwnersStyles}>
        <JDFormPropertyOwners componentID={jDRequestID} edit={edit} isReadOnly={isReadOnly} stepNo={3} />
      </section>
      <section id='JD1aAquaticResources' name='Aquatic Resources' style={ARStyles}>
        <JDFormAquaticResources stepNo={4} />
      </section>
      <section id='JD1aSupportingInformation' name='Supporting Information' style={supportingInfoStyles}>
        <SupportingInformation
          componentID={jDRequestID}
          subtext={<p>To facilitate your request, we strongly encourage a copy of the Delineation Report and supporting information be uploaded in this section. The types of supporting information that should be provided are contained in the drop-down list when you click on the "Add a Document" tab. Information
            that is most helpful in the USACE review are aerial photographs, automated wetland data sheets, and maps/plans/plots. Multiple documents of the same file type can be combined and uploaded as one document.</p>}
          isReadOnly={isReadOnly}
          requestData={jDData}
          updateRequestData={doUpdateJDRequest}
          stepNo={5}
        />
      </section>
      <section id='JD1aCertifySignSubmit' name='Certify Sign Submit' style={certifySignSubmitStyles}>
        <JDFormCertifySignSubmit isReadOnly={isReadOnly} stepNo={6} />
      </section>
    </>);

  });

export default JDForm1a;
