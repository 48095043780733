import { connect } from 'redux-bundler-react';
import { TableWrapper } from './FormAndAppendices';
import LinkButton from '@components/link/linkButton';
import { FileTypes, ResourceFiles } from '@src/utils/enums';

const FormsAndAppendicesPreConstruction = connect('doDownloadFile', ({ doDownloadFile }) => (
  <>
    <p>
      This path will assist property owners or their agents in providing the information necessary to ensure that their
      proposed project meets the terms and conditions of the Nationwide Permit(s) they intend to use.
    </p>
    <TableWrapper>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6082, FileTypes.Resource)}>
            ENG 6082 Nationwide Permit Pre-Construction Notification (PCN) [PDF, 3 pages]
          </LinkButton>
        </td>
        <td>
          This form is used to provide notification to USACE regarding an applicant’s desire to use a particular
          Nationwide Permit.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6295, FileTypes.Resource)}>
            ENG 6295 Agent Authorization Form [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          This form indicates that the property owner has given permission to an agent/consultant to act on his or her
          behalf in all matters relating to obtaining a Nationwide Permit.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6294, FileTypes.Resource)}>
            ENG 6294 Right of Entry [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          This form provides permission to the USACE Project Manager to enter the property for which the
          Pre-Construction Notification is being prepared.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixA1, FileTypes.Resource)}>
            App. A1 Property Owners [PDF, 1 page]
          </LinkButton>
        </td>
        <td>Names and address of all persons who own or who have a legal interest in a specific piece of property.</td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixB, FileTypes.Resource)}>
            App. B Aquatic Resources [PDF, 1 page]
          </LinkButton>
        </td>
        <td>Inventory of aquatic resources that may or may not be jurisdictional under the Clean Water Act.</td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixC, FileTypes.Resource)}>
            App. C Impact Inventory [PDF, 2 pages]
          </LinkButton>
        </td>
        <td>Inventory of proposed impacts to aquatic resources on specific piece of property.</td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixD1, FileTypes.Resource)}>
            App. D1 Mitigation Inventory MB and ILF [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          Proposed mitigation that will be obtained from either (or both) a mitigation bank (MB) or an In-Lieu-Fee (ILF)
          program.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixD2, FileTypes.Resource)}>
            App. D2 Mitigation Inventory PRM [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          Proposed mitigation that will be undertaken by a permittee (permittee responsible mitigation – PRM) either
          on-site or off-site.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixE, FileTypes.Resource)}>
            App. E Dredging Information Sheet [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          Information pertaining to proposed dredging impacts that include the type of material being dredged, how the
          dredging will be undertaken, quantities, and disposal locations.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixF, FileTypes.Resource)}>
            App F. Shoreline Stabilization Project Information Sheet [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          If you entered yes to the question regarding bank stabilization this will include information about how the
          project will be constructed (using pilings or fill), dimensions of the structure, etc.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixG, FileTypes.Resource)}>
            App G. Pile Driving Information Sheet [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          If you entered yes to the question regarding pilings this will include information about the type, material,
          diameter, installation method, construction method, etc.
        </td>
      </tr>

      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixH, FileTypes.Resource)}>
            App H. Supporting Information
          </LinkButton>
        </td>
        <td>
          Information that is uploaded by the requestor to support and/or clarify the request that is being made. This
          information can include, but is not limited to, photos, maps, drawings, other authorizations, mitigation
          plans, etc.
        </td>
      </tr>
    </TableWrapper>
  </>
));

export default FormsAndAppendicesPreConstruction;
