import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import ImageFigure from '../components/Form/ImageFigure';
import LateralLimitsTidal from '../../../styles/images/RRS Graphic V3_042623.png';
import LateralLimitsFresh from '../../../styles/images/RRS Graphic V2_041423.png';
import FieldHeader from '../components/Form/FieldHeader';
import ExternalLink from '@components/external-link/ExternalLink';

export const JDFormInstructionsMetadata = {
  header: 'Request Jurisdictional Determination (Applicants/Landowners)',
  sectionName: 'Instructions',
  isSection: true,
  lastSection: false,
  firstSection: true,
};

const JDFormInstructions = connect(
  'doAddSectionValidity',
  'doUpdateSectionValidity',

  ({
    doUpdateSectionValidity,
    stepNo,
  }) => {

    useEffect(() => {
      doUpdateSectionValidity(JDFormInstructionsMetadata.sectionName, true, stepNo);
    }, [doUpdateSectionValidity, stepNo]);

    return (
      <>
        <FieldHeader text='INSTRUCTIONS' />
        <p>This request provides a way for agents or individuals to electronically submit <b>delineation reports</b> and/or request a <b>written jurisdictional determination.</b> This electronic application will guide you through providing the information necessary to the United States Army Corps of Engineers (USACE) to efficiently process your request.</p>

        <p><u><b>Delineation of waters of the United States:</b></u></p>
        <p className='mb-3'>A <b>delineation of waters of the United States</b> is the act of identifying and locating aquatic resources (including wetlands) within a specified area using scientific methods developed by the U.S. Army Corps of Engineers. A <b>delineation report</b> is a term often used to describe a document or series of documents, stored in the USACE administrative record
          for the project, that describes the methodology used to conduct the delineation and typically includes a collection of one or more maps, datasheets, photographs, and/or figures that depict and support a legally defensible delineation of waters of the United States on a property. Agents typically submit delineation reports to the USACE in support of
          permit applications and/or jurisdictional determination requests. The submission of a delineation report by an agent helps accelerate the USACE review of the project but is <b>strictly voluntary</b>. Agents should use the example maps/figures/templates and methods provided in these instructions to create the delineation report. Following these examples will
          help facilitate an efficient review of the delineation and prevent the need for the USACE to request and/or collect additional information. The USACE project manager ultimately determines the level of information necessary to support the delineation.</p>

        <p><u><b>Delineation Methods and Resources:</b></u></p>
        <p><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/reg_supp/' title='Wetland Delineation Manual and Regional Supplements' content='Wetland Delineation Manual and Regional Supplements' /></p>
        <p>Wetland delineation consists of standardized procedures that are used to determine if a wetland is present on a site and, if so, to establish its boundaries in the field.</p>
        <p><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/reg_supp/' title='Automated Wetland Datasheets (ADS)' content='Automated Wetland Datasheets (ADS)' /></p>
        <p>The Microsoft Excel-based ADS increases technical accuracy by reducing errors and increases efficiency by automatically populating many of the field indicators of wetland hydrology, hydrophytic vegetation, and hydric soils. These should be used to the extent possible instead of paper datasheets forms.</p>
        <p><ExternalLink href='https://www.erdc.usace.army.mil/Media/Fact-Sheets/Fact-Sheet-Article-View/Article/486085/ordinary-high-water-mark-ohwm-research-development-and-training/' title='Ordinary High Water Mark Guidance (OHWM) and Manual' content='Ordinary High Water Mark Guidance (OHWM) and Manual' /></p>
        <p>The OHWM defines the lateral limits of non-tidal open waters including streams, rivers, lakes, ponds, etc.</p>
        <p><ExternalLink href='https://wetland-plants.usace.army.mil/nwpl_static/v34/home/home.html' title='National Wetland Plant List (NWPL)' content='National Wetland Plant List (NWPL)' /></p>
        <p>The NWPL, along with its wetland plant species status, plays a crucial role in wetland delineation, wetland restoration and research. Additionally, it serves as a valuable resource for general botanical information about wetland plants. The NWPL encompasses all 50 U.S. states, the District of Columbia, and the U.S. territories. Furthermore, to enhance understanding of plant distribution,
          the NWPL includes a complete geographic range for each taxon across North America north of Mexico. The wetland plant data is categorized into ten regions that align with the wetland delineation regions established by the U.S. Army Corps of Engineers.</p>

        <p><u><b>Jurisdictional Determination:</b></u></p>
        <p>Obtaining a Jurisdictional Determination (JD) typically involves a two-step process of 1) identifying and delineating the geographic extent of all aquatic resources (including wetlands) on a property through the creation of a delineation report and 2) the USACE issuing a written determination identifying which delineated areas are or may be (depending on the type of JD requested) regulated
          by the USACE under section 404 of the Clean Water Act and/or Section 10 of the Rivers and Harbors Act. Jurisdictional determinations are not required for the USACE to issue a permit but can provide a level of written assurance regarding the accuracy of the delineated waters during the planning phase of a project.</p>

        <div className='d-flex justify-content-center mb-2'>
          <ImageFigure
            src={LateralLimitsTidal}
            alt='USACE jurisdiction in tidal waters'
            caption='This figure is a graphical depiction of the lateral limits of the USACE jurisdiction in tidal waters'
            style={{ 'width': '700px' }}
          />
        </div>
        <div className='d-flex justify-content-center mb-2'>
          <ImageFigure
            src={LateralLimitsFresh}
            alt='USACE jurisdiction in fresh waters'
            caption='This figure is a graphical depiction of the lateral limits of the USACE jurisdiction in fresh waters (non-tidal waters).'
            style={{ 'width': '700px' }}
          />
        </div>

        <p>The USACE provides two types of JDs:</p>
        <p><u>Approved Jurisdictional Determination (AJD)</u> - An AJD is defined in USACE regulations at 33 CFR 331.2. As explained in further detail in <ExternalLink href='https://usace.contentdm.oclc.org/utils/getfile/collection/p16021coll9/id/1256' title='RGL 16-01' content='RGL 16-01' />, an AJD is used to indicate that the USACE has identified the presence or absence of wetlands and/or other aquatic resources on a site, including their accurate location(s) and boundaries, as well as their jurisdictional status. AJDs are valid for 5 years.</p>
        <p><u>Preliminary Jurisdictional Determination (PJD)</u> - A PJD is defined in USACE regulations at 33 CFR 331.2. As explained in further detail in <ExternalLink href='https://usace.contentdm.oclc.org/utils/getfile/collection/p16021coll9/id/1256' title='RGL 16-01' content='RGL 16-01' />, a PJD is used to indicate that the USACE has identified the approximate location(s) and boundaries of wetlands and/or other aquatic resources on a site that are presumed to be subject to regulatory jurisdiction of the U.S. Army Corps of Engineers. Unlike an AJD, a PJD
          does not represent a definitive, official determination that there are, or that there are not, jurisdictional aquatic resources on a site, and does not have an expiration date.</p>
      </>
    );
  });

JDFormInstructions.metadata = JDFormInstructionsMetadata;

export default JDFormInstructions;
