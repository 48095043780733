import statusModal from '../app-pages/Forms/components/modals/statusModal';
import { tSuccess, tError } from '../utils/toast-helpers';
import * as turf from '@turf/turf';

const geocodingAPIBundle = {
  name: 'geocodingapi',
  getReducer: () => {
    const initialData = [];
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'RESET_GEOCODING_RESULTS':
          return { ...state, gdbproject: null, gdbresources: null, reversegeocode: null };
        case 'PARSING_PROJECT_GEOMETRIES_FROM_GDB':
          return payload ? { ...state, gdbproject: payload } : state;
        case 'PARSING_RESOURCE_GEOMETRIES_FROM_GDB':
          return payload ? { ...state, gdbresources: payload } : state;

        case 'GEOCODING_ADDRESS_FROM_LATLNG':
          return payload.address ? { ...state, reversegeocode: payload.address } : state;

        case 'GEOCODING_ADDRESS_FROM_ADDRESS_NAME':
          return payload ? { ...state, addresscandidates: payload } : state;

        case 'GEOCODING_ADDRESS_SUGGESTIONS_FROM_TEXT':
          return payload ? { ...state, suggest: payload } : state;

        case 'RESET_ADDRESS_SUGGESTIONS':

          return { ...state, suggest: undefined };

        case 'RESET_ADDRESS_CANDIDATES':

          return { ...state, addresscandidates: undefined };

        default:
          return state;
      }
    };
  },
  doGetAddressFromLatLng:
    (lat, lng) =>
      ({ dispatch, apiGet, store }) => {
        const LATLNG = lng.concat(', ', lat);
        const uri = '/api/ArcGIS/reverseGeocode?' + new URLSearchParams({
          location: LATLNG,
          f: 'json',
          local: true,
        });
        apiGet(uri, (err, body) => {
          if (!err && body.status === 'Success') {
            const jsonObject = JSON.parse(body.data);
            if (jsonObject?.address?.City?.includes('District') || jsonObject?.address?.City?.includes('Township')) {
              console.warn('Local City cannot be determined. Using generic API call');
              const retryURI = '/api/ArcGIS/reverseGeocode?' + new URLSearchParams({
                location: LATLNG,
                f: 'json',
                local: false,
              });
              apiGet(retryURI, (err, body) => {
                if (!err && body.status === 'Success') {
                  const jsonObject = JSON.parse(body.data);
                  dispatch({
                    type: 'GEOCODING_ADDRESS_FROM_LATLNG',
                    payload: jsonObject
                  });
                }
              });
            }
            else {
              dispatch({
                type: 'GEOCODING_ADDRESS_FROM_LATLNG',
                payload: jsonObject
              });
            }
          }
          else {
            store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An unknown error has occurred', status: body?.status ?? err?.status ?? 'Unknown Error' });
          }
        });
      },
  doFindAddressCandidates:
    (address, magicKey) =>
      async ({ dispatch, store }) => {

        const params = new URLSearchParams({
          singleLine: address,
          apikey: import.meta.env.VITE_ESRI_GEOLOCATION_API_KEY,
          outFields: '*',
          f: 'json',
        });

        if (magicKey) {
          params.append('magicKey', magicKey);
        }

        const uri = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?' + params;

        try {
          const response = await fetch(uri, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = response?.json()?.then((data) => {
            dispatch({
              type: 'GEOCODING_ADDRESS_FROM_ADDRESS_NAME',
              payload: data
            });
            return data;
          }
          );

          return Promise.resolve(data);

        } catch (error) {
          store.doSecondaryModalOpen(statusModal, { msg: error.message });
          throw error;
        }

      },
  doResetAddressCandidatesResults:
    () =>
      ({ dispatch }) => {
        dispatch({
          type: 'RESET_ADDRESS_CANDIDATES',
        });
      },
  doGetAddressSuggestions: (text, location = '-103.46,44.58', usOnly) => async ({ dispatch, store }) => {
    const uri = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?' + new URLSearchParams({
      text: text,
      location: location,
      category: 'Address',
      countryCode: usOnly ? 'USA,ASM,PRI,UMI,VIR,GUM,FSM' : undefined,
      apikey: import.meta.env.VITE_ESRI_GEOLOCATION_API_KEY,
      f: 'json',
    });

    try {
      const response = await fetch(uri, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer'

      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      response.json().then((data) => {
        dispatch({
          type: 'GEOCODING_ADDRESS_SUGGESTIONS_FROM_TEXT',
          payload: data
        });
      }

      );

    } catch (error) {
      store.doSecondaryModalOpen(statusModal, { msg: error.message });
      throw error;
    }
  },
  doResetGeocodingResults:
    () =>
      ({ dispatch }) => {
        dispatch({
          type: 'RESET_GEOCODING_RESULTS',
        });
      },
  doResetAddressSuggestionResults:
    () =>
      ({ dispatch }) => {
        dispatch({
          type: 'RESET_ADDRESS_SUGGESTIONS',
        });
      },
  doGetGeometryData:
    (gdbData, onlyFirst, toastID) =>
      ({ dispatch, apiPostFile, store }) => {

        const getFirstValidGeometry = (obj) => {

          const extractGeometry = (obj) => {
            if (obj.geometry && ['Point', 'MultiPoint', 'Polygon', 'MultiPolygon', 'LineString', 'MultiLineString'].includes(obj.geometry.type)) {
              return obj.geometry;
            } else if (obj.features) {
              for (let feature of obj.features) {
                const geometry = extractGeometry(feature);
                if (geometry) return geometry;
              }
            }
            return null;
          };

          const geometry = extractGeometry(obj);
          return geometry ? turf.flip(geometry) : null;
        };

        const uri = '/api/ArcGIS/getGeometryData';

        const formData = new FormData();
        formData.append('data', gdbData);
        formData.append('firstPolygonOnly', onlyFirst);

        apiPostFile(uri, formData, (err, body) => {
          if (!err && (body?.status === 'Success' || body?.status === 'Success With Warning')) {
            if (body?.status === 'Success With Warning') {
              const parsedValidationMsg = body?.msg?.split(',');
              const validationMsg = <ul>
                {parsedValidationMsg?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>;
              store.doSecondaryModalOpen(statusModal, { msg: parsedValidationMsg?.length > 1 ? validationMsg : body?.msg ?? 'The GDB file uploaded with some warnings.', status: 'GDB Upload Warning' });
            }
            if (onlyFirst) {
              dispatch({
                type: 'PARSING_PROJECT_GEOMETRIES_FROM_GDB',
                payload: getFirstValidGeometry(body?.data)
              });
              store.doUpdateRequestLocation({ geometry: getFirstValidGeometry(body?.data) });
            }
            else {
              dispatch({
                type: 'PARSING_RESOURCE_GEOMETRIES_FROM_GDB',
                payload: turf.flip(body?.data)
              });
            }
            tSuccess(toastID, 'GDB File Successfully Uploaded!');
          }
          else {
            tError(toastID, 'GDB Upload Failed!');
            // Parse body message for GDB validation errors
            const parsedValidationMsg = body?.msg?.split(',');
            const validationMsg = <ul>
              {parsedValidationMsg?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>;
            store.doSecondaryModalOpen(statusModal, { msg: parsedValidationMsg?.length > 1 ? validationMsg : body?.msg ?? 'An error occurred while uploading the GDB file. Please try again.', status: 'GDB Upload Failed' });
          }
        });
      },
  selectReverseGeocodeResults: (state) => state.geocodingapi.reversegeocode,
  selectAddressCandidateResults: (state) => state.geocodingapi.addresscandidates,
  selectAddressSuggestionResults: (state) => state.geocodingapi.suggest,
  selectGDBProjectResults: (state) => state.geocodingapi.gdbproject,
  selectGDBResourcesResults: (state) => state.geocodingapi.gdbresources,
};
export default geocodingAPIBundle;