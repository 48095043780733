import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';

import { ModalContent, ModalFooter } from '@components/modal';
import SelectInput from '@components/select/Select';
import TextInput from '@components/text-input/TextInput';
import DragInput from '@components/drag-input/dragInput';
import ValidationCard from '../Form/ValidationCard';
import DatePicker from '@components/date-picker/DatePicker';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { jddoctypes, violationComplaintDocTypes, preappdoctypes, permitdoctypes } from '@pages/Forms/input-forms/_helper';
import { tagValueRegex } from '@src/utils/regex';
import Card from '@components/card/card';
import ErrorSummary from '@components/error-summary/ErrorSummary';


const AddSupportingDocumentModal = connect(
  'doModalClose',
  'doUpdateSelectedFiles',
  'selectSelectedRequest',
  'selectSelectedFiles',
  'selectTotalFileSize',
  'selectMaxTotalFileSize',
  'selectMaxFileCount',
  ({
    doModalClose,
    doUpdateSelectedFiles,
    selectedRequest,
    selectedFiles,
    totalFileSize,
    maxTotalFileSize,
    maxFileCount,
    componentID,
  }) => {
    const defaultValues = {
      section: 'Supporting Documents',
      fileName: '',
      componentID: componentID
    };
    const schema = yup.object().shape({
      doctype: yup.string().required('Please select an option'),
      doccreateddate: yup.string().required('Please select a date'),
      doclabel: yup.string().required('Field is required').matches(tagValueRegex, 'Value can only contain alphanumeric characters, whitespace, and the following special characters: _, ., /, =, +, -, :, @').max(256, 'Exceeds character limit'),
      doccitation: yup.string().required('Field is required').matches(tagValueRegex, 'Value can only contain alphanumeric characters, whitespace, and the following special characters: _, ., /, =, +, -, :, @').max(256, 'Exceeds character limit'),
    });

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors, isValid }, setValue, getValues, trigger } = methods;

    const [stagedFiles, setStagedFiles] = useState([]);
    const [isOverTotalFileLimit, setIsOverTotalFileLimit] = useState(false);
    const [isOverFileCountLimit, setIsOverFileCountLimit] = useState(false);

    const dateNow = new Date().toISOString().slice(0, 10);

    const setDocTypes = () => {
      switch (selectedRequest) {
        case '1':
          return jddoctypes;
        case '2':
          return jddoctypes;
        case '3':
          return permitdoctypes;
        case '4':
          return permitdoctypes;
        case '5':
          return preappdoctypes;
        case '6':
          return violationComplaintDocTypes;
        default:
          return [];
      }
    };

    const saveData = () => {
      if (isValid && stagedFiles?.length > 0 && !isOverTotalFileLimit) {
        stagedFiles.forEach(item => {
          setValue('file', item);
          setValue('fileName', item.name);
          doUpdateSelectedFiles(getValues());
        });
        doModalClose();
      } else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    useEffect(() => {
      const stageSizesArr = stagedFiles.map(item => item.size);
      const sum = stageSizesArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setIsOverTotalFileLimit(((isNaN(totalFileSize) ? 0 : totalFileSize) + sum > maxTotalFileSize) ? true : false);
      setIsOverFileCountLimit((selectedFiles.length + stagedFiles.length > maxFileCount) ? true : false);
    }, [selectedFiles, stagedFiles, maxFileCount, maxTotalFileSize, totalFileSize]);

    return (
      <ModalContent title='Add Supporting Document Information'>
        <FormProvider {...methods}>
          {errors && <ErrorSummary errors={errors} modalID='addSupportingDocumentsModal' type='modal' />}
          <section className='modal-body' id='addSupportingDocumentsModal'>
            <div className='container-fluid'>
              <div className='w-100 pb-3' id='fieldheader'>
                <Card>
                  <Card.Body>
                    <p>For Document Label and Information Source/Citation, please ensure the values only include letters, digits, white-space, and the following special characters: <code>'_', '.', '/', '=', '+', '-', ':', '@'. </code></p>
                    <p>For uploaded files, please ensure the file name only includes letters, digits, white-space, and the following special characters: <code>'-', '!', '_', '.', ''', '(', ')', '&', '$', '@', '=', ';', '+', ','. </code></p>
                    <p>If any characters outside of these mentioned are included in the file name or Document Label fields you will receive an error message.  To resolve the error, remove the uploaded documents with the "Clear Staged Documents" button, and then reupload the files again ensuring the file name and document labels only include the aforementioned characters.</p>
                    <p><b>Individual files cannot exceed 100MB, and in total cannot exceed 500MB per save.</b></p>
                  </Card.Body>
                </Card>
              </div>
              <div className='row mt-2'>
                <div className='col-6'>
                  <SelectInput name='doctype' label='Document Type' required>
                    {setDocTypes().map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
                  </SelectInput>
                </div>
                <div className='col-6'>
                  <DatePicker name='doccreateddate' max={dateNow} label='Document Created Date' required />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextInput name='doclabel' label='Document Label' placeholder='Document Label' required />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextInput name='doccitation' label='Information Source/Citation' placeholder='Information Source/Citation' required />
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col'>
                  <DragInput
                    name='file'
                    label='Documents'
                    onChange={setStagedFiles}
                    isMulti={true}
                    tooltip={<><b>Note:</b> To select multiple files, while pressing down the control key (Ctrl) click on files that you want to select. Continue to press down the control key until you select all the files you want.</>}
                    required
                  />
                  {isOverTotalFileLimit && <ValidationCard message='Only 500MB of files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_size_error' />}
                  {isOverFileCountLimit && <ValidationCard message='Only 50 files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_count_error' />}
                </div>
              </div>
            </div>
          </section>
        </FormProvider>
        <ModalFooter
          showCancelButton
          saveText='Add'
          onSave={saveData}
          customClosingLogic
        />
      </ModalContent>
    );
  });

export default AddSupportingDocumentModal;
