import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import Icon from '@components/icon/Icon';
import { mdiUpload, mdiDownload, mdiDelete, mdiPaperclip } from '@mdi/js';

import FileDetails from './fileDetails';
import Tooltip from '../tooltip/tooltip';
import ValidationCard from '../../app-pages/Forms/components/Form/ValidationCard';

import { filenameRegex } from '../../utils/regex';

import './dragInput.scss';
import '../../styles/index.scss';

const DragInput = connect(
  'selectCombinedRequestFiles',
  'selectMaxIndFileSize',
  ({
    combinedRequestFiles,
    maxIndFileSize,
    text = 'Drag and drop your files here, or click to select files',
    name,
    label,
    accept,
    required,
    onChange,
    validations,
    tooltip,
    isMulti = false,
    isDisabled = false,
    value,
    doDownloadFile = () => { },
    doDeleteFile = () => { },
    isReadOnly
  }) => {
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const fileValidator = file => {
      // Parse out file extension in the filename
      const filename = file?.name?.replace(/\.[^/.]+$/, '');

      if (file?.size === 0) {
        return {
          code: 'empty-file',
          message: 'File is empty. Please select another file.'
        };
      }

      if (file?.size > maxIndFileSize) { // (binary bytes) 100MB
        return {
          code: 'file-size-too-large',
          message: 'File is too large (> 100MB). Please compress file, parse into separate files, or select another file.'
        };
      }

      if (combinedRequestFiles?.filter(item => item.fileName === file.name).length > 0) {
        return {
          code: 'file-exists',
          message: 'This file name has already been used. Please rename file or select another.'
        };
      }

      if (filename?.length > 1024) { // 1024 bytes
        return {
          code: 'filename-too-long',
          message: 'File name is exceeds character limit (> 1024 characters). Please shorten the file name.'
        };
      }

      if (!filename?.match(filenameRegex)) { // check if there are invalid characters
        return {
          code: 'filename-invalid-characters',
          message: 'File name contains invalid characters. Please rename file. File name can only contain alphanumeric characters, whitespace, and the following special characters: !, -, _, ., (, ), &, $, @, =, ;, +, ,'
        };
      }

      return null;
    };

    const { register } = useFormContext();
    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps
    } = useDropzone({
      disabled: isDisabled,
      accept: accept,
      validator: fileValidator,
      onDragEnter: () => setIsDraggingOver(true),
      onDragLeave: () => setIsDraggingOver(false),
      onDrop: () => setIsDraggingOver(false),
    });

    const files = (file, index) => (
      <div key={index} className='mb-1' style={{ padding: '10px' }}>
        <Icon focusable={false} path={mdiPaperclip} size={'20px'} />
        <FileDetails file={file} />
        {file.key && <Icon aria-label='download file' path={mdiDownload} className='download-icon' size={'20px'} onClick={doDownloadFile} />}
        {(file.key && !isReadOnly) && <Icon aria-label='delete file' path={mdiDelete} className='delete-icon' size={'20px'} onClick={doDeleteFile} />}
      </div>
    );

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      errors.map(e => (
        <ValidationCard key={e.code} message={<><b>{file.path}</b>: {e.message}</>} id={`${name}_error`} />
      ))
    ));


    const listFiles = () => {
      if (value?.length > 0) {
        return files(value[0], 1);
      } else if (acceptedFiles?.length > 0) {
        return isMulti === true ? acceptedFiles.map((item, i) => files(item, i)) : files(acceptedFiles[0], 1);
      } else {
        return (
          <p style={{ padding: '10px' }}>
            <span><Icon focusable={false} path={mdiUpload} size={'24px'} /></span> {text} {required ? <span className='asterisk-color'>*</span> : ''}
          </p>
        );
      }
    };

    useEffect(() => {
      onChange(acceptedFiles);
    }, [acceptedFiles, onChange]);

    return (
      <>
        <label
          className={'mr-2 mb-1 w-100'}
          htmlFor={name}>
          {label}
          {tooltip && <>
            <Tooltip name={name} content={tooltip} iconStyle={{ marginLeft: '5px' }} />
          </>}
        </label>
        <div className={`drag-input ${isDraggingOver && 'active-dragover'}`} id={name}>
          <div {...getRootProps({ className: `dropzone ${acceptedFiles?.length > 0 && 'active-dragover'}` })}>
            <input
              type='file'
              id={`${name}_hidden`}
              name={name}
              required={required}
              {...register(name, { onChange, ...validations })}
              aria-invalid={(acceptedFiles.length === 0) ? 'true' : 'false'}
              aria-required={required}
              {...getInputProps()}
              tabIndex={0}
            />
            {listFiles()}
          </div>
          {fileRejectionItems}
        </div>
      </>
    );
  });

export default DragInput;
