import { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';

import { ModalContent, ModalFooter } from '@components/modal';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ContactTypes } from '@src/utils/enums';
import { emailRegex, phoneRegex, usPhoneRegex, phoneExtRegex, countryCodeRegex, cityRegex, zipCodeRegex } from '@src/utils/regex';
import { isNullUndefinedOrEmpty } from '@src/utils/helpers';
import ErrorSummary from '@components/error-summary/ErrorSummary';

const schema = yup.object().shape({
  firstName: yup.string().required('Field is required'),
  lastName: yup.string().required('Field is required'),
  city: yup.string().matches(cityRegex, { message: 'Field is invalid', excludeEmptyString: true }).nullable(),
  zipcode: yup.string().nullable().when('country', { is: 'US', then: () => yup.string().matches(zipCodeRegex, 'Field is invalid') }),
  country: yup.string().nullable(),
  phoneOneType: yup.string().nullable().test('phone-one-type-optional', 'Please select an option', function (value) {
    const { phoneOne, phoneOneCountryCode } = this.parent;
    return isNullUndefinedOrEmpty(phoneOne) && isNullUndefinedOrEmpty(phoneOneCountryCode) && isNullUndefinedOrEmpty(value) ? true : !isNullUndefinedOrEmpty(value);
  }),
  phoneOneCountryCode: yup.string().nullable().test('phone-one-country-code-optional', 'Field is invalid', function (value) {
    const { phoneOne, phoneOneType } = this.parent;
    return isNullUndefinedOrEmpty(phoneOne) && isNullUndefinedOrEmpty(phoneOneType) && isNullUndefinedOrEmpty(value) ? true : countryCodeRegex.test(value);
  }),
  phoneOne: yup.string().nullable().test('phone-one-optional', 'Field is invalid', function (value) {
    const { phoneOneCountryCode, phoneOneType } = this.parent;
    if (isNullUndefinedOrEmpty(phoneOneCountryCode) && isNullUndefinedOrEmpty(phoneOneType) && isNullUndefinedOrEmpty(value))
      return true;
    else
      return phoneOneCountryCode === '1' ? usPhoneRegex.test(value) : phoneRegex.test(value);
  }),
  phoneOneExtension: yup.string().nullable().when('phoneOneType', { is: 'Business', then: () => yup.string().matches(phoneExtRegex, 'Field is invalid') }),
  showPhoneTwo: yup.boolean().nullable(),
  phoneTwoType: yup.string().nullable().when('showPhoneTwo', {
    is: true, then: () =>
      yup.string().test('phone-two-type-optional', 'Please select an option', function (value) {
        const { phoneTwo, phoneTwoCountryCode } = this.parent;
        return isNullUndefinedOrEmpty(phoneTwo) && isNullUndefinedOrEmpty(phoneTwoCountryCode) && isNullUndefinedOrEmpty(value) ? true : !isNullUndefinedOrEmpty(value);
      })
  }),
  phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', {
    is: true, then: () =>
      yup.string().test('phone-two-country-code-optional', 'Field is invalid', function (value) {
        const { phoneTwo, phoneTwoType } = this.parent;
        return isNullUndefinedOrEmpty(phoneTwo) && isNullUndefinedOrEmpty(phoneTwoType) && isNullUndefinedOrEmpty(value) ? true : countryCodeRegex.test(value);
      })
  }),
  phoneTwo: yup.string().nullable().when('showPhoneTwo', {
    is: true, then: () =>
      yup.string().test('phone-two-optional', 'Field is invalid', function (value) {
        const { phoneTwoCountryCode, phoneTwoType } = this.parent;
        if (isNullUndefinedOrEmpty(phoneTwoCountryCode) && isNullUndefinedOrEmpty(phoneTwoType) && isNullUndefinedOrEmpty(value))
          return true;
        else
          return phoneTwoCountryCode === '1' ? usPhoneRegex.test(value) : phoneRegex.test(value);
      })
  }),
  phoneTwoExtension: yup.string().nullable().when(['showPhoneTwo', 'phoneTwoType'], { is: (show, type) => show && type === 'Business', then: () => yup.string().nullable().matches(phoneExtRegex, { message: 'Field is invalid', excludeEmptyString: true }) }),
  faxPhone: yup.string().matches(phoneRegex, { message: 'Field is invalid', excludeEmptyString: true }).nullable(),
  emailAddress: yup.string().nullable().when('emailAddress', { is: (value) => value?.length, then: () => yup.string().matches(emailRegex, 'Field is invalid') }),
}, [
  ['emailAddress', 'emailAddress'],
  ['zipcode', 'zipcode']
]);

const requiredFields = [
  'First Name',
  'Last Name'
];

const AddOtherPersonsModal = connect(
  'doModalClose',
  ({
    doModalClose,
    addRowData,
    edit,
    data,
    setRowData,
    rowData,
    id,
    isReadOnly,
  }) => {
    const defaultValues = edit ? {
      contactType: ContactTypes.Witness,
      address: data?.address ?? null,
      addressTwo: data?.addressTwo ?? null,
      city: data?.city ?? null,
      company: data?.company ?? null,
      emailAddress: data?.emailAddress ?? null,
      faxPhone: data?.faxPhone ?? null,
      firstName: data?.firstName ?? null,
      lastName: data?.lastName ?? null,
      middleName: data?.middleName ?? null,
      phoneOne: data?.phoneOne ?? null,
      phoneOneType: data?.phoneOneType ?? null,
      phoneOneCountryCode: data?.phoneOneCountryCode ?? null,
      phoneOneExtension: data?.phoneOneExtension ?? null,
      phoneTwoCountryCode: data?.phoneTwoCountryCode ?? null,
      phoneTwo: data?.phoneTwo ?? null,
      phoneTwoType: data?.phoneTwoType ?? null,
      phoneTwoExtension: data?.phoneTwoExtension ?? null,
      salutation: data?.salutation ?? null,
      state: data?.state ?? null,
      zipcode: data?.zipcode ?? null,
      country: data?.country ?? null,
    } : { contactType: ContactTypes.Witness, country: null };

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { errors, isValid }, setFocus, trigger, getValues } = methods;

    const onSave = () => {
      if (isValid) {
        const contactFields = getValues();
        const contactFieldsWithIDs = {
          ...contactFields,
          contactID: data?.contactID ?? undefined,
          requestID: data?.requestID ?? undefined,
          version: data?.version ?? undefined,
          contactType: ContactTypes.Witness,
          createdBy: data?.createdBy ?? undefined,
          phoneOne: contactFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
          phoneTwo: contactFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
        };
        if (edit) {
          // if contact data obj already exists in database, identify contactID, otherwise use row index
          let dataArr = [...rowData];
          const index = data.contactID ? dataArr.findIndex(el => el.contactID === id) : id;
          dataArr[index] = contactFieldsWithIDs;
          setRowData(dataArr);
        } else {
          addRowData(contactFieldsWithIDs, setRowData);
        }
        doModalClose();
      }
      else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    useEffect(() => {
      if (errors?.[Object.keys(errors)[0]]?.['ref']?.focus) {
        errors?.[Object.keys(errors)[0]]?.['ref']?.focus();
      }

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <FormProvider {...methods}>
        <ModalContent hasCloseButton={isReadOnly} title='Add Contact - Additional Contacts or Witnesses'>
          {errors && <ErrorSummary errors={errors} modalID='otherContactsModal' type='modal' />}
          <section className='modal-body' id='otherContactsModal'>
            <div className='container-fluid'>
              <ContactFields label='Additional Contact or Witness' requiredFields={requiredFields} isReadOnly={isReadOnly} />
            </div>
          </section>
          <ModalFooter
            showCancelButton={!isReadOnly}
            showSaveButton={!isReadOnly}
            customClosingLogic
            saveText={edit ? 'Apply Changes' : 'Add'}
            onSave={onSave}
          />
        </ModalContent>
      </FormProvider>
    );
  }
);

export default AddOtherPersonsModal;
