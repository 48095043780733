import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import Spinner from '../../../app-components/spinner/spinner';
import { Alert, Button } from '@trussworks/react-uswds';

const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const defaultBankILFOptions = [
  { label: 'Not Listed', value: 'Not Listed', id: 999999999 }
];
const unavailableOption = [
  { label: 'RIBITS Unavailable', value: 'RIBITS Unavailable', id: 999999991 }
];
const MITIGATION_TYPES = {
  MITIGATION_BANK: 'Mitigation Bank',
  IN_LIEU_FEE: 'In-lieu Fee',
};

const BankILFTableCell = connect(
  'doGetBankSiteList',
  'doGetILFProgramList',
  'selectBankSiteList',
  'selectILFProgramList',
  'selectRequestFormData',
  'doGetMitigationBanksOrILF',
  'selectRIBITSAPIError',
  ({
    doGetBankSiteList,
    doGetILFProgramList,
    bankSiteList,
    iLFProgramList,
    getValue,
    row,
    column,
    table,
    cell,
    requestFormData,
    doGetMitigationBanksOrILF,
    rIBITSAPIError
  }) => {
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    const [bankILFOptions, setBankILFOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ribitsError, setRibitsError] = useState(null);
    const rowMitigationType = useMemo(() => row.getValue('mitigationType'), [row]);
    const debouncedUpdateRef = useRef();
    const observerRef = useRef();
    const districtName = requestFormData?.location?.district?.replace(/ District$/i, '') || '';
    const currentEnv = import.meta.env.VITE_ENVIRONMENT;
    const isDev = currentEnv === 'development';

    useEffect(() => {
      debouncedUpdateRef.current = debounce((newValue) => {
        if (tableMeta?.updateData) {
          tableMeta.updateData(row.index, column.id, newValue);
        }
      }, 500);
    }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

    const updateValue = useCallback((newValue) => {
      debouncedUpdateRef.current(newValue);
    }, []);

    const handleMitigationBanksOrILF = useCallback(async () => {
      setLoading(true);
      setRibitsError(null);
      try {
        const result = await doGetMitigationBanksOrILF(rowMitigationType);
        setBankILFOptions(result);
      } catch (error) {
        setBankILFOptions(unavailableOption);
        setRibitsError('RIBITS Service is Unavailable.');
      } finally {
        setLoading(false);
      }
    }, [doGetMitigationBanksOrILF, rowMitigationType]);

    const handleIntersection = useCallback((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (isDev) {
            if (rowMitigationType === MITIGATION_TYPES.MITIGATION_BANK) {
              doGetBankSiteList('district', districtName);
            } else if (rowMitigationType === MITIGATION_TYPES.IN_LIEU_FEE) {
              doGetILFProgramList('district', districtName);
            }
          } else {
            handleMitigationBanksOrILF();
          }
          if (observerRef.current) {
            observerRef.current.unobserve(entry.target);
          }
        }
      });
    }, [isDev, rowMitigationType, districtName, observerRef, doGetBankSiteList, doGetILFProgramList, handleMitigationBanksOrILF]);

    useEffect(() => {
      const observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      });
      observerRef.current = observer;
      const element = document.getElementById(cell.id);
      if (element) {
        observerRef.current.observe(element);
      }
      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }, [cell.id, handleIntersection]);

    const createUniqueOptions = (items, nameKey, idKey) => {
      const uniqueNames = new Set();
      const options = [...defaultBankILFOptions];
      items.forEach(item => {
        const name = item[nameKey];
        if (!uniqueNames.has(name)) {
          uniqueNames.add(name);
          options.push({
            value: name,
            label: name,
            id: item[idKey]
          });
        }
      }); 
      return options;
    };

    useEffect(() => {
      const fetchOptions = async () => {
        setLoading(true);
        setRibitsError(null);
        try {
          if (isDev) {
            if (rowMitigationType === MITIGATION_TYPES.MITIGATION_BANK) {
              setBankILFOptions(createUniqueOptions(bankSiteList, 'NAME', 'BANK_ID'));
            } else if (rowMitigationType === MITIGATION_TYPES.IN_LIEU_FEE) {
              setBankILFOptions(createUniqueOptions(iLFProgramList, 'PROGRAM_NAME', 'PROGRAM_ID'));
            }
          } else {
            if (rowMitigationType) {
              const bankPrograms = createUniqueOptions(bankSiteList, 'name', 'id');
              setBankILFOptions(bankPrograms);
            } else {
              setBankILFOptions(defaultBankILFOptions);
            }
          }
        } catch (error) {
          setRibitsError('RIBITS Service is Unavailable.');
          setBankILFOptions(unavailableOption);
        } finally {
          setLoading(false);
        }
      };
      fetchOptions();
    }, [isDev, rowMitigationType, bankSiteList, iLFProgramList, setLoading, setRibitsError, setBankILFOptions]);

    useEffect(() => {
      if (!isDev) return;
      const timer = setTimeout(() => {
        if (loading) {
          setLoading(false);
          setRibitsError('RIBITS is unavailable.');
        }
      }, 10000);
      return () => clearTimeout(timer);
    }, [loading, isDev]);

    const handleChange = (e) => {
      const val = e?.target?.value;
      setValue(val);
      updateValue(val);
      // Set bankOrILFNumber
      const mappedBankID = bankILFOptions?.filter(item => item.label === val)?.[0]?.id;
      tableMeta?.updateData(row.index, 'bankOrILFNumber', mappedBankID);
    };

    return (
      <div id={cell.id} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {ribitsError && (
          <span><Alert slim type='error'>{'RIBITS is unavailable at this time.  Select "RIBITS Unavailable" in the Bank/ILF Name dropdown and provide any known Bank/ILF Name details in the Description of Avoidance, Minimization and Compensation field.' } <div style={{ marginTop: '1rem' }}><Button type='button' className='mr-2 text-sm py-2 px-2' onClick={handleMitigationBanksOrILF}>Retry</Button></div></Alert></span>
        )}
        {loading && !ribitsError ? (
          <Spinner />
        ) : (
          <select
            id={cell.id}
            onChange={handleChange}
            value={value ?? ''}
            required={columnMeta?.required}
            disabled={columnMeta?.readOnly}
            style={{
              width: '100%',
              marginTop: '4px',
              cursor: columnMeta?.readOnly ? 'not-allowed' : 'auto'
            }}
          >
            <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
            {bankILFOptions?.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        )}
      </div>
    );
  });
export default BankILFTableCell;