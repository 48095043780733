import Card from '@components/card';
import ExternalLink from '@components/external-link/ExternalLink';
import { Row } from '@pages/_shared/helper';
import Tooltip from '@components/tooltip/tooltip';

import freshWatersImg from '@styles/images/RRS Graphic V2_041423.png';
import tidalWatersImg from '@styles/images/RRS Graphic V3_042623.png';

import '@styles/_accordion.scss';

export const jDTooltipContent = {
  CWATooltip: `Section 404 of the Clean Water Act (33 U.S.C. 1344) authorizes the Secretary of the Army,
  acting through the Chief of Engineers, to issue permits, after notice and opportunity for public hearing, for the discharge of dredged or fill material into the waters of the United States at specified disposal
  sites. (See 33 CFR part 323.) The selection and use of disposal sites will be in accordance with guidelines developed by the Administrator of EPA in conjunction with the Secretary of the Army and published in
  40 CFR part 230. If these guidelines prohibit the selection or use of a disposal site, the Chief of Engineers shall consider the economic impact on navigation and anchorage of such a prohibition in reaching his
  decision. Furthermore, the Administrator can deny, prohibit, restrict or withdraw the use of any defined area as a disposal site whenever he determines, after notice and opportunity for public hearing and after
  consultation with the Secretary of the Army, that the discharge of such materials into such areas will have an unacceptable adverse effect on municipal water supplies, shellfish beds and fishery areas, wildlife, 
  or recreational areas. (See 40 CFR part 230).`,
  RHATooltip: `Section 10 of the Rivers and Harbors Act approved March 3, 1899, (33 U.S.C. 403),
  prohibits the unauthorized obstruction or alteration of any navigable water of the United States. The construction of any structure in or over any navigable water of the United States, the excavating
  from or depositing of material in such waters, or the accomplishment of any other work affecting the course, location, condition, or capacity of such waters is unlawful unless the work has been recommended
  by the Chief of Engineers and authorized by the Secretary of the Army. The instrument of authorization is designated a permit. The authority of the Secretary of the Army to prevent obstructions to navigation
  in navigable waters of the United States was extended to artificial islands, installations, and other devices located on the seabed, to the seaward limit of the outer continental shelf, by section 4(f) of
  the Outer Continental Shelf Lands Act of 1953 as amended (43 U.S.C. 1333(e)).`,
  tidalWatersTooltip: `Tidal waters are those waters that rise and fall in a predictable and measurable
  rhythm or cycle due to the gravitational pulls of the moon and sun. Tidal waters end where the rise and fall of the water surface can no longer be practically measured in a predictable rhythm due to masking by hydrologic,
  wind, or other effects.`,
  baselineTooltip: 'Generally, where the shore directly contacts the open sea, the line on the shore reached by the ordinary low tides comprises the baseline from which the distance of three geographic miles is measured.',
  waterLineTooltip: `The mean high water (MHW) line, also known as the mean high water mark, refers to the average height of the
  highest tide recorded over a specific period of time, typically based on a 19-year tidal cycle. It represents the average shoreline level reached by high tides under normal conditions, excluding exceptional tidal events
  or storm surges.`,
  tideLineTooltip: `High tide line means the line of intersection of the land with the water's surface at the maximum height reached by a rising tide. The high tide line may be determined, in the absence of
  actual data, by a line of oil or scum along shore objects, a more or less continuous deposit of fine shell or debris on the foreshore or berm, other physical markings or characteristics, vegetation lines, tidal gages, or
  other suitable means that delineate the general height reached by a rising tide. The line encompasses spring high tides and other high tides that occur with periodic frequency but does not include storm surges in which
  there is a departure from the normal or predicted reach of the tide due to the piling up of water against a coast by strong winds such as those accompanying a hurricane or other intense storm.`,
  wetlandTooltip: `Wetlands means those areas that are inundated or saturated by surface or ground water at a frequency and duration sufficient to support, and that under normal circumstances do support, a prevalence
  of vegetation typically adapted for life in saturated soil conditions. Wetlands generally include swamps, marshes, bogs, and similar areas. The USACE has developed wetland delineations manuals that aid the USACE in identifying wetlands
  (see delineation section below).`,
  watermarkTooltip: `Ordinary high water mark (OHWM) means that line on the shore established by the fluctuations of water and
  indicated by physical characteristics such as clear, natural line impressed on the bank, shelving, changes in the character of soil, destruction of terrestrial vegetation, the presence of litter and debris, or other appropriate
  means that consider the characteristics of the surrounding areas. The USACE has developed OHWM delineation manuals that aid the USACE in identifying the OHWM (see delineation section below).`,
  adjacentTooltip: 'Adjacent means having a continuous surface connection.',
};

export const wotusAccordion = [
  {
    title: <>Expand to Learn More About <span className='text-bold'>Navigable Waters of the United States</span></>,
    content: <ul>
      <li>Are regulated by the USACE pursuant to Section 10 of the Rivers and Harbors Act<Tooltip name='rivers-harbors-act' content={jDTooltipContent.RHATooltip} />.</li>
      <li>These waters are subject to the ebb and flow of the tide and/or are presently used, or have been used in the past, or may be susceptible for use to transport interstate or foreign commerce.</li>
      <li>A more detailed definition of these waters is provided at <ExternalLink href='https://www.ecfr.gov/current/title-33/chapter-II/part-329' title='33 CFR 329' content='33 CFR 329' />.</li>
    </ul>,
    id: '1',
    headingLevel: 'h3'
  }, {
    title: <>Expand to Learn More About <span className='text-bold'>Waters of the United States</span></>,
    content: <ul>
      <li>Are regulated by the USACE pursuant to Section 404 of the Clean Water Act<Tooltip name='clean-water-act' content={jDTooltipContent.CWATooltip} />.</li>
      <li>These waters include navigable waterways and various components of the surface water tributary system, including lakes and ponds, extending up to small streams, and wetlands that are adjacent to such waters.</li>
      <li>A more detailed definition of these waters is provided at <ExternalLink href='https://www.ecfr.gov/current/title-33/chapter-II/part-328' title='33 CFR 328' content='33 CFR 328' />.</li>
    </ul>,
    id: '2',
    headingLevel: 'h3'
  }
];

export const geoExtentAccordion = [
  {
    title: <>Expand to Learn More About <span className='text-bold'>Tidal Waters of the United States & Territorial Seas</span></>,
    content: <>
      <ul>
        <li>Under Section 10 of the Rivers and Harbors Act, the extent of USACE jurisdiction in <span className='text-bold'>tidal waters</span><Tooltip name='tidal-water' content={jDTooltipContent.tidalWatersTooltip} /> extends shoreward to the <span className='text-bold'>mean high water line</span><Tooltip name='waterline' content={jDTooltipContent.waterLineTooltip} />.</li>
        <li>Under Section 404 of the Clean Water Act, the extent of USACE jurisdiction in <span className='text-bold'>tidal waters</span><Tooltip name='tidal-water-2' content={jDTooltipContent.tidalWatersTooltip} /> extends to the <span className='text-bold'>high tide line</span><Tooltip name='tideline' content={jDTooltipContent.tideLineTooltip} />. When adjacent wetlands are present, the geographic limits extend to the delineated limits of the <span className='text-bold'>wetland</span><Tooltip name='wetland' content={jDTooltipContent.wetlandTooltip} />.</li>
        <li>The limit of jurisdiction in the territorial seas is measured from the <span className='text-bold'>baseline</span><Tooltip name='baseline' content={jDTooltipContent.baselineTooltip} /> in a seaward direction a distance of three nautical miles.</li>
      </ul>
      <Row>
        <Card className='image-container-accordion'>
          <Card.Body>
            <img src={tidalWatersImg} alt='Lateral limits of the USACE jurisdiction in tidal waters' className='w-100' />
            <p>This figure is a graphical depiction of the lateral limits of the USACE jurisdiction in tidal waters.</p>
          </Card.Body>
        </Card>
      </Row>
    </>,
    id: '3',
    headingLevel: 'h3'
  }, {
    title: <>Expand to Learn More About <span className='text-bold'>Non-Tidal Waters of the United States</span></>,
    content: <>
      <ul>
        <li>The limit of jurisdiction in non-tidal waters extends to the <span className='text-bold'>ordinary high water mark</span><Tooltip name='watermark' content={jDTooltipContent.watermarkTooltip} />.</li>
        <li>When adjacent wetlands are present, the jurisdiction extends beyond the <span className='text-bold'>ordinary high water mark</span><Tooltip name='watermark-2' content={jDTooltipContent.watermarkTooltip} /> to the delineated limit of the <span className='text-bold'>adjacent</span><Tooltip name='adjacent' content={jDTooltipContent.adjacentTooltip} /> wetlands.</li>
      </ul>
      <Row>
        <Card className='image-container-accordion'>
          <Card.Body>
            <img src={freshWatersImg} alt='Lateral limits of the USACE jurisdiction in fresh waters' className='w-100' />
            <p>This figure is a graphical depiction of the lateral limits of the USACE jurisdiction in fresh waters (non-tidal waters).</p>
          </Card.Body>
        </Card>
      </Row>
    </>,
    id: '4',
    headingLevel: 'h3'
  }
];

export const scientificMethodsAccordion = [
  {
    title: 'Expand to Learn More About the Scientific Methods Used to Identify Waters of the United States',
    content: <>
      <p className='text-bold'><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/reg_supp/' title='Wetland Delineation Manual and Regional Supplements' content='Wetland Delineation Manual and Regional Supplements' /></p>
      <p>Wetland delineation consists of standardized procedures that are used to determine if a wetland is present on a site and, if so, to establish its boundaries in the field.</p>
      <p className='text-bold'><ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/reg_supp/#data-sheets' title='Automated Wetland Determination Data Sheets (ADS)' content='Automated Wetland Determination Data Sheets (ADS)' /></p>
      <p>The Microsoft Excel-based ADS increases technical accuracy by reducing errors and increases efficiency by automatically populating many of the field indicators of wetland hydrology, hydrophytic vegetation,
        and hydric soils. These should be used to the extent possible instead of paper datasheets forms.</p>
      <p className='text-bold'><ExternalLink href='https://www.erdc.usace.army.mil/Media/Fact-Sheets/Fact-Sheet-Article-View/Article/486085/ordinary-high-water-mark-ohwm-research-development-and-training/' title='Ordinary High Water Mark Guidance (OHWM) and Manual' content='Ordinary High Water Mark Guidance (OHWM) and Manual' /></p>
      <p>The OHWM defines the lateral limits of non-tidal open waters including streams, rivers, lakes, ponds, etc.</p>
      <p className='text-bold'><ExternalLink href='https://wetland-plants.sec.usace.army.mil/' title='National Wetland Plant List (NWPL)' content='National Wetland Plant List (NWPL)' /></p>
      <p>The NWPL, along with its wetland plant species status, plays a crucial role in wetland delineation, wetland restoration and research. Additionally, it serves as a valuable resource for general botanical information about wetland plants. The NWPL encompasses all
        50 U.S. states, the District of Columbia, and the U.S. territories. Furthermore, to enhance understanding of plant distribution, the NWPL includes a complete geographic range for each taxon across North America north of Mexico. The wetland plant data is categorized
        into ten regions that align with the wetland delineation regions established by the U.S. Army Corps of Engineers.</p>
    </>,
    id: '5',
    headingLevel: 'h3'
  }
];

export const submitOptionsAccordion = [
  {
    title: 'Approved Jurisdictional Determination (AJD)',
    content: <p><span className='text-bold'></span> An AJD is defined in USACE regulations at 33 CFR 331.2. As explained in further detail in Regulatory <ExternalLink href='https://www.usace.army.mil/missions/civil-works/regulatory-program-and-permits/guidance-letters/' title='Guidance letters' content='Guidance Letter' /> 16-01, an AJD is used to indicate that the USACE has identified the presence or absence of wetlands and/or other aquatic resources on a site, including their accurate location(s) and boundaries, as well as their jurisdictional status. AJDs are valid for 5 years.</p>,
    id: '1',
    headingLevel: 'h3'
  }, {
    title: 'Preliminary Jurisdictional Determination (PJD)',
    content: <>
      <p><span className='text-bold'></span> A PJD is defined in USACE regulations at 33 CFR 331.2. As explained in further detail in Regulatory <ExternalLink href='https://www.usace.army.mil/missions/civil-works/regulatory-program-and-permits/guidance-letters/' title='Guidance letters' content='Guidance Letter' /> 16-01, a PJD is used to indicate that the USACE has identified the approximate location(s) and boundaries of wetlands and/or other aquatic resources on a site that are presumed to be subject to regulatory jurisdiction of the U.S. Army Corps of Engineers. Unlike an AJD, a PJD does not represent a definitive, official determination that there are, or that there are not, jurisdictional aquatic resources on a site, and does not have an expiration date.</p>
      <p className='mb-3'>You can view AJDs issued by the USACE by visiting the <ExternalLink href='https://permits.ops.usace.army.mil/orm-public#' title='Approved JD Archive' content='Approved JD Archive' />.</p>
    </>,
    id: '2',
    headingLevel: 'h3'
  },
];