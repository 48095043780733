import { ModalContent, ModalFooter } from '@components/modal';

const WithdrawModal = ({
  withdrawClick,
  data
}) => (
  <ModalContent title='Confirm Withdraw'>
    <section className='modal-body'>
      <div className='container-fluid'>
        <p className='mb-2'>Are you sure you want to withdraw this request?</p>
        <ul>
          <li><b>Request Type:</b> {data.requestType}</li>
          <li><b>Request Name:</b> {data.projectName}</li>
        </ul>
      </div>
    </section>
    <ModalFooter
      onSave={() => withdrawClick()}
      saveText='Yes'
      cancelText='No'
      onCancel={() => { }}
    />
  </ModalContent>
);

export default WithdrawModal;
