import { connect } from 'redux-bundler-react';
import { ModalContent, ModalFooter } from '@components/secondary-modal';
import SelectInput from '@components/select/Select';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

const schema = yup.object().shape({
  district: yup.string().required('Field is required'),
});

const DistrictModal = connect(
  'doSecondaryModalClose',
  'selectDistrictResults',
  ({
    doSecondaryModalClose,
    districtResults,
    setDistrict,
  }) => {

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { isValid }, watch } = methods;

    const district = watch('district');

    const saveDistrict = () => {
      setDistrict(district);
      doSecondaryModalClose();
    };

    return (
      <FormProvider {...methods}>
        <ModalContent title='Select a USACE Regulatory District'>
          <section className='modal-body'>
            <div className='container-fluid'>
              <p>This project spans multiple USACE Regulatory Districts, Please select the lead district from the dropdown below.</p>
              <SelectInput name='district' label='Lead District' options={districtResults ? districtResults : []} required />
            </div>
          </section>
          <ModalFooter
            showCancelButton
            saveText='Confirm District'
            saveIsDisabled={!isValid}
            customClosingLogic
            onSave={() => saveDistrict()}
          />
        </ModalContent >
      </FormProvider>);
  });

export default DistrictModal;
