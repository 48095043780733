import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Radio } from '@trussworks/react-uswds';

import '@styles/index.scss';

const RadioButton = ({
  id,
  label,
  name,
  onChange = () => {},
  onBlur = () => {},
  value,
  ...customProps
}) => {
  const { register } = useFormContext();
  const {ref: radioRef , ...rest } = register(name, {onBlur, onChange });

  return (
    <div className='margin-bottom-1'>
      <Radio
        inputRef={radioRef}
        id={id ?? value}
        label={label}
        name={name} 
        value={value}
        {...customProps}
        {...rest}
      />
    </div>
  );
};

export default RadioButton;