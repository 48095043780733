import Card from '@components/card/index';
import Icon from '@components/icon/Icon';
import { mdiArrowDownCircleOutline, mdiArrowUpCircleOutline } from '@mdi/js';

import './SectionButton.scss';

const SectionButton = (props) => {
  let buttonIcon = <></>;
  let buttonText = '';
  let navSection = <p>{props.section}</p>;

  switch (props.location) {
    case 'bottom':
      buttonIcon = (
        <Icon focusable={false} size={'50px'} path={mdiArrowDownCircleOutline} />
      );
      buttonText = <p>NEXT SECTION</p>;

      break;
    case 'top':
      buttonIcon = (
        <Icon focusable={false} size={'50px'} path={mdiArrowUpCircleOutline} />
      );
      buttonText = <p>PREVIOUS SECTION</p>;

      break;
    default:
      return;
  }

  return (
    <Card variant='interactive' id='section'>
      <div id='card'>
        <Card.Body>
          <div className='row d-flex align-items-center'>
            <div className='col-1'>
              {buttonIcon}
            </div>
            <div className='col'>
              <div className='col-12'>
                {buttonText}
              </div>
              <div className='col-12'>
                <div id='navsection'>{navSection}</div>
              </div>
            </div>
          </div>
        </Card.Body>
      </div>
    </Card>
  );
};

export default SectionButton;
