import { useMemo, useState, useCallback, useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { createColumnHelper } from '@tanstack/react-table';
import { Alert } from '@trussworks/react-uswds';

import useErrorFocus from '@src/customHooks/useErrorFocus';

import TanStackTableNew from '@components/table/TanStackTableNew.jsx';
import TextInput from '@components/text-input/TextInput';
import FieldHeader from '@pages/Forms/components/Form/FieldHeader';
import TextAreaInput from '@components/textarea/TextArea';
import SelectInput from '@components/select/Select';

import { TableCell } from '@components/table/tableCellComponents/TableCell';
import { EditCell } from '@components/table/tableCellComponents/EditCell';
import AmountUnitTableCell from '@components/table/tableCellComponents/AmountUnitTableCell';
import CreditUnitTableCell from '@components/table/tableCellComponents/CreditUnitTableCell';
import AmountTypeTableCell from '@components/table/tableCellComponents/AmountTypeTableCell';
import WatersNameTableCell from '@components/table/tableCellComponents/WatersNameTableCell';
import BankILFTableCell from '@components/table/tableCellComponents/BankILFTableCell';
import { ProposedCreditAmountTableCell } from '@components/table/tableCellComponents/ProposedCreditAmountTableCell';
import { AreaAmountTableCell } from '@components/table/tableCellComponents/AreaAmountTableCell';
import { ImpactAreaAmountTableCell } from '@components/table/tableCellComponents/ImpactAreaAmountTableCell';
import { ImpactTableCell } from '@components/table/tableCellComponents/ImpactTableCell';
import { PermanentLossTableCell } from '@components/table/tableCellComponents/PermanentLossTableCell';
import MitigationAmountUnitTableCell from '@components/table/tableCellComponents/MitigationAmountUnitTableCell';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { Row } from '@pages/_shared/helper';
import { impactActivities, impactResourceTypes, ILFMitigationTypes, PRMMitigationTypes, mitigationSubTypes, mitigationBankTypes, InlieuFeeTypes, compensatoryMitigationTooltip, proposedMitigationTooltip, mitigationTypeTooltip, permitteeResponsibleMitigationTypeTooltip, impactNameTooltip, materialsTypeTooltip } from '../_helper';
import { decimalNumberRegex, setNumberValue } from '@src/utils/regex';
import { filterNullEmptyObjects } from '@src/utils/helpers';
import TypeOfMaterialsTableCell from '@components/table/tableCellComponents/TypeOfMaterialsTableCell';

import { ImpactsActivity } from '@src/utils/enums';
import ErrorSummary from '@components/error-summary/ErrorSummary';

export const PermitsFormImpactsMitigationMetadata = {
  sectionName: 'Impacts and Mitigation',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const requiredActivity = [ImpactsActivity.ConversionWatersType, ImpactsActivity.DischargeDredgedMaterial, ImpactsActivity.DischargeFillMaterial, ImpactsActivity.EcologicalRestoration];

const impactsValidationSchema = yup.object().shape({
  waterName: yup.string().required('Please select an option'),
  impactName: yup.string().required('Field is required'),
  dischargeMaterialType: yup.string().when('activity', { is: val => requiredActivity.includes(val) === true, then: () => yup.string().required(('Please select an option')), otherwise: () => yup.string().nullable() }).nullable(),
  activity: yup.string().required('Please select an option'),
  resourceType: yup.string().required('Please select an option'),
  isPermanentLoss: yup.string().required('Please select an option'),
  impactDuration: yup.string().required('Field is required'),
  amountType: yup.string().required('Please select an option'),
  amountUnit: yup.string().required('Field is required'),
  proposedLength: yup.string().test('zeroTest', 'Value must be more than 0', function (item) { return this.parent.proposedAmount || this.parent.amountUnit === 'Cubic Yards' ? true : item > 0; }).nullable().matches(decimalNumberRegex, 'Field is invalid.'),
  proposedWidth: yup.string().test('zeroTest', 'Value must be more than 0', function (item) { return this.parent.proposedAmount || this.parent.amountUnit === 'Cubic Yards' ? true : item > 0; }).nullable().matches(decimalNumberRegex, 'Field is invalid.'),
  proposedAmount: yup.string().when(['proposedLength', 'proposedWidth'], { is: (proposedLength, proposedWidth) => !proposedLength && !proposedWidth, then: () => yup.string().test('zeroTest', 'Value must be more than 0', val => (val !== '' || val !== null) && val > 0) }).nullable().matches(decimalNumberRegex, 'Field is invalid.'),
});

const ILFValidationSchema = yup.object().shape({
  mitigationName: yup.string().required('Field is required'),
  mitigationType: yup.string().required('Please select an option'),
  nameOfBankOrILF: yup.string().required('Please select an option'),
  creditUnit: yup.string().nullable(),
  proposedCreditAmount: yup.number().nullable(),
});

const PRMValidationSchema = yup.object().shape({
  waterName: yup.string().when('mitigationType', { is: val => val !== 'Advanced Permittee Responsible Mitigation', then: () => yup.string().required(('Please select an option')), otherwise: () => yup.string().nullable() }).nullable(),
  mitigationName: yup.string().required('Field is required'),
  mitigationType: yup.string().required('Please select an option'),
  permitteeResponsibleType: yup.string().nullable(),
  resourceType: yup.string().nullable(),
  proposedLength: yup.string().test('zeroTest', 'Value must be more than 0', function (item) { return (!this.parent.proposedAmount || this.parent.proposedAmount === 0) ? item > 0 : true; }).nullable(),
  proposedWidth: yup.string().test('zeroTest', 'Value must be more than 0', function (item) { return (!this.parent.proposedAmount || this.parent.proposedAmount === 0) ? item > 0 : true; }).nullable(),
  proposedAmount: yup.string().when(['proposedLength', 'proposedWidth'], {
    is: (proposedLength, proposedWidth) => (
      !proposedLength && !proposedWidth
    ),
    then: () => yup.string().required('Field is required').min(1, 'Value must be greater than 0')
  }).nullable(),
  amountUnit: yup.string().required('Please select an option'),
});

const ipImpactAdditionalDetailsLabel = 'Provide any additional information you may have about the proposed quantity of wetlands, streams, or other types of waters directly affected by the proposed activity. This level of detail is helpful to better understand the type of impacts that are proposed for your project';
const gpImpactAdditionalDetailsLabel = 'Provide any additional information you may have about the proposed quantity of wetlands, streams, or other types of waters directly affected by the proposed Nationwide Permit activity. This level of detail is helpful to better understand the type of impacts that are proposed for your project';
const ipFormHeaderSubtext = ' For discharges of dredged or fill material into waters of the United States, provide the amount of wetlands, streams, or other types of waters filled, flooded, excavated, or drained by the proposed activity. For structures or work in navigable waters of the United States subject to Section 10 of the Rivers and Harbors Act of 1899, provide the amount of navigable waters filled, dredged, or occupied by one or more structures (e.g., aids to navigation, mooring buoys) by the proposed activity.';
const gpFormHeaderSubtext = ' For discharges of dredged or fill material into waters of the United States, provide the amount of wetlands, streams, or other types of waters filled, flooded, excavated, or drained by the proposed NWP activity. For structures or work in navigable waters of the United States subject to Section 10 of the Rivers and Harbors Act of 1899, provide the amount of navigable waters filled, dredged, or occupied by one or more structures (e.g., aids to navigation, mooring buoys) by the proposed NWP activity.';

const PermitsFormImpactsMitigation = connect(
  'doUpdateSectionValidity',
  'doUpdatePermitRequest',
  'doUpdatePermitRequestSub',
  'doGetBankSiteList',
  'doGetILFProgramList',
  'selectSelectedRequest',
  'selectBankSiteList',
  'selectILFProgramList',
  'selectRequestFormData',
  'selectRequestAPIData',
  'selectPermitData',
  'selectIsReadOnly',
  'selectSteps',
  'selectActiveStep',
  ({
    doUpdateSectionValidity,
    doUpdatePermitRequest,
    doUpdatePermitRequestSub,
    doGetBankSiteList,
    doGetILFProgramList,
    bankSiteList,
    iLFProgramList,
    selectedRequest,
    requestFormData,
    requestAPIData,
    permitData,
    isReadOnly,
    steps,
    activeStep,
    stepNo
  }) => {

    const ipSchema = (impactsTableLength, PRMTableLength, ILFTableLength) => yup.object().shape({
      proposedAcres: yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid.'),
      proposedLinearFeet: yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid.'),
      proposedCubicYards: yup.string().required('Field is required').matches(decimalNumberRegex, 'Field is invalid.'),
      impactsTable: yup.string().test(
        'impacts-table-test',
        'Impacts: At least one impact is required',
        function () {
          return impactsTableLength >= 1;
        }
      ),
      impactAdditionalDetails: yup.string().required('Field is required'),
      proposedMitigationMeasures: yup.string().required('Field is required'),
      isCompensatoryMitigationProposed: yup.string().required('Please select an option'),
      mitigationTable: yup.string().when('isCompensatoryMitigationProposed', {
        is: 'true', then: () => yup.string().test(
          'mitigation-table-test',
          'Mitigation: At least one mitigation is required',
          function () {
            return PRMTableLength >= 1 || ILFTableLength >= 1;
          }
        ), otherwise: () => yup.string().nullable()
      }),
      compensatoryMitigationExplanation: yup.string().nullable().when('willResultInWetlandLoss', { is: 'true', then: () => yup.string().required('Field is required') }),
    });
    const gpSchema = (impactsTableLength, PRMTableLength, ILFTableLength) => yup.object().shape({
      proposedAcres: yup.string().required('Field is required').matches(decimalNumberRegex, 'Value is invalid.'),
      proposedLinearFeet: yup.string().required('Field is required').matches(decimalNumberRegex, 'Value is invalid.'),
      proposedCubicYards: yup.string().required('Field is required').matches(decimalNumberRegex, 'Value is invalid.'),
      impactsTable: yup.string().test(
        'impacts-table-test',
        'Impacts: At least one impact is required',
        function () {
          return impactsTableLength >= 1;
        }
      ),
      impactAdditionalDetails: yup.string().required('Field is required'),
      proposedMitigationMeasures: yup.string().required('Field is required'),
      willResultInWetlandLoss: yup.string().required('Please select an option'), /// NEEDS TO BE REQUIRED FOR GP AND NOT EXIST FOR IP
      isCompensatoryMitigationProposed: yup.string().required('Please select an option'),
      mitigationTable: yup.string().when('isCompensatoryMitigationProposed', {
        is: 'true', then: () => yup.string().test(
          'mitigation-table-test',
          'Mitigation: At least one mitigation is required',
          function () {
            return PRMTableLength >= 1 || ILFTableLength >= 1;
          }
        ), otherwise: () => yup.string().nullable()
      }),
      compensatoryMitigationExplanation: yup.string().nullable().when('willResultInWetlandLoss', { is: 'true', then: () => yup.string().required('Field is required') }),
    });
    const volumeOptions = useMemo(() => [{ value: 'Cubic Yards', label: 'Cubic Yards' }], []);
    const areaOptions = useMemo(() => [{ value: 'Square Feet', label: 'Square Feet' }, { value: 'Acres', label: 'Acres' }], []);
    const projectWaters = useMemo(() =>
      requestFormData?.request?.aquaticResources?.map(resource =>
        resource?.waterName ? {
          value: resource?.waterName,
          label: resource?.waterName,
        } : {
          value: null,
          label: 'NO NAME ENTERED!',
        }
      ), [requestFormData?.request?.aquaticResources]);
    const [impactsKey, setImpactsKey] = useState(0);
    const [PRMKey, setPRMKey] = useState(999);
    const [ILFKey, setILFKey] = useState(99999);
    const [impactsTableErrors, setImpactsTableErrors] = useState();
    const [PRMTableErrors, setPRMTableErrors] = useState();
    const [ILFTableErrors, setILFTableErrors] = useState();
    const [ilfProgramOptions, setILFProgramOptions] = useState();
    const [bankProgramOptions, setBankProgramOptions] = useState();
    const [impactsData, setImpactsData] = useState([]);
    const [PRMData, setPRMData] = useState([]);
    const [ILFData, setILFData] = useState([]);
    const [impactSummaryData, setImpactSummaryData] = useState({});
    const impactsTableLength = impactsData?.length;
    const PRMTableLength = PRMData?.length;
    const ILFTableLength = ILFData?.length;
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    const districtName = requestFormData?.location?.district?.replace(/ District$/i, '');

    useEffect(() => {
      doGetBankSiteList('district', districtName);
      doGetILFProgramList('district', districtName);
    }, [doGetBankSiteList, doGetILFProgramList, districtName]);

    const defaultValues = {
      proposedAcres: permitData?.impactSummary?.proposedAcres ?? null,
      proposedLinearFeet: permitData?.impactSummary?.proposedLinearFeet ?? null,
      proposedCubicYards: permitData?.impactSummary?.CubicYards ?? null,
      impactAdditionalDetails: permitData?.impactSummary?.impactAdditionalDetails ?? '',
      isCompensatoryMitigationProposed: (permitData?.isCompensatoryMitigationProposed !== undefined && permitData?.isCompensatoryMitigationProposed !== null) ? (permitData.isCompensatoryMitigationProposed ? 'true' : 'false') : '',
      compensatoryMitigationExplanation: permitData?.compensatoryMitigationExplanation ?? '',
      willResultInWetlandLoss: (permitData?.willResultInWetlandLoss !== undefined && permitData?.willResultInWetlandLoss !== null) ? (permitData.willResultInWetlandLoss ? 'true' : 'false') : '',
      proposedMitigationMeasures: permitData?.proposedMitigationMeasures ?? '',
    };

    const formSchema = selectedRequest === '3' ? ipSchema(impactsTableLength, PRMTableLength, ILFTableLength) : gpSchema(impactsTableLength, PRMTableLength, ILFTableLength);

    const methods = useForm({ resolver: yupResolver(formSchema), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { isValid, errors }, setValue, watch, setError, clearErrors, trigger } = methods;

    const isCompensatoryMitigationProposed = watch('isCompensatoryMitigationProposed');
    const willResultInWetlandLoss = watch('willResultInWetlandLoss');

    // Calculations

    const proposedAcresCalc = impactsData?.filter(item => item?.amountUnit === 'Acres').reduce((total, impact) => {
      if (impact.proposedAmount) {
        return total + Number(impact?.proposedAmount);
      }
      if (impact?.proposedLength && impact?.proposedWidth) {
        const area = impact?.proposedLength * impact?.proposedWidth;
        return total + Number((area / 43560));
      }
      return total;
    }, 0);

    const proposedSquareFtCalc = impactsData?.filter(item => item?.amountUnit === 'Square Feet').reduce((total, impact) => {
      if (impact?.proposedAmount) {
        return total + Number(impact?.proposedAmount);
      }
      if (impact?.proposedLength && impact?.proposedWidth) {
        const area = impact?.proposedLength * impact?.proposedWidth;
        return total + Number(area);
      }
      return total;
    }, 0);

    const proposedCubicYards = impactsData?.reduce((total, impact) => {
      if (impact?.amountUnit === 'Cubic Yards') {
        return total + Number((impact?.proposedAmount || 0)); // Check if proposedAmount is null
      }
      return total;
    }, 0);

    const totalWetlandLossCalc = impactsData?.filter(item => (item.resourceType === 'Non-Tidal Wetland' || item.resourceType === 'Tidal Wetland') && item.amountUnit === 'Acres').reduce((total, impact) => {
      if (impact?.proposedAmount) {
        return total + Number(impact?.proposedAmount);
      }
      if (impact?.proposedLength && impact?.proposedWidth) {
        const area = impact?.proposedLength * impact?.proposedWidth;
        return total + Number((area / 43560));
      }
      return total;
    }, 0);

    const totalStreamLossCalc = impactsData?.filter(item => item?.resourceType === 'River/Stream' && item.amountUnit === 'Acres').reduce((total, impact) => {
      if (impact?.proposedAmount) {
        return total + Number(impact?.proposedAmount);
      }
      if (impact?.proposedLength && impact?.proposedWidth) {
        const area = impact?.proposedLength * impact?.proposedWidth;
        return total + Number((area / 43560));
      }
      return total;
    }, 0);

    useEffect(() => {
      if (selectedRequest === '4' && (totalWetlandLossCalc > 0.10 || totalStreamLossCalc > 0.03)) {
        setValue('willResultInWetlandLoss', 'true', { shouldValidate: true });
        doUpdatePermitRequest({ willResultInWetlandLoss: 'true' });
      }
    }, [selectedRequest, totalWetlandLossCalc, totalStreamLossCalc, setValue, doUpdatePermitRequest]);

    useEffect(() => {
      setValue('proposedAcres', proposedAcresCalc, { shouldValidate: true });
    }, [proposedAcresCalc, setValue]);

    useEffect(() => {
      setValue('proposedLinearFeet', proposedSquareFtCalc, { shouldValidate: true });
    }, [proposedSquareFtCalc, setValue]);

    useEffect(() => {
      setValue('proposedCubicYards', proposedCubicYards, { shouldValidate: true });
    }, [proposedCubicYards, setValue]);

    useEffect(() => {
      // Remove duplicate names
      let checkDuplicateArr = [];
      let bankPrograms = [{ label: 'Not Listed', value: 'Not Listed', id: 999999999 }];
      bankSiteList?.forEach(item => {
        if (!checkDuplicateArr?.includes(item.NAME)) {
          checkDuplicateArr.push(item.NAME);
          bankPrograms.push({ value: item.NAME, label: item.NAME, id: item.BANK_ID });
        }
      });
      setBankProgramOptions(bankPrograms);
    }, [bankSiteList]);

    useEffect(() => {
      // Remove duplicate names
      let checkDuplicateArr = [];
      let ilfPrograms = [{ label: 'Not Listed', value: 'Not Listed', id: 999999999 }];
      iLFProgramList?.forEach(item => {
        if (!checkDuplicateArr?.includes(item.PROGRAM_NAME)) {
          checkDuplicateArr.push(item.PROGRAM_NAME);
          ilfPrograms.push({ value: item.PROGRAM_NAME, label: item.PROGRAM_NAME, id: item.PROGRAM_ID });
        }
      });
      setILFProgramOptions(ilfPrograms);
    }, [iLFProgramList]);

    // Update permits request object
    useEffect(() => {
      if (impactsData) {
        const impacts = impactsData?.map(item => ({
          ...item,
          proposedLength: setNumberValue(item?.proposedLength, false),
          proposedWidth: setNumberValue(item?.proposedWidth, false),
          proposedAmount: setNumberValue(item?.proposedAmount, false),
        }));
        doUpdatePermitRequest({ impacts: impacts });
      }
      doUpdatePermitRequest({ impactSummary: filterNullEmptyObjects(impactSummaryData) !== null ? impactSummaryData : null });
      // Update calculated values
      if (impactsData?.length > 0 && (proposedAcresCalc !== 0 || proposedSquareFtCalc !== 0 || proposedCubicYards !== 0)) {
        doUpdatePermitRequestSub('impactSummary', { proposedAcres: proposedAcresCalc });
        doUpdatePermitRequestSub('impactSummary', { proposedLinearFeet: proposedSquareFtCalc });
        doUpdatePermitRequestSub('impactSummary', { proposedCubicYards: proposedCubicYards });
      } else {
        doUpdatePermitRequestSub('impactSummary', { proposedAcres: null });
        doUpdatePermitRequestSub('impactSummary', { proposedLinearFeet: null });
        doUpdatePermitRequestSub('impactSummary', { proposedCubicYards: null });
      }
    }, [impactsData, impactSummaryData, doUpdatePermitRequest, doUpdatePermitRequestSub, impactsTableErrors, proposedAcresCalc, proposedCubicYards, proposedSquareFtCalc]);

    useEffect(() => {
      if (ILFData) {
        const ILFmitigation = ILFData?.map(item => ({
          ...item,
          proposedCreditAmount: setNumberValue(item?.proposedCreditAmount, false),
          proposedLength: setNumberValue(item?.proposedLength, false),
          proposedWidth: setNumberValue(item?.proposedWidth, false),
          proposedAmount: setNumberValue(item?.proposedAmount, false),
        }));
        const PRMmitigation = PRMData?.map(item => ({
          ...item,
          proposedCreditAmount: setNumberValue(item?.proposedCreditAmount, false),
          proposedLength: setNumberValue(item?.proposedLength, false),
          proposedWidth: setNumberValue(item?.proposedWidth, false),
          proposedAmount: setNumberValue(item?.proposedAmount, false),
        }));

        const mitigations = [...ILFmitigation, ...PRMmitigation];

        doUpdatePermitRequest({ mitigations: mitigations });
      }
    }, [ILFData, PRMData, doUpdatePermitRequest]);


    const columnHelper = createColumnHelper();

    const ImpactsColumns = useMemo(() => [
      columnHelper.display({
        id: 'edit',
        cell: isReadOnly ? <></> : EditCell,
        size: 15,
        enableResizing: false,
        enableSorting: false,
      }),
      columnHelper.accessor('waterName', {
        header: 'Water Name/Label',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'select',
          options: projectWaters,
          required: true,
        },
      }),
      columnHelper.accessor('impactName', {
        header: 'Impact Name',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'text',
          tooltip: impactNameTooltip,
          required: true,
        },
      }),
      columnHelper.accessor('activity', {
        header: 'Activity',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'select',
          required: true,
          options: impactActivities,
        },
      }),
      columnHelper.accessor('dischargeMaterialType', {
        header: 'Type of Materials Being Discharged',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TypeOfMaterialsTableCell,
        meta: {
          type: 'text',
          tooltip: materialsTypeTooltip,
        },
      }),
      columnHelper.accessor('resourceType', {
        header: 'Resource Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        enableHiding: true,
        meta: {
          type: 'select',
          required: true,
          options: impactResourceTypes,
        },
      }),
      columnHelper.accessor('isPermanentLoss', {
        header: 'Permanent Loss',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue() ? 'Yes' : 'No'}</span> : PermanentLossTableCell,
        meta: {
          type: 'select',
          required: true,
          options: [{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }],
        },
      }),
      columnHelper.accessor('impactDuration', {
        header: 'Impact Duration',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : ImpactTableCell,
        meta: {
          type: 'select',
          required: true,
          options: [{ value: 'Temporary', label: 'Temporary' }, { value: 'Permanent', label: 'Permanent' }]
        }
      }),
      columnHelper.accessor('amountType', {
        header: 'Amount Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : AmountTypeTableCell,
        meta: {
          type: 'select',
          required: true,
          options: [{ value: 'Fill Area', label: 'Fill Area' }, { value: 'Fill Volume', label: 'Fill Volume' }, { value: 'Removal Area', label: 'Removal Area' }, { value: 'Removal Volume', label: 'Removal Volume' }, { value: 'Structure Area', label: 'Structure Area' }],
        },
      }),
      columnHelper.accessor('proposedLength', {
        header: 'Proposed Length (feet)',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : ImpactAreaAmountTableCell,
        meta: {
          isNumber: true,
          maxLength: 17,
          required: true,
        }
      }),
      columnHelper.accessor('proposedWidth', {
        header: 'Proposed Width (feet)',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : ImpactAreaAmountTableCell,
        meta: {
          isNumber: true,
          maxLength: 17,
          required: true,
        },
      }),
      columnHelper.accessor('proposedAmount', {
        header: 'Proposed Amount',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : ImpactAreaAmountTableCell,
        meta: {
          type: 'text',
          isNumber: true,
          maxLength: 17,
          required: true,
        }
      }),
      columnHelper.accessor('amountUnit', {
        header: 'Area Units',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : AmountUnitTableCell,
        meta: {
          type: 'select',
          required: true,
          options: [areaOptions, volumeOptions],
        },
      }),
    ], [columnHelper, areaOptions, volumeOptions, isReadOnly, projectWaters]);

    const updateImpactsData = useCallback((rowIndex, columnId, updatedValue) => {
      setImpactsData((oldData) => {
        const newData = oldData ? [...oldData] : null;
        if (newData && newData[rowIndex]) {
          // Update properties
          newData[rowIndex] = {
            ...newData[rowIndex],
            [columnId]: updatedValue
          };
          return newData;
        }
      });
    }, [setImpactsData]);

    const removeMultipleImpacts = useCallback((indicesToRemove) => {
      setImpactsData((oldData) => {
        const newRows = oldData && oldData.filter((_, index) => !indicesToRemove.includes(index));
        return newRows;
      });
      setImpactsKey(old => old + 1);
    }, [setImpactsData, setImpactsKey]);


    const PRMColumns = useMemo(() => [
      columnHelper.display({
        id: 'edit',
        cell: isReadOnly ? <></> : EditCell,
        size: 15,
        enableResizing: false,
        enableSorting: false,
      }),
      columnHelper.accessor('waterName', {
        header: 'Waters Name\n(i.e. Wetland A, Stream A)',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : WatersNameTableCell,
        size: 200,
        meta: {
          type: 'select',
          options: projectWaters,
          required: true,
        },
      }),
      columnHelper.accessor('mitigationName', {
        header: 'Mitigation Name\n(i.e. All Wetlands, Stream A, etc.)',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        size: 200,
        meta: {
          type: 'text',
          required: true,
        },
      }),
      columnHelper.accessor('mitigationType', {
        header: 'Mitigation Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        size: 200,
        meta: {
          type: 'select',
          required: true,
          tooltip: mitigationTypeTooltip,
          options: PRMMitigationTypes
        },
      }),
      columnHelper.accessor('permitteeResponsibleType', {
        header: 'Permitee Responsible Mitigation Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        size: 200,
        meta: {
          type: 'select',
          tooltip: permitteeResponsibleMitigationTypeTooltip,
          options: mitigationSubTypes
        },
      }),
      columnHelper.accessor('resourceType', {
        header: 'Resource Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        size: 175,
        meta: {
          type: 'select',
          options: impactResourceTypes,
        },
      }),
      columnHelper.accessor('proposedLength', {
        header: 'Proposed Length (feet)',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : AreaAmountTableCell,
        meta: {
          isNumber: true,
          required: true,
          maxLength: 17
        },
      }),
      columnHelper.accessor('proposedWidth', {
        header: 'Proposed Width (feet)',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : AreaAmountTableCell,
        meta: {
          isNumber: true,
          required: true,
          maxLength: 17
        },
      }),
      columnHelper.accessor('proposedAmount', {
        header: 'Proposed Area',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : AreaAmountTableCell,
        meta: {
          isNumber: true,
          required: true,
          maxLength: 17
        },
      }),
      columnHelper.accessor('amountUnit', {
        header: 'Area Units',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : MitigationAmountUnitTableCell,
        meta: {
          type: 'select',
          options: [areaOptions],
          required: true,
        },
      }),
    ], [columnHelper, isReadOnly, areaOptions, projectWaters]);

    const ILFColumns = useMemo(() => [
      columnHelper.display({
        id: 'edit',
        cell: isReadOnly ? <></> : EditCell,
        size: 15,
        enableResizing: false,
        enableSorting: false,
      }),
      columnHelper.accessor('mitigationName', {
        header: 'Mitigation Name\n(i.e. All Wetlands, Stream A, etc.)',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        meta: {
          type: 'text',
          required: true,
        },
      }),
      columnHelper.accessor('mitigationType', {
        header: 'Mitigation Type',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        size: 100,
        meta: {
          type: 'select',
          required: true,
          tooltip: mitigationTypeTooltip,
          options: ILFMitigationTypes,
        },
      }),
      columnHelper.accessor('nameOfBankOrILF', {
        header: 'Bank/ILF Name',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : BankILFTableCell,
        size: 300,
        meta: {
          type: 'select',
          options: [bankProgramOptions, ilfProgramOptions],
          required: true,
        },
      }),
      // NOTE: Hidden rendered column that gets set in nameOfBankOrILF column
      columnHelper.accessor('bankOrILFNumber', {
        header: 'Bank/ILF Number',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : TableCell,
        enableHiding: true,
        meta: {
          type: 'text',
          readOnly: true,
          required: true,
        },
      }),
      columnHelper.accessor('proposedCreditAmount', {
        header: 'Proposed Credit Amount',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : ProposedCreditAmountTableCell,
        size: 50,
        meta: {
          type: 'text',
          isNumber: true,
          maxLength: 17
        },
      }),
      columnHelper.accessor('creditUnit', {
        header: 'Credit Units',
        cell: isReadOnly ? ({ cell }) => <span>{cell.getValue()}</span> : CreditUnitTableCell,
        size: 100,
        meta: {
          type: 'select',
          options: [mitigationBankTypes, InlieuFeeTypes],
        },
      }),
    ], [columnHelper, isReadOnly, bankProgramOptions, ilfProgramOptions]);

    const updateILFData = useCallback((rowIndex, columnId, updatedValue) => {
      setILFData((oldData) => {
        const newData = oldData ? [...oldData] : null;
        if (newData && newData[rowIndex]) {
          // Update properties
          newData[rowIndex] = {
            ...newData[rowIndex],
            [columnId]: updatedValue
          };
          return newData;
        }
      });
    }, [setILFData]);


    const updatePRMData = useCallback((rowIndex, columnId, updatedValue) => {
      setPRMData((oldData) => {
        const newData = oldData ? [...oldData] : null;
        if (newData && newData[rowIndex]) {
          // Update properties
          newData[rowIndex] = {
            ...newData[rowIndex],
            [columnId]: updatedValue
          };
          return newData;
        }
      });
    }, [setPRMData]);

    const removeMultiplePRM = useCallback((indicesToRemove) => {
      setPRMData((oldData) => {
        const newRows = oldData.filter((_, index) => !indicesToRemove.includes(index));
        return newRows;
      });
      setPRMKey(old => old + 1);
    }, [setPRMData, setPRMKey]);

    const removeMultipleILF = useCallback((indicesToRemove) => {
      setILFData((oldData) => {
        const newRows = oldData.filter((_, index) => !indicesToRemove.includes(index));
        return newRows;
      });
      setILFKey(old => old + 1);
    }, [setILFData, setILFKey]);

    useEffect(() => {

      if (isCompensatoryMitigationProposed === 'false' && (ILFData?.length > 0 || PRMData?.length > 0)) {

        if (ILFData?.length > 0) {
          const rowsToRemove = ILFData?.map((row, i) => i);
          rowsToRemove && removeMultipleILF(rowsToRemove);
        }

        if (PRMData?.length > 0) {
          const rowsToRemove = PRMData?.map((row, i) => i);
          rowsToRemove && removeMultiplePRM(rowsToRemove);
        }

      }

    }, [isCompensatoryMitigationProposed, ILFData, PRMData, removeMultipleILF, removeMultiplePRM]);

    const handleChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case 'impactAdditionalDetails':
          setImpactSummaryData(value ? { ...impactSummaryData, ...({ [name]: value }) } : {});
          break;
        default: {
          doUpdatePermitRequest({ [name]: value });
        }
      }
    };

    useEffect(() => {

      if (requestAPIData?.request?.permits?.[0]?.impacts) {
        setImpactsData(requestAPIData?.request?.permits?.[0]?.impacts);
      }

      if (requestAPIData?.request?.permits?.[0]?.mitigations) {
        setILFData(requestAPIData?.request?.permits?.[0]?.mitigations.filter(data => ['Mitigation Bank', 'In-lieu Fee'].includes(data.mitigationType)));
        setPRMData(requestAPIData?.request?.permits?.[0]?.mitigations.filter(data => ['Permittee Responsible On-site', 'Permittee Responsible Off-site', 'Advanced Permittee Responsible Mitigation'].includes(data.mitigationType)));
      }

      if (requestAPIData?.request?.permits?.[0]?.impactSummary) {
        setImpactSummaryData(requestAPIData?.request?.permits?.[0]?.impactSummary);
      }

    }, [requestAPIData]);

    useEffect(() => {
      const impactsValid = (impactsData?.length > 0 && !impactsTableErrors);
      const PRMValid = isCompensatoryMitigationProposed === 'true' ? ((PRMData?.length > 0 || ILFData?.length > 0) && !PRMTableErrors) : true;
      const ILFValid = isCompensatoryMitigationProposed === 'true' ? ((ILFData?.length > 0 || PRMData?.length > 0) && !ILFTableErrors) : true;
      const sectionValid = isValid && impactsValid && PRMValid && ILFValid;
      doUpdateSectionValidity(PermitsFormImpactsMitigationMetadata.sectionName, sectionValid ? true : false, stepNo, isReadOnly);
    }, [isValid, errors, ILFTableErrors, PRMTableErrors, impactsTableErrors, isCompensatoryMitigationProposed, ILFData?.length, PRMData?.length, impactsData?.length, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {

      if (impactsData?.length < 1) {
        setError('impactsTable', { type: 'custom', message: 'Impacts: At least one impact is required' });
      }
      else if (impactsTableErrors) {
        setError('impactsTable', { type: 'custom', message: 'Impacts: Table contains invalid rows' });
      }
      else if (!impactsTableErrors && impactsData?.length > 0) {
        clearErrors('impactsTable');
      }
      else {
        clearErrors('impactsTable');
      }

    }, [impactsTableErrors, impactsData, clearErrors, setError]);

    useEffect(() => {

      if (isCompensatoryMitigationProposed === 'true' && ILFData?.length < 1 && PRMData?.length < 1) {
        setError('mitigationTable', { type: 'custom', message: 'Mitigation: At least one mitigation is required' });
      }
      else if (PRMTableErrors === true || ILFTableErrors === true) {
        setError('mitigationTable', { type: 'custom', message: 'Mitigation: Table contains invalid rows' });
      }
      else if (isCompensatoryMitigationProposed === 'true' && !PRMTableErrors && !ILFTableErrors && (ILFData?.length > 0 || PRMData?.length > 0)) {
        clearErrors('mitigationTable');
        trigger();
      }
      else {
        clearErrors('mitigationTable');
        trigger();
      }

    }, [isCompensatoryMitigationProposed, PRMTableErrors, ILFTableErrors, PRMData, ILFData, clearErrors, setError, trigger]);


    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        <>
          {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
            <ErrorSummary errors={errors} sectionNo={stepNo} />
          }
          <FieldHeader
            text='Project Impacts and Mitigation'
            subtext={selectedRequest === '3' ? ipFormHeaderSubtext : gpFormHeaderSubtext}
          >
            <div className='mt-2' id='impactsTable'>
              Provide an inventory of all proposed impacts to aquatic resources on the project site and any proposed compensatory mitigation. Impacts and mitigation can be provided in this section by adding them individually.
            </div>
            {/* <div className='mt-2'>
              Download PDF Bulk Upload Guide:
              <ul>
                <li>
                  <LinkButton onClick={() => doDownloadFile(TemplateFiles.BulkUploadGuide, FileTypes.Template)} title='Impact and Mitigation Bulk Upload Guide (PDF)' content='Impact and Mitigation Bulk Upload Guide (PDF)' />
                </li>
              </ul>
            </div>
            <div className='mt-2'>
              Download CSV Bulk Upload Template:
              <ul>
                <li>
                  <LinkButton onClick={() => doDownloadFile(ResourceFiles.ARBulkUploadGDBTemplate, FileTypes.Template)} title='Impact and Mitigation Bulk Upload GDB Template (ZIP)' content='Impact and Mitigation Bulk Upload GDB Template (ZIP)' />
                </li>
              </ul>
            </div> */}
            <div className='mt-2 mb-3'>
              Use the “Add New Row" button to individually add impacts and mitigation to the inventory table.
            </div>
            <div className='mt-2'>
              For proposed impact amounts, enter length and width, OR area amount.
            </div>
          </FieldHeader>
          <Row>
            <p className='h4 border-bottom w-100 pb-2 ml-2'>Impacts<span className='asterisk-color'>*</span></p>
          </Row>
          <TanStackTableNew
            data={impactsData}
            key={impactsKey}
            columns={ImpactsColumns}
            validationSchema={impactsValidationSchema}
            rowErrorCallback={setImpactsTableErrors}
            addRow={() => setImpactsData(prev => prev ? [...prev, {}] : [{}])}
            removeMultipleRows={removeMultipleImpacts}
            updateSourceData={updateImpactsData}
            isReadOnly={isReadOnly}
            initialTableState={{}}
            hideDrag={true}
            tableVersion={'ImpactsTable'}
          />
          <Row>
            <p className='h4 border-bottom w-100 pb-2 ml-2'>Impact Summary</p>
          </Row>
          <div className='ml-2'>
            <TextInput label='Proposed Acres' name='proposedAcres' required readOnly={true} onChange={handleChange} />
          </div>
          <div className='ml-2'>
            <TextInput label='Proposed Square Feet' name='proposedLinearFeet' required readOnly={true} onChange={handleChange} />
          </div>
          <div className='ml-2'>
            <TextInput label='Proposed Cubic Yards Dredged or Discharged' name='proposedCubicYards' required readOnly={true} onChange={handleChange} />
          </div>

          <div className='ml-2'>
            <TextAreaInput label={selectedRequest === '3' ? ipImpactAdditionalDetailsLabel : gpImpactAdditionalDetailsLabel} name='impactAdditionalDetails' required readOnly={isReadOnly} onChange={handleChange} />
          </div>
          <Row>
            <p className='h4 border-bottom w-100 pb-2 ml-2'>Mitigation</p>
          </Row>
          <div className='ml-2'>
            <TextAreaInput label='Description of Avoidance, Minimization, and Compensation' name='proposedMitigationMeasures' required tooltip={proposedMitigationTooltip} readOnly={isReadOnly} onChange={handleChange} />
          </div>
          <div className='ml-2'>
            <Alert type='info' headingLevel='h4'>In general, mitigation should be located within the same watershed as the impact site and should be located where it is most likely to successfully replace lost functions and services.  USACE considers the type and location options for mitigation in the following order although flexibility in approach can be exercised on a project-specific basis: mitigation bank credits, in-lieu fee program credits, permittee responsible mitigation under a watershed approach, permittee responsible mitigation through on-site and in-kind mitigation, and permittee responsible mitigation through off-site and/or out-of-kind mitigation.</Alert>
          </div>
          {selectedRequest === '4' &&
            <div className='ml-2'>
              <SelectInput label='Will the proposed activity result in a loss of greater than 0.10 acre of wetlands or 3/100 acre of stream ?' name='willResultInWetlandLoss' required readOnly={isReadOnly || (selectedRequest === '4' && (totalWetlandLossCalc > 0.10 || totalStreamLossCalc > 0.03))} onChange={handleChange}>
                <option key='2' value='true'>Yes</option>
                <option key='3' value='false'>No</option>
              </SelectInput>
            </div>
          }
          {(selectedRequest === '4' && willResultInWetlandLoss === 'true') && <div className='ml-2'>
            <TextAreaInput label='Explain how compensatory mitigation requirements in paragraph (c) of general condition 23 will be satisfied or explain why the adverse environmental effects are no more than minimal and why compensatory mitigation should not be required for the proposed activity' name='compensatoryMitigationExplanation' required tooltip={compensatoryMitigationTooltip} readOnly={isReadOnly} onChange={handleChange} />
          </div>}
          <div className='ml-2'>
            <SelectInput label='Do you propose compensatory mitigation?' name='isCompensatoryMitigationProposed' required readOnly={isReadOnly} onChange={handleChange}>
              <option key='2' value='true'>Yes</option>
              <option key='3' value='false'>No</option>
            </SelectInput>
          </div>
          {isCompensatoryMitigationProposed === 'true' && <>
            <p className='h4 border-bottom w-100 pb-2 ml-2 mt-3'>Permittee Responsible Mitigation</p>
            <TanStackTableNew
              data={PRMData}
              key={PRMKey}
              columns={PRMColumns}
              validationSchema={PRMValidationSchema}
              rowErrorCallback={setPRMTableErrors}
              addRow={() => setPRMData(prev => prev ? [...prev, {}] : [{}])}
              removeMultipleRows={removeMultiplePRM}
              updateSourceData={updatePRMData}
              isReadOnly={isReadOnly}
              initialTableState={{}}
              hideDrag={true}
              tableVersion='PRMTable'
              isFullWidth
            />
            <p className='h4 border-bottom w-100 pb-2 ml-2 mt-3' id='mitigationTable'>Mitigation Banks / In-Lieu Fee Programs</p>
            <TanStackTableNew
              data={ILFData}
              key={ILFKey}
              columns={ILFColumns}
              validationSchema={ILFValidationSchema}
              rowErrorCallback={setILFTableErrors}
              addRow={() => setILFData(prev => prev ? [...prev, {}] : [{}])}
              removeMultipleRows={removeMultipleILF}
              updateSourceData={updateILFData}
              isReadOnly={isReadOnly}
              initialTableState={{ columnVisibility: { bankOrILFNumber: false } }}
              hideDrag={true}
              isFullWidth
            />
          </>}
          <div className='mb-4' />
        </>
      </FormProvider>
    );
  });

PermitsFormImpactsMitigation.metadata = PermitsFormImpactsMitigationMetadata;

export default PermitsFormImpactsMitigation;
