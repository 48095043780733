import { connect } from 'redux-bundler-react';
import { TableWrapper } from './FormAndAppendices';
import LinkButton from '@components/link/linkButton';
import { FileTypes, ResourceFiles } from '@src/utils/enums';

const FormsAndAppendicesWotus = connect('doDownloadFile', ({ doDownloadFile }) => (
  <>
    <p>
      A document or series of documents, stored in the USACE administrative record for the project, that describes the
      methodology used to conduct the delineation and typically includes a collection of one or more maps, datasheets,
      photographs, and/or figures that depict and support a legally defensible delineation of waters of the United
      States on a property.
    </p>
    <TableWrapper>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AdditionalInformation, FileTypes.Resource)}>
            Additional Information [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          Provides information entered throughout the request screens. Generates a Delineation report and Jurisdictional
          Determination Project Information Sheet.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6295, FileTypes.Resource)}>
            ENG 6295 Agent Authorization Form [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          This form indicates that the property owner has given permission to an agent/consultant to act on his or her
          behalf in all matters relating to submitting a delineation report and/or an Aquatic Resource (AR) inventory.{' '}
          <span className='text-bold'>
            This form is not generated but needs to be uploaded to the RRS by the requestor.
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6294, FileTypes.Resource)}>
            ENG 6294 Right of Entry [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          This form provides permission to the USACE Project Manager to enter the property for which the WOTUS
          Delineation Report and AR inventory is being prepared.{' '}
          <span className='text-bold'>
            This form is not generated but needs to be uploaded to the RRS by the requestor.
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.ARBulkUploadGuide, FileTypes.Resource)}>
            AR Bulk Upload Guide [PDF, 6 pages]
          </LinkButton>
        </td>
        <td>
          This guide provides detailed information on how to prepare and upload information on multiple Aquatic
          Resources on a parcel of property.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.ARBulkUploadGDBTemplate, FileTypes.Resource)}>
            AR Bulk Upload GDB Template [ZIP, 72 KB]
          </LinkButton>
        </td>
        <td>
          This geodatabase (GDB) template provides a standard form to provide data (location, size, type, etc.) on
          Aquatic Resources prior to uploading to the RRS system.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.ARBulkUploadCSV, FileTypes.Resource)}>
            Resource Bulk Upload CSV Template [CSV, 2 KB]
          </LinkButton>
        </td>
        <td>Used for uploading centroids associated with aquatic resources in a csv format.</td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixH, FileTypes.Resource)}>
            Supporting information [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          Information that is uploaded by the requestor to support and/or clarify the request that is being made. This
          information can include, but is not limited to, photos, maps, drawings, other authorizations, mitigation
          plans, etc.
        </td>
      </tr>
    </TableWrapper>
  </>
));

export default FormsAndAppendicesWotus;
