import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import FieldHeader from '@forms/components/Form/FieldHeader';
import { Accordion } from '@trussworks/react-uswds';
import { recommendedInfoAccordion } from './PreAppForm3InstructionsHelper';

export const PreAppForm3InstructionsMetadata = {
  header: 'Pre-application/Project Scoping Request Form',
  sectionName: 'Instructions',
  isSection: true,
  lastSection: false,
  firstSection: true,
};

const PreAppForm3Instructions = connect(
  'doUpdateSectionValidity',
  ({
    doUpdateSectionValidity,
    stepNo,
  }) => {
    useEffect(() => {
      doUpdateSectionValidity(PreAppForm3InstructionsMetadata.sectionName, true, stepNo);
    }, [doUpdateSectionValidity, stepNo]);

    return (
      <>
        <FieldHeader text='INSTRUCTIONS' />
        <p className='margin-bottom-2 margin-top-2'>
          The <span className='text-bold'>pre-application meeting and scoping request</span> provides an electronic option for applicants to request a pre-application meeting with the U.S. Army Corps of Engineers (USACE) or to submit project scoping letters. This electronic application will guide you through providing the information required for a complete request.
        </p>
        <p className='margin-bottom-2'>
          <span className='text-bold'>Scoping letters,</span> which are typically submitted by applicants using Federal or State funding to accomplish a project of which they are the proponent, seek comment from the USACE regarding potential impacts to waters of the United States during very early phases of project development. These are typically submitted to help meeting requirements of the National Environmental Policy Act or similar state/local laws.
        </p>
        <p><span className='text-bold'>Preapplication meetings</span> are typically held prior to an applicant submitting a project application and are normally reserved for <b>larger and more complex projects that require a standard individual permit</b>. The purpose of these meetings is to discuss various aspects of the project, such as the required permit type, estimated permit timeframes, potential complexities related to permitting (such as endangered species, historic properties, section 408, etc.), onsite and offsite alternatives, options for avoidance and minimization, potential construction layouts, compensatory mitigation options, and other information. The meetings usually involve the applicant, USACE staff, interested resource agencies (federal, state, or local), and sometimes the interested public.</p>
        <p className='margin-bottom-2'>Note - If a field site visit is requested, it will be necessary for the property owner to provide a Right of Entry Form and their designated agent (if one was retained) to provide a completed Agent Authorization Form.</p>
        <p className='margin-bottom-2 text-bold'>Recommended Information for Standard Permit Pre-application Meetings (click to expand each section to learn more):</p>
        <div className='container margin-bottom-2'>
          <Accordion bordered items={recommendedInfoAccordion} multiselectable={true} />
        </div>
      </>
    );
  });

PreAppForm3Instructions.metadata = PreAppForm3InstructionsMetadata;

export default PreAppForm3Instructions;
