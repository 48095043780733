import MaterialIcon from '@mdi/react';

const Icon = ({ focusable = true, ...rest }) => (
  <MaterialIcon
    aria-hidden={focusable ? 'false' : 'true'}
    focusable={focusable ? 'true' : 'false'}
    tabIndex={focusable ? '0' : '-1'}
    role={focusable ? 'button' : 'img'}
    {...rest}
  />
);

export default Icon;
